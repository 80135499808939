import React, { useEffect, useState } from "react";
import { AsyncSelect } from "@atlaskit/select";
import apiClient from "../../api/admin-api-client";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

function SelectCategory({ onChange, value, isDisabled }) {
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);
    const [pending, setPending] = useState(true);
    useEffect(() => {
        search()
            .then(opt => {
                setOptions(opt);
                setPending(false);
            });
    }, []);

    const search = () => apiClient("categories")
        .then(response => {
            return Promise.resolve(response?.data?.map(i => ({
                label: `${i?.module?.alias ?? "-"} / ${i.name}`,
                value: i.id,
                slug: i?.slug,
                name: i?.name,
            })));
        })
        .catch(() => Promise.resolve([]));

    const selected = options?.find(i => i?.value === value);

    return <Wrapper>
        <AsyncSelect 
            defaultOptions={options} 
            value={selected} 
            onChange={onChange} 
            isDisabled={isDisabled || pending} 
            placeholder={t("admin_select_category_placeholder")} />
    </Wrapper>;
}

export default SelectCategory;

const Wrapper = styled.div`

`;