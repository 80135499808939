/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import SelectRoles from "../roles/select-roles";
import { Checkbox } from "@atlaskit/checkbox";
import toBoolean from "../../utils/to-boolean";
import { useTranslation } from "react-i18next";
import SelectOrganizations from "../organizations/select-organizations";
import Toggle from "@atlaskit/toggle";


export default function EditUser({ user, onClose, onAdded }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState("");
    const [isAdmin, setIsAdmin] = useState(toBoolean(user.is_admin));
    const [system, setSystem] = useState(toBoolean(user?.system));
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [organizations, setOrganizations] = useState(user?.organizations?.map(i => ({
        value: i?.id,
        content: i?.name,
    })) ?? []);
    const [roles, setRoles] = useState(user?.roles?.map(i => ({
        value: i?.id,
        content: i?.name,
    })) ?? []);
    const userRolesIds = user?.roles?.map(i => i?.id);
    const { t } = useTranslation();

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit}>
                    {t("admin_edit_user_save")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_edit_user_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const addRoles = ids => {
        return Promise.all(ids?.map(id => apiClient(`roles/${id}/users/${user?.id}`, { method: "POST" })));
    };

    const removeRoles = ids => {
        return Promise.all(ids?.map(id => apiClient(`roles/${id}/users/${user?.id}`, { method: "DELETE" })));
    };

    const handleSubmit = () => {
        const formData = {
            first_name: firstName,
            last_name: lastName,
            email,
            is_admin: isAdmin,
            system: system,
            organizations: organizations?.map(i => i?.value) ?? [],
        };

        if (password || passwordConfirmation) {
            formData["password"] = password;
            formData["password_confirmation"] = passwordConfirmation;
        }

        run(apiClient(`user/${user.id}`, { method: "PATCH", data: formData }))
            .then(response => {
                const newRolesIds = roles?.map(i => i?.value);
                const rolesToAdd = roles?.filter(i => !userRolesIds?.includes(i?.value))?.map(i => i?.value) ?? [];
                const rolesToRemove = user?.roles?.filter(i => !newRolesIds?.includes(i?.id))?.map(i => i?.id) ?? [];

                Promise.all([
                    addRoles(rolesToAdd),
                    removeRoles(rolesToRemove)
                ])
                    .then(() => {
                        onAdded(response);
                        toast.success(t("admin_edit_user_success"));
                        onClose();
                    })
                    .catch(e => {
                        console.error(e);
                        toast.error(t("admin_edit_user_roles_error"));
                        onAdded(response);
                        onClose();
                    });
            })
            .catch(() => {
                toast.error(t("admin_edit_user_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_edit_user_heading")}
            scrollBehavior="inside-wide"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_edit_user_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="first_name" label={t("admin_create_user_first_name")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={firstName} onChange={e => setFirstName(e.target.value)} isInvalid={error?.errors?.first_name} />}
            </Field>
            <Field id="last_name" name="last_name" label={t("admin_create_user_last_name")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={lastName} onChange={e => setLastName(e.target.value)} isInvalid={error?.errors?.last_name} />}
            </Field>
            <Field id="email" name="email" label={t("admin_create_user_email")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={email} onChange={e => setEmail(e.target.value)} isInvalid={error?.errors?.email} autoComplete={false} />}
            </Field>
            <Field id="password" name="password" label={t("admin_create_user_password")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} type="password" value={password} onChange={e => setPassword(e.target.value)} isInvalid={error?.errors?.password} autoComplete={false} />}
            </Field>
            <Field id="password_confirmation" name="password_confirmation" label={t("admin_create_user_password_confirmation")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} type="password" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} isInvalid={error?.errors?.password_confirmation} autoComplete={false} />}
            </Field>
            {/* <div>
                <Toggle isChecked={system} onChange={() => setSystem(t => !t)} />
            </div> */}
            <RolesWrapper>
                <SelectRoles ids={user?.roles?.map(i => i?.id) ?? []} onChange={e => setRoles(e)} />
            </RolesWrapper>
            <RolesWrapper>
                <SelectOrganizations ids={user?.organizations?.map(i => i?.id) ?? []} onChange={e => setOrganizations(e)} />
            </RolesWrapper>
            <Field id="isAdmin" name="isAdmin" label="">
                {() => (
                    <Checkbox label={t("admin_edit_user_is_admin")} isChecked={isAdmin} onChange={() => setIsAdmin(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="system" name="system" label="">
                {() => (
                    <Checkbox label="System" isChecked={system} onChange={() => setSystem(v => !v)} isDisabled={isPending} />
                )}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;

const RolesWrapper = styled.div`
    margin-top: 20px;
    padding: 20px 25px 20px;
    background: rgba(0,0,0,0.02);
    border-radius: 5px;
`;