import React from "react";
import { AsyncSelect } from "@atlaskit/select";
import apiClient from "../../api/api-client";
import { useTranslation } from "react-i18next";

function SelectDocumentWorkflow({ documentTypeId, isDisabled, onChange, value }) {
    const { t } = useTranslation();
    const search = () => apiClient(`document-type/${documentTypeId}/workflows`)
        .then(response => {
            return Promise.resolve(response.map(i => ({
                label: i.name,
                value: i.id,
            })));
        })
        .catch(() => Promise.resolve([]));

    return  <AsyncSelect placeholder={t("select_document_workflow_placeholder")}
        isSearchable={false}
        loadOptions={search} 
        value={value} 
        onChange={onChange} 
        defaultOptions
        isDisabled={isDisabled} />;
}

export default SelectDocumentWorkflow;