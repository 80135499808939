import React, { useEffect, useState } from "react";
import Spinner from "../../components/ui/spinner";
import AdminLayout from "../../layouts/admin-layout";
import PageHeader from "@atlaskit/page-header";
import styled from "styled-components";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import DynamicTable from "@atlaskit/dynamic-table";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { Link, useParams } from "react-router-dom";
import useAdminCategories from "../../hooks/use-admin-categories";
import useAdminModules from "../../hooks/use-admin-modules";
import CreateCategory from "../../components/categories/create-category";
import RemoveCategory from "../../components/categories/remove-category";
import EditCategory from "../../components/categories/edit-category";
import AssignCategory from "../../components/categories/assign-category";
import IconWidget from "../../components/widgets/icon-widget";
import { useTranslation } from "react-i18next";

function AdminModule() {
    const { module } = useParams();
    const { modules } = useAdminModules();
    const currentModule = modules?.find(i => i.slug?.toLowerCase() === module) || null;
    const { isPending, categories, reload } = useAdminCategories(currentModule);
    const [ open, setOpen ] = useState(false);
    const [ itemToRemove, setItemToRemove ] = useState(null);
    const [ itemToEdit, setItemToEdit ] = useState(null);
    const [ categoryOpen, setCategoryOpen ] = useState(false);
    const { t } = useTranslation();


    if (isPending) {
        return <AdminLayout><Spinner /></AdminLayout>;
    }

    const head = {
        cells: [
            { key: "name", content: t("admin_module_category"), isSortable: false },
            { key: "actions", content: null, isSortable: false },
        ]
    };

    const rows = categories?.map(c => ({
        key: c.id,
        cells: [
            { key: "name", content: <Flex>
                {c?.icon ? <IconWrapper>
                    <IconWidget name={c?.icon} size="16px" />
                </IconWrapper> : null}
                <Link to={`/admin/modules/${module}/${c.slug}`}>{c.name}</Link>
            </Flex>},
            { key: "actions", content: 
            <DropdownMenu position="bottom right" triggerType="button">
                <DropdownItemGroup>
                    <DropdownItem onClick={() => setItemToEdit(c)}>
                        {t("admin_module_edit")}
                    </DropdownItem>
                    <DropdownItem onClick={() => setItemToRemove(c)}>
                        {t("admin_module_remove")}
                    </DropdownItem>
                </DropdownItemGroup>
            </DropdownMenu>
            }
        ],
    })) || [];

    const actionsContent = <ButtonGroup>
        <Button onClick={() => setCategoryOpen(true)}>
            {t("admin_module_assign_category")}
        </Button>
        {currentModule && <Button appearance="primary" onClick={() => setOpen(true)}>
            {t("admin_module_create_category")}
        </Button>}
    </ButtonGroup>;

    return <AdminLayout>
        <PageHeader actions={actionsContent}>
            {t("admin_module_heading")}
        </PageHeader>
        <Wrapper>
            <Left>
                <DynamicTable
                    head={head} 
                    rows={rows}
                    loadingSpinnerSize="large"
                    isLoading={isPending}
                />
            </Left>
        </Wrapper>
        {currentModule && open && <CreateCategory module={currentModule} open={open} onClose={() => setOpen(false)} onAdded={() => reload()} categories={categories} />}
        {itemToRemove && <RemoveCategory category={itemToRemove} onClose={() => setItemToRemove(null)} onRemoved={() => {
            setItemToRemove(null);
            reload();
        }} />}
        {itemToEdit && <EditCategory module={currentModule} category={itemToEdit} onClose={() => setItemToEdit(null)} onSaved={() => {
            setItemToEdit(null);
            reload();
        }} />}
        {categoryOpen && <AssignCategory module={currentModule} onClose={() => setCategoryOpen(false)} onSaved={() => {
            setCategoryOpen(false);
            reload();
        }} />}
    </AdminLayout>;
}

export default AdminModule;

const Wrapper = styled.div`
    display: flex;
`;

const Left = styled.div`
    width: 100%;
`;

const Flex = styled.div`
    display: flex;
`;

const IconWrapper = styled.div`
    margin-right: 5px;
    width: 20px;
`;