import React, { useEffect, useState } from "react";
import Spinner from "../../components/ui/spinner";
import AdminLayout from "../../layouts/admin-layout";
import PageHeader from "@atlaskit/page-header";
import styled from "styled-components";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import DynamicTable from "@atlaskit/dynamic-table";
import Avatar from "@atlaskit/avatar";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import CreateUser from "../../components/users/create-user";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import RemoveUserModal from "../../components/users/remove-user-modal";
import EditUser from "../../components/users/edit-user";
import BlockUserModal from "../../components/users/block-user-modal";
import LockFilledIcon from "@atlaskit/icon/glyph/lock-filled";
import UnblockUserModal from "../../components/users/unblock-user-modal";
import BlockUserDate from "../../components/users/block-user-date";
import UnblockUserDate from "../../components/users/unblock-user-date";
import { toast } from "react-toastify";
import toBoolean from "../../utils/to-boolean";
import { useTranslation } from "react-i18next";
import GenerateUserToken from "../../components/users/generate-user-token";
import SelectOrganization from "../../components/organizations/select-organization";
import Select from "@atlaskit/select";
import Badge from "@atlaskit/badge";


function AdminUsers() {
    const { run, isPending, data: users } = useAsync();
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(10);
    const [open, setOpen] = useState(false);
    const [userToRemove, setUserToRemove] = useState(null);
    const [userToEdit, setUserToEdit] = useState(null);
    const [userToBlockInstantly, setUserToBlockInstantly] = useState(null);
    const [userToUnblock, setUserToUnblock] = useState(null);
    const [userToBlock, setUserToBlock] = useState(null);
    const [userToUnblockDate, setUserToUnblockDate] = useState(null);
    const [userToGenerateToken, setUserToGenerateToken] = useState(null);
    const { t } = useTranslation();
    const [organization, setOrganization] = useState(null);

    useEffect(() => {
        reload();
    }, [currentPage, organization?.value]);

    const reload = () => {
        run(apiClient(`users?page=${currentPage}&organization=${organization?.value ?? ""}`))
            .then(response => {
                setTotal(response.total);
                setPerPage(response.per_page);
            })
            .catch(() => {
                setTotal(0);
                toast.error(t("admin_users_error"));
            });
    };

    if (isPending) {
        return <AdminLayout><Spinner /></AdminLayout>;
    }

    const usosEnabled = users?.data?.some(user => user?.usos_id);

    const head = {
        cells: [
            { key: "avatar", content: null, width: "16px", padding: "5px" },
            { key: "id", content: "ID", width: "50px" },
            { key: "name", content: t("admin_users_column_name"), isSortable: false },
            { key: "email", content: t("admin_users_column_email"), isSortable: false },
            ...(usosEnabled ? [
                { key: "usos", content: "USOS ID", isSortable: false },
            ] : []),
            { key: "organizations", content: t("admin_users_column_organizations"), isSortable: false },
            ...(organization?.value ? [
                { key: "user_type", content: "Typ użytkownika w organizacji", isSortable: false },
            ] : []),
            { key: "actions", content: null, isSortable: false },
        ]
    };

    const userTypeOptions = [
        { label: "Użytkownik", value: "user" },
        { label: "Administrator", value: "admin" },
    ];

    const rows = users?.data?.map(user => ({
        key: user.id,
        cells: [
            { key: "avatar", content: <Avatar src={user.avatar_url} /> },
            { key: "id", content: <span>{user.id}</span> },
            {
                key: "name", content: <span>
                    {toBoolean(user.blocked) || user.blocked_by ? <LockedUser>
                        <LockFilledIcon />
                        <div>
                            {user.first_name + " " + user.last_name}
                            {user.blocked_by && <small>&nbsp;({user.blocked_by})</small>}
                        </div>
                    </LockedUser> : <span>
                        {user.first_name + " " + user.last_name}
                        {!!user?.system && <div>
                            (<Badge>SYSTEM</Badge>)</div>}
                    </span>}
                </span>
            },
            { key: "email", content: user.email },
            ...(usosEnabled ? [
                { key: "usos", content: user?.usos_id ?? "-" },
            ] : []),
            { key: "organizations", content: (user?.organizations?.map(i => i?.name)?.join(", ")) ?? "-" },
            ...(organization?.value ? [
                {
                    key: "user_type", content: <div>
                        <Select
                            value={userTypeOptions.find(i => i.value == user?.organizations?.find(o => o?.id == organization?.value)?.pivot?.type)}
                            // defaultValue={user?.organizations?.find(o => o?.id == organization?.value)?.pivot?.type}
                            options={userTypeOptions}
                            onChange={e => {
                                const newRole = e.value;
                                apiClient(`users/${user?.id}/organizations/${organization?.value}/role`, {
                                    method: "PATCH",
                                    data: {
                                        role: newRole,
                                    }
                                })
                                    .then(() => {
                                        toast.success("Rola zaktualizowana");
                                        reload();
                                    }).catch(() => {
                                        toast.error("Nie udało się zaktualizować roli");
                                    });
                            }}
                        />
                    </div>
                },
            ] : []),
            {
                key: "actions", content: <DropdownMenu position="bottom right" triggerType="button">
                    <DropdownItemGroup>
                        <DropdownItem onClick={() => setUserToEdit(user)}>
                            {t("admin_users_edit")}
                        </DropdownItem>
                        <DropdownItem onClick={() => setUserToRemove(user)}>
                            {t("admin_users_remove")}
                        </DropdownItem>
                        {!toBoolean(user.blocked) && !user.blocked_by && <DropdownItem onClick={() => setUserToBlockInstantly(user)}>
                            {t("admin_users_block")}
                        </DropdownItem>}
                        {!toBoolean(user.blocked) && !user.blocked_by && <DropdownItem onClick={() => setUserToBlock(user)}>
                            {t("admin_users_block_date")}
                        </DropdownItem>}
                        {toBoolean(user.blocked) && <DropdownItem onClick={() => setUserToUnblock(user)}>
                            {t("admin_users_unblock")}
                        </DropdownItem>}
                        {!!user.blocked_by && <DropdownItem onClick={() => setUserToUnblockDate(user)}>
                            {t("admin_users_unblock")}
                        </DropdownItem>}
                        <DropdownItem onClick={() => setUserToGenerateToken(user)}>
                            {t("admin_users_token_generate")}
                        </DropdownItem>
                    </DropdownItemGroup>
                </DropdownMenu>
            }
        ],
    })) || [];

    const actionsContent = <Flex>
        <div style={{ width: "200px", marginRight: "10px" }}>
            <SelectOrganization onChange={e => setOrganization(e)} value={organization} />
        </div>
        <ButtonGroup>
            <Button appearance="primary" onClick={() => setOpen(true)}>
                {t("admin_users_create")}
            </Button>
        </ButtonGroup>
    </Flex>;

    return <AdminLayout>
        <PageHeader actions={actionsContent}>
            {t("admin_users_heading")}
        </PageHeader>
        <Wrapper>
            <Left>
                <DynamicTable
                    head={head}
                    rows={rows}
                    rowsPerPage={perPage}
                    loadingSpinnerSize="large"
                    isLoading={isPending}
                    page={currentPage}
                    onSetPage={p => setCurrentPage(p)}
                    paginationi18n={{
                        next: t("admin_users_next_page"),
                        prev: t("admin_users_prev_page")
                    }}
                />
            </Left>
        </Wrapper>
        {open && <CreateUser open={open} onClose={() => setOpen(false)} onAdded={() => reload()} />}
        {userToEdit && <EditUser open={true} user={userToEdit} onClose={() => setUserToEdit(null)} onAdded={() => reload()} />}
        {userToRemove && <RemoveUserModal user={userToRemove} onRemoved={() => {
            setUserToRemove(null);
            reload();
        }} onClose={() => setUserToRemove(null)} />}
        {userToBlockInstantly && <BlockUserModal user={userToBlockInstantly} onBlocked={() => {
            setUserToBlockInstantly(null);
            reload();
        }} onClose={() => setUserToBlockInstantly(null)} />}
        {userToUnblock && <UnblockUserModal user={userToUnblock} onUnblocked={() => {
            setUserToUnblock(null);
            reload();
        }} onClose={() => setUserToUnblock(null)} />}
        {userToBlock && <BlockUserDate user={userToBlock} onBlocked={() => {
            setUserToBlock(null);
            reload();
        }} onClose={() => setUserToBlock(null)} />}
        {userToUnblockDate && <UnblockUserDate user={userToUnblockDate} onUnblocked={() => {
            setUserToUnblockDate(null);
            reload();
        }} onClose={() => setUserToUnblockDate(null)} />}
        {userToGenerateToken && <GenerateUserToken user={userToGenerateToken} onClose={() => setUserToGenerateToken(null)} />}
    </AdminLayout>;
}

export default AdminUsers;

const Wrapper = styled.div`
    display: flex;
`;

const Left = styled.div`
    width: 100%;
`;

const LockedUser = styled.div`
    display: flex;
    align-items: center;
    opacity: 0.8;
`;

const Flex = styled.div`
    display: flex;
`;