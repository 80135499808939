import React, { useEffect, useState } from "react";
import PageHeader from "@atlaskit/page-header";
import AdminLayout from "../../layouts/admin-layout";
import apiClient from "../../api/admin-api-client";
import { toast } from "react-toastify";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import useAdminWidgets from "../../hooks/use-admin-widgets";
import { useTranslation } from "react-i18next";
import DynamicTable from "@atlaskit/dynamic-table";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import RemoveWidget from "../../components/widgets/remove-widget";
import CreateWidget from "../../components/widgets/create-widget";

function AdminWidgets() {
    const { t } = useTranslation();
    const [ formOpen, setAddFormOpen ] = useState(false);
    const [ widgetToRemove, setWidgetToRemove ] = useState(null);
    const { widgets, isPending, reload } = useAdminWidgets();

    const actionsContent = (
        <ButtonGroup>
            <Button appearance="primary" onClick={() => setAddFormOpen(true)}>
                {t("admin_widgets_new")}
            </Button>
        </ButtonGroup >
    );

    const head = {
        cells: [
            { key: "name", content: t("admin_widgets_column_name"), isSortable: false },
            { key: "actions", content: null, isSortable: false, align: "right", width: "50px" },
        ]
    };

    const rows = widgets?.map(widget => ({
        key: widget.name,
        cells: [
            { key: "name", content: <p>{widget.name}</p> },
            { key: "actions", content: <DropdownMenu  position="bottom right" triggerType="button">
                <DropdownItemGroup>
                    <DropdownItem onClick={() => setWidgetToRemove(widget)}>
                        {t("admin_widgets_remove")}
                    </DropdownItem>
                </DropdownItemGroup>
            </DropdownMenu>
            }
        ]
    }));

    return <AdminLayout>
        <PageHeader actions={actionsContent}>
            {t("admin_widgets_heading")}
        </PageHeader>
        <DynamicTable
            head={head} 
            rows={rows}
            loadingSpinnerSize="large"
            isLoading={isPending}
        />
        {widgetToRemove && <RemoveWidget 
            widget={widgetToRemove} 
            onRemoved={() => {
                setWidgetToRemove(null);
                reload();
            }} onClose={() => {
                setWidgetToRemove(null);
            }} />}
        {formOpen && <CreateWidget
            onClose={() => setAddFormOpen(false)}
            onAdded={() => {
                setAddFormOpen(false);
                reload();
            }}
        />}
    </AdminLayout>;
}

export default AdminWidgets;
