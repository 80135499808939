import { useCallback, useEffect } from "react";
import apiClient from "../api/api-client";
import useAsync from "../hooks/use-async";
import documentLinkTypesResponse from "../api/document-link-types-response";

export default function useDocumentLinkTypes() {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        if (data?.length > 0) return;
        reload();
    }, []);

    const reload = useCallback(() => {
        run(apiClient("link-types")).catch(() => { });
    });

    return {
        types: data ? documentLinkTypesResponse(data) : null,
        data: data,
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        reload,
    };
}