import React from "react";
import styled from "styled-components";
import { 
    Line, Bar, Radar, Doughnut,
    PolarArea, Bubble, Scatter
} from "react-chartjs-2";

function ChartWidget({ type, ...props }) {
    switch (type) {
    case "line":
        return <Line {...props} />;
    case "bar":
        return <Bar {...props} />;
    case "radar":
        return <Radar {...props} />;
    case "doughnut":
        return <Doughnut {...props} />;
    case "polarArea":
        return <PolarArea {...props} />;
    case "bubble":
        return <Bubble {...props} />;
    case "scatter":
        return <Scatter {...props} />;
    }
}
export default ChartWidget;

const Wrapper = styled.div`
`;