/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import NewDocumentForm from "./new-document-form";
import Form from "@atlaskit/form";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import prepareFormData from "../../utils/prepare-form-data";
import { toast } from "react-toastify";
import styled from "styled-components";
import SectionMessage from "@atlaskit/section-message";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useDocument from "../../hooks/use-document";
import { useTranslation } from "react-i18next";

export default function CreateDocumentModal({ open, onClose, onSubmit, onEdit, documentTypeId, resourceId = null, startDate = null, endDate = null, documentLinkTypeId = null, eventId = null }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const { document, isPending: loadingDocument } = useDocument(eventId);
    const [ eventInitialValues, setEventInitialValues ] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        if (!document && !document?.id && !document?.rawValues) return;

        const fields = document?.document_type?.fields?.reduce((acc, f) => {
            acc[f.id] = f.name;
            return acc;
        }, {});

        setEventInitialValues(document?.rawValues?.reduce((acc, v) => {
            const fieldId = v?.document_type_field_id;
            const key = fields[fieldId];
            acc[key] = v?.value;
            return acc;
        }, {}));
    }, [ document?.id ]);

    const isLoadingDocument = eventId && loadingDocument;

    const handleSubmit = data => {
        const formData = prepareFormData(data);

        if (eventId) {
            return editEvent(formData);
        }

        run(apiClient(`workflow/document-type/${documentTypeId}/document`, { method: "POST", data: formData }))
            .then(response => {
                onSubmit(response);
            })
            .catch(() => {
                toast.error(t("create_document_modal_create_error"));
            });
    };

    const editEvent = (formData) => {
        run(apiClient(`workflow/document/${eventId}`, { method: "PATCH", data: formData }))
            .then(() => {
                onEdit && onEdit();
            })
            .catch(error => {
                toast.error(error?.message || t("create_document_modal_save_error"));
            });
    };

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="submit" isLoading={isPending}>
                    {t("create_document_modal_create")}
                </Button>
                <Button type="button" isLoading={isPending} onClick={onClose}>
                    {t("create_document_modal_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    return <>
        <ModalTransition>
            {open && (
                <Modal
                    isLoading={isLoadingDocument}
                    onClose={() => {
                        if (isPending) return;
                        onClose();
                    }}
                    heading={ eventId? t("create_document_modal_edit_heading") : t("create_document_modal_create_heading") }
                    components={{
                        Container: ({ children, className }) => (
                            <Form onSubmit={handleSubmit}>
                                {({ formProps }) => (
                                    <form {...formProps}>
                                        {children}
                                    </form>
                                )}
                            </Form>
                        ),
                        Footer: footer,
                    }}
                    scrollBehavior="outside"
                >
                    <Wrapper>
                        {isCreatingError && <SectionMessageWrapper>
                            <SectionMessage title={t("create_document_modal_error_heading")} appearance="error">
                                <p>{error?.message}</p>
                                {error?.errors && <ul>
                                    {Object.keys(error.errors).map(key => (
                                        <li key={key}>{error.errors[key]}</li>
                                    ))}
                                </ul>}
                            </SectionMessage>
                        </SectionMessageWrapper>}
                        <NewDocumentForm documentTypeId={documentTypeId} error={error} quickForm initialValues={{
                            start_date: startDate,
                            end_date: endDate,
                            ...eventInitialValues,
                        }} />
                    </Wrapper>
                </Modal>
            )}
        </ModalTransition>
    </>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 50px;
    font-size: 13px;
`;

const Wrapper = styled.div`
    margin-bottom: 20px;
`;