import React from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import useAsync from "../../hooks/use-async";
import { useTranslation } from "react-i18next";

export default function DeleteOrganization({ organization, onRemoved, onClose }) {
	const { run, isPending } = useAsync();
	const { t } = useTranslation();
	const remove = () => {
		run(apiClient(`organizations/${organization.id}`, { method: "DELETE" }))
			.then(() => {
				toast.success(t("admin_remove_organization_success"));
				onRemoved && onRemoved();
			})
			.catch(error => toast.error(t("admin_remove_organization_error") + error.message));
	};

	return (
		<>
			<ModalTransition>
				<Modal
					isLoading={isPending}
					actions={[
						{ text: t("admin_remove_organization_remove"), onClick: remove, isLoading: isPending },
						{ text: t("admin_remove_organization_cancel"), onClick: onClose, isDisabled: isPending },
					]}
					onClose={close}
					heading={t("admin_remove_organization_heading")}
					appearance="danger"
				>
					<p>
						{t("admin_remove_organization_p")}
					</p>
				</Modal>
			</ModalTransition>
		</>
	);
}