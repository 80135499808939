import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import Select from "@atlaskit/select";
import styled from "styled-components";
import { toast } from "react-toastify";
import Button from "@atlaskit/button";

export default function SelectPivotFilter({ onChange, value, documentTypeId, hash }) {
    const { t } = useTranslation();
    const { run, isPending, data } = useAsync();

    useEffect(() => {
        reload();
    }, [hash]);

    const reload = () => {
        run(apiClient(`documentTypes/${documentTypeId}/pivotFilters`))
            .catch(() => {
            });
    };

    const remove = () => {
        run(apiClient(`pivotFilters/${value?.value}`, {
            method: "DELETE"
        }))
            .then(() => {
                onChange(null);
                reload();
            })
            .catch(() => {
            });
    };

    const filters = data ?? [];

    return <Flex>
        <Col>
            <Select
                isDisabled={isPending}
                isClearable
                value={value}
                onChange={onChange}
                options={filters?.map(o => ({
                    label: o?.name,
                    value: o?.id,
                    data: o?.data,
                }))} />
        </Col>
        {value && <Button onClick={() => {
            if (window.confirm(t("filters_remove") + "?")) {
                remove();
            }
        }} appearance="subtle">
            {t("filters_remove")}
        </Button>}
    </Flex>;
}

const Flex = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const Col = styled.div`
flex: 1;
`;