import React, { useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import Button from "@atlaskit/button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SaveFilters({ documentTypeId, filters, type, onSuccess, sortColumn, sortDir, columnsVisible, saveFor = null }) {
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState("");
    const { run, isPending, error, isError } = useAsync();
    const [newFilterId, setNewFilterId] = useState(null);
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const close = () => setIsOpen(false);
    const open = () => setIsOpen(true);
    const save = e => {
        e.preventDefault();
        run(apiClient(`document-type/${documentTypeId}/filters`, {
            data: {
                document_type_id: documentTypeId,
                name,
                ...(saveFor ?? {}),
                filters: filters.map(f => {
                    let value = f?.type === "document_type" ? JSON.stringify(f.value) : f.value;

                    const specialDates = ["BEFORE_YESTERDAY", "YESTERDAY", "TODAY", "TOMORROW", "AFTER_TOMORROW", "IS_EMPTY"];
                    if (f?.type === "date" && specialDates?.includes(f?.operator?.toUpperCase())) {
                        value = f?.operator;
                    }

                    return {
                        name: f?.type === "document_type" ? String(f.column) : f.column,
                        operator: f.operator,
                        value,
                    };
                }),
                condition: type,
                sort_column: sortColumn != "id" ? sortColumn : undefined,
                sort_dir: sortDir,
                columns_visible: columnsVisible
            }
        }))
            .then(response => {
                toast.success(t("save_filters_success"));
                setNewFilterId(response.id);
                onSuccess(response.id);
                setIsOpen(false);
                setName("");
            })
            .catch(() => {
                toast.error(t("save_filters_error"));
            });
    };

    // if (newFilterId) {
    //     return <Redirect to={`${window.location.href}?filter=${newFilterId}`} />;
    // }

    return (
        <>
            <Button onClick={open} isDisabled={filters?.length === 0}>
                {t("save_filters_save")}
            </Button>
            <ModalTransition>
                {isOpen && (
                    <Modal
                        actions={[
                            { text: t("save_filters_save"), type: "submit", isLoading: isPending, onClick: save },
                            { text: t("save_filters_cancel"), onClick: close },
                        ]}
                        onClose={close}
                        heading={t("save_filters_heading")}
                    >
                        <Field id="name" name="name" label={t("save_filters_name")}>
                            {() => (
                                <TextField
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                    isInvalid={error?.errors?.name}
                                    placeholder={t("save_filters_name")}
                                />
                            )}
                        </Field>
                        {isError && <SectionMessageWrapper>
                            <SectionMessage title={t("save_filters_error_heading")} appearance="error">
                                <p>{error?.message}</p>
                                {error?.errors && <ul>
                                    {Object.keys(error.errors).map(key => (
                                        <li key={key}>{error.errors[key]}</li>
                                    ))}
                                </ul>}
                            </SectionMessage>
                        </SectionMessageWrapper>}
                    </Modal>
                )}
            </ModalTransition>
        </>
    );
}

const SectionMessageWrapper = styled.div`
    margin-top: 50px;
    font-size: 13px;
`;