import React, { useEffect } from "react";
import Spinner from "../../components/ui/spinner";
import AdminLayout from "../../layouts/admin-layout";
import PageHeader from "@atlaskit/page-header";
import styled from "styled-components";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import DynamicTable from "@atlaskit/dynamic-table";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { Link } from "react-router-dom";
import { useState } from "react";
import CreateModule from "../../components/modules/create-module";
import RemoveModule from "../../components/modules/remove-module";
import EditModule from "../../components/modules/edit-module";
import IconWidget from "../../components/widgets/icon-widget";
import { useTranslation } from "react-i18next";

function AdminModules() {
    const { t } = useTranslation();
    const { run, isPending, data: modules } = useAsync();
    const [ open, setOpen ] = useState(false);
    const [ itemToRemove, setItemToRemove ] = useState(null);
    const [ itemToEdit, setItemToEdit ] = useState(null);

    useEffect(() => {
        reload();
    }, [ ]);

    const reload = () => {
        run(apiClient("modules")).catch(() => {});
    };

    if (isPending) {
        return <AdminLayout><Spinner /></AdminLayout>;
    }

    const head = {
        cells: [
            { key: "name", content: t("admin_modules_module"), isSortable: false },
            { key: "actions", content: null },
        ]
    };

    const rows = modules?.data?.map(m => ({
        key: m.id,
        cells: [
            { key: "name", content: <Flex>
                {m?.icon ? <IconWrapper>
                    <IconWidget name={m?.icon} size="16px" />
                </IconWrapper> : null}
                <Link to={`/admin/modules/${m.name?.toLowerCase()}`}>{m.alias}</Link>
            </Flex>},
            { key: "actions", content: 
            <DropdownMenu position="bottom right" triggerType="button">
                <DropdownItemGroup>
                    <DropdownItem onClick={() => setItemToEdit(m)}>
                        {t("admin_modules_edit")}
                    </DropdownItem>
                    <DropdownItem onClick={() => setItemToRemove(m)}>
                        {t("admin_modules_remove")}
                    </DropdownItem>
                </DropdownItemGroup>
            </DropdownMenu>
            }
        ],
    })) || [];

    const actionsContent = <ButtonGroup>
        <Button appearance="primary" onClick={() => setOpen(true)}>
            {t("admin_modules_create")}
        </Button>
    </ButtonGroup>;

    return <AdminLayout>
        <PageHeader actions={actionsContent}>
            {t("admin_modules_heading")}
        </PageHeader>
        <Wrapper>
            <Left>
                <DynamicTable
                    head={head} 
                    rows={rows}
                    loadingSpinnerSize="large"
                    isLoading={isPending}
                />
            </Left>
        </Wrapper>
        {open && <CreateModule open={open} onClose={() => setOpen(false)} onAdded={() => reload()} modules={modules?.data ?? []} />}
        {itemToRemove && <RemoveModule module={itemToRemove} onClose={() => setItemToRemove(null)} onRemoved={() => {
            setItemToRemove(null);
            reload();
        }} />}
        {itemToEdit && <EditModule module={itemToEdit} onClose={() => setItemToEdit(null)} onSaved={() => {
            setItemToEdit(null);
            reload();
        }} />}
    </AdminLayout>;
}

export default AdminModules;

const Wrapper = styled.div`
    display: flex;
`;

const Left = styled.div`
    width: 100%;
`;

const Flex = styled.div`
    display: flex;
`;

const IconWrapper = styled.div`
    margin-right: 5px;
    width: 20px;
`;