import React, { useEffect, useState } from "react";
import UserPicker from "@atlaskit/user-picker";
import apiClient from "../../api/api-client";
import { useTranslation } from "react-i18next";

export default function SearchUsers({ onChange, isMulti = true, selectedUser = null }) {
    const [users, setUsers] = useState(null);
    const { t } = useTranslation();

    const search = query => apiClient(`users?email=${query || ""}`)
        .then(response => {
            return Promise.resolve(response.data.map(i => ({
                id: i.id,
                name: i.first_name + " " + i.last_name,
                type: "user",
                avatarUrl: i.avatar_url,
            })));
        })
        .catch(() => Promise.resolve([]));

    return (
        <UserPicker
            fieldId="user"
            placeholder={t("search_users_placeholder")}
            isMulti={isMulti}
            onChange={e => {
                setUsers(e);
                onChange(e);
            }}
            loadOptions={search}
            value={selectedUser}
        />
    );
}