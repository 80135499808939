import React, { useEffect, useState } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import useDashboardInvites from "../../hooks/use-dashboard-documents";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import styled from "styled-components";
import { Link } from "react-router-dom";
import slugify from "slugify";
import { useTranslation } from "react-i18next";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import Button from "@atlaskit/button/custom-theme-button";

function DashboardInvites({ module = null }) {
    const { t } = useTranslation();
    const { run, isPending, data } = useAsync();
    const [isAccepting, setIsAccepting] = useState(false);
    const [isDeclining, setIsDeclining] = useState(false);

    useEffect(() => {
        reload();
    }, [module]);

    const reload = (p = 1) => {
        run(apiClient("userInvites")).catch(() => { });
    };

    const accept = (uuid) => {
        setIsAccepting(true);

        apiClient(`userInvites/${uuid}`, { method: "POST" })
            .then(() => reload())
            .catch(() => { })
            .finally(() => setIsAccepting(false));
    };

    const decline = (uuid) => {
        setIsDeclining(true);

        apiClient(`userInvites/${uuid}`, { method: "DELETE" })
            .then(() => reload())
            .catch(() => { })
            .finally(() => setIsDeclining(false));
    };

    const head = {
        cells: [
            { key: "name", content: t("dashboard_invites_name"), isSortable: false, width: 100 },
            { key: "action", content: "", isSortable: false, width: 100 },
        ]
    };

    const rows = data?.data?.map(invite => ({
        key: invite?.id,
        cells: [
            { key: "name", content: <div>{invite?.organization?.name}</div> },
            {
                key: "action", content: <Flex>
                    <Button size="small" appearance="primary" onClick={() => accept(invite?.uuid)} isLoading={isAccepting} isDisabled={isAccepting}>
                        {t("dashboard_invites_accept")}
                    </Button>
                    <Button size="small" appearance="danger" onClick={() => decline(invite?.uuid)} isLoading={isDeclining} isDisabled={isDeclining}>
                        {t("dashboard_invites_decline")}
                    </Button>
                </Flex>
            },

        ]
    }));

    return <>
        <DynamicTable
            head={head}
            rows={rows}
            loadingSpinnerSize="large"
            isLoading={isPending}
            emptyView={<div>{t("dashboard_invites_empty")}</div>}
        />
    </>;
}

export default DashboardInvites;

function RowHeader({ document }) {
    return <Link to={`/${document?.category?.module?.name}/${document?.category?.slug ?? slugify(document?.category?.name || "")}/${document?.space?.slug}/${document.id}`.toLowerCase()}>{document.name}</Link>;
}

const RowSpacer = styled.div`
    height: 30px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Category = styled.span`
color: rgb(137, 147, 164);
text-decoration: none;
outline: currentcolor none medium;
margin-left: 8px;
color: rgb(137, 147, 164);
font-weight: 600;
font-size: 12px;
text-decoration: none;
white-space: nowrap;
margin-right: 10px;
`;

const Flex = styled.div`
display: flex;
gap: 10px;
`;