import React from "react";
import DashboardLayout from "../layouts/dashboard-layout";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import DashboardDocuments from "../components/dashboard/dashboard-documents";
import DashboardNotifications from "../components/dashboard/dashboard-notifications";
import DashboardInvites from "../components/dashboard/dashboard-invites";
import { useTranslation } from "react-i18next";
import useGlobalConfig from "../hooks/use-global-config";
import Spinner from "../components/ui/spinner";
import Widgets from "../components/widgets/widgets";
import { Redirect } from "react-router-dom";

function Dashboard() {
    const { t } = useTranslation();
    const { config, isPending } = useGlobalConfig("dashboard");

    if (config?.redirect) {
        return <Redirect to={config?.redirect} />;
    }

    return <>
        <Helmet>
            <title>{config?.title ?? t("dashboard_title")}</title>
        </Helmet>
        <DashboardLayout>
            <h2>{config?.heading ?? t("dashboard_heading")}</h2>
            {
                isPending
                    ? <Spinner />
                    : <ModuleView config={config} />
            }
        </DashboardLayout>
    </>;
}

function ModuleView({ config }) {
    const { t } = useTranslation();

    if (config?.widgets) {
        return <Widgets widgets={config?.widgets} module={module} />;
    }

    return <Row>
        <Left>
            <Wrapper>
                <h4>{t("dashboard_assigned")}</h4>
                <DashboardDocuments />
            </Wrapper>
        </Left>
        <Right>
            <Wrapper>
                <h4>{t("dashboard_notifications")}</h4>
                <DashboardNotifications />
            </Wrapper>
            <Wrapper>
                <h4>{t("dashboard_invites")}</h4>
                <DashboardInvites />
            </Wrapper>
        </Right>
    </Row>;
}

export default Dashboard;

const Row = styled.div`
    @media screen and (min-width: 1300px) {
        display: flex;
    }
`;

const Left = styled.div`
    @media screen and (min-width: 1300px) {
        width: 100%;
    }
`;

const Right = styled.div`
    @media screen and (min-width: 1300px) {
        width: 80%;
    }
`;

const Wrapper = styled.div`
    padding: 20px;
    margin-top: 20px;
    background: white;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);
    border-radius: 5px;
    border-top: 4px solid #2684ff;
    overflow: hidden;

    > h4 {
        margin-bottom: 20px;
    }

    @media screen and (min-width: 1300px) {
        margin: 20px;
    }
`;