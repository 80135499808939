import { useCallback, useEffect } from "react";
import apiClient from "../api/admin-api-client";
import useAsync from "../hooks/use-async";

export default function useAdminRolePermissions(role) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        if (!role) return;
        reload();
    }, [ role ]);

    const reload = useCallback(() => run(apiClient(`roles/${role}/spaces/permissions`)).catch(() => { }));

    return {
        permissions: permissionsResponse(data),
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        reload,
    };
}

function permissionsResponse(data) {
    if (!data) return {};
    
    return data?.data?.reduce((acc, item) => {
        if (!acc[item?.id]) acc[item?.id] = {};
        item?.permissions?.map(i => {
            acc[item?.id][i?.name] = true;
        });
        return acc;
    }, {});
}