export default function documentsListResponse(response) {
    return {
        data: response.data.map(row => ({
            uuid: row?.uuid,
            name: findName(row),
            id: row.id,
            category: row?.category,
            space: row?.space,
            document_type: row?.document_type,
            values: row?.mappedValues ? row.mappedValues : reduceValues(row.values),
            rawValues: row.values,
            mappedValues: row?.mappedValues,
            status: row.status,
            links: row?.links || [],
            users: row?.users || [],
            files: row?.files ?? [],
            deleted_at: row?.deleted_at,
        })),
        pagination: {
            total: response?.meta?.total,
            per_page: response?.meta?.per_page,
            page: response?.meta?.current_page,
        }
    };
}

function reduceValues(values) {
    return values.reduce((acc, i) => {
        acc[i.name] = i.value;
        return acc;
    }, {});
}

function findName(row) {
    if (row?.mappedValues) {
        return row?.mappedValues?.name;
    }

    const nameValue = row?.values?.find(i => i.name === "name");
    return nameValue?.value;
}