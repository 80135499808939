import React from "react";
import styled from "styled-components";
import DynamicTable from "@atlaskit/dynamic-table";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function TableWidget({ columns = [], summary = {}, rows = [] }) {
    const { t } = useTranslation();

    return <>
        <Table>
            <Thead>
                <tr>
                    {columns?.map(column => (
                        <Th key={column?.name} >{column?.label}</Th>
                    ))}
                </tr>
            </Thead>
            <Tbody>
                {rows?.map((row, index) => (
                    <Tr key={index}>
                        {Object.keys(row)?.map(columnName => (
                            <Td key={columnName + "_" + index}>
                                <RowContent {...row[columnName]} align={columns?.find(i => i?.name === columnName)?.align ?? "left"}>
                                    {row[columnName]?.value}
                                </RowContent>
                            </Td>
                        ))}
                    </Tr>
                ))}
                {summary && rows?.length > 0 && 
                    <SummaryTr key="summary">
                        {columns?.map(column => {
                            return summary[column?.name] ? <SummaryTd>
                                <Summary>
                                    <Align align={column?.align ?? "left"}>
                                        {summary[column?.name]}
                                    </Align>
                                </Summary>
                            </SummaryTd> : <SummaryTd />;
                        })}
                    </SummaryTr>}
            </Tbody>
        </Table>
    </>;
}

function RowContent({ href = null, align = "left", children, color = "inherit" }) {
    if (href) {
        return <Align align={align} color={color}>
            <a href={href}>{children}</a>
        </Align>;
    }

    return <Align align={align} color={color}>
        {children}
    </Align>;
}

export default TableWidget;

const Wrapper = styled.div`
`;

const Column = styled.div`
    text-align: center;
`;

const Align = styled.div`
text-align: ${props => props.align};
color: ${props => props.color};
`;

const Summary = styled.div`
    font-weight: bold;
`;

const Table = styled.table`
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
`;

const Th = styled.th`
margin: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
border: 1px solid #e4e3e2;
padding: 8px !important;
text-align: left;
font-size: 14px;
font-weight: 700;
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
color: rgb(85, 94, 104);
`;

const Td = styled.td`
margin: 0;
padding: 8px !important;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
border: 1px solid #e4e3e2;
text-align: left;
`;

const SummaryTd = styled.td`
margin: 0;
padding: 8px !important;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
border: 1px solid #ffffff;
border-bottom: 1px solid #e4e3e2;
text-align: left;

&:first-child {
    border-left: 1px solid #e4e3e2;
}

&:last-child {
    border-right: 1px solid #e4e3e2;
}
`;

const Tbody = styled.tbody`
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
`;

const Thead = styled.thead`
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
`;

const Tr = styled.tr`
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;

&:nth-child(odd) {
    background-color: rgb(248, 248, 248);
}
`;

const SummaryTr = styled.tr`
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
`;