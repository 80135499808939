/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Checkbox } from "@atlaskit/checkbox";
import Select from "@atlaskit/select";
import { v4 as uuidv4 } from "uuid";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import TextArea from "@atlaskit/textarea";
import useGlobalConfig from "../../hooks/use-global-config";
import { useTranslation } from "react-i18next";
import { he } from "date-fns/locale";

export default function CreateField({ onClose, onAdded, documentTypeId, fieldId, fields, allFields, pos = 0 }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [type, setType] = useState(null);
    const [name, setName] = useState("");
    const [group, setGroup] = useState(null);
    const [groupLp, setGroupLp] = useState(0);
    const [groupFlex, setGroupFlex] = useState(1);
    const [label, setLabel] = useState("");
    const [position, setPosition] = useState(() => {
        if (allFields?.length > 0) return {
            value: allFields[0]?.name,
            label: allFields[0]?.label,
        };
        return null;
    });
    const [required, setRequired] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [quickForm, setQuickForm] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [mustHave, setMustHave] = useState(false);
    const [workflow, setWorkflow] = useState(false);
    const [options, setOptions] = useState(() => []);
    const [help, setHelp] = useState("");
    const { config: fieldsMapConfig } = useGlobalConfig("fields_map");
    // eslint-disable-next-line no-useless-escape
    // eslint-disable-next-line quotes
    const [rawOptions, setRawOptions] = useState(`{\n}`);
    const { t } = useTranslation();

    const setOptionName = (id, name) => setOptions(opt => opt.map(o => {
        if (o.id === id) return { ...o, name };
        return o;
    }));

    const setOptionValue = (id, value) => setOptions(opt => opt.map(o => {
        if (o.id === id) return { ...o, value };
        return o;
    }));

    const addNewRow = () => setOptions(opt => ([
        ...opt,
        { id: uuidv4(), name: "", value: "" },
    ]));

    const removeOption = option => setOptions(opt => opt.filter(o => o?.id !== option?.id));

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit}>
                    {t("admin_create_field_create")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_create_field_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        run(apiClient("document-type-fields", {
            data: {
                document_type_id: documentTypeId,
                field_id: type?.value,
                name,
                label,
                required,
                hidden,
                quick_form: quickForm,
                read_only: readOnly,
                must_have: mustHave,
                workflow,
                position: position?.value,
                options,
                help,
                group,
                group_lp: groupLp,
                group_flex: groupFlex,
            }
        }))
            .then(response => {
                // if (type?.value === 3 || type?.value === 11) {
                if (type?.value === fieldsMapConfig?.selectId || type?.value === fieldsMapConfig?.multiSelectId) {
                    run(apiClient(`document-type-field/${response?.data?.id}/options`, {
                        method: "PATCH", data: {
                            options
                        }
                    }))
                        .then(() => {
                            onAdded(response);
                            onClose();
                        })
                        .catch(() => {
                            toast.error(t("admin_create_field_option_add_error"));
                        });

                    // } else if (type?.value === 12) {
                } else {
                    const id = response?.data?.id;

                    if (id) {
                        apiClient(`document-type-field/${id}/options/raw`, { method: "PATCH", data: rawOptions })
                            .then(() => {
                                onAdded(response);
                                onClose();
                            })
                            .catch(() => {
                                toast.error(t("admin_create_field_option_error"));
                            });
                    } else {
                        onAdded(response);
                        onClose();
                    }
                }
            })
            .catch(() => {
                toast.error(t("admin_create_field_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_create_field_heading")}
            scrollBehavior="outside"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_create_field_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="type" label={t("admin_create_field_type")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => (<Select
                    {...fieldProps}
                    value={type}
                    onChange={e => setType(e)}
                    options={fields.map(i => ({ label: i.type, value: i.id }))}
                />)}
            </Field>
            <Field name="name" label={t("admin_create_field_name")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={name} onChange={e => setName(e.target.value)} isInvalid={error?.errors?.name} autoComplete={false} />}
            </Field>
            <Field name="label" label={t("admin_create_field_label")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={label} onChange={e => setLabel(e.target.value)} isInvalid={error?.errors?.label} autoComplete={false} />}
            </Field>
            {(type?.value === fieldsMapConfig?.selectId || type?.value === fieldsMapConfig?.multiSelectId) && <OptionsWrapper>
                <Field name="options" label="" isDisabled={isPending}>
                    {() => (
                        <>
                            {options.map(option => <OptionWrapper key={option.id}>
                                <RemoveIconWrapper>
                                    <Button iconBefore={<EditorRemoveIcon />} onClick={() => removeOption(option)} />
                                </RemoveIconWrapper>
                                <OptionColumn>
                                    <Field name="option-name" label={t("admin_create_field_option_name")} isDisabled={isPending}>
                                        {() => <TextField value={option.name} onChange={e => setOptionName(option.id, e.target.value)} />}
                                    </Field>
                                </OptionColumn>
                                <OptionColumn>
                                    <Field name="option-value" label={t("admin_create_field_option_value")} isDisabled={isPending}>
                                        {() => <TextField value={option.value} onChange={e => setOptionValue(option.id, e.target.value)} />}
                                    </Field>
                                </OptionColumn>
                            </OptionWrapper>)}
                            <AddNewRow>
                                <Button appearance="primary" onClick={addNewRow}>
                                    {t("admin_create_field_add_option")}
                                </Button>
                            </AddNewRow>
                        </>
                    )}
                </Field>
            </OptionsWrapper>}
            {(type?.value !== fieldsMapConfig?.selectId && type?.value !== fieldsMapConfig?.multiSelectId) && <Field name="rawOptions" label={t("admin_create_field_raw_options")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextArea
                    {...fieldProps}
                    isMonospaced
                    value={rawOptions}
                    onChange={e => setRawOptions(e?.target?.value)}
                    minimumRows={5}
                />}
            </Field>}
            {/* {type?.value === 11 && <OptionsWrapper>
                <Field name="options" label="" isDisabled={isPending}>
                    {() => (
                        <>
                            {multiOptions.map(option => <OptionWrapper key={option.id}>
                                <RemoveIconWrapper>
                                    <Button iconBefore={<EditorRemoveIcon />} onClick={() => removeMultiOption(option)} />
                                </RemoveIconWrapper>
                                <OptionColumn>
                                    <Field name="option-name" label="Nazwa" isDisabled={isPending}>
                                        {() => <TextField value={option.name} onChange={e => setMultiOptionName(option.id, e.target.value)} />}
                                    </Field>
                                </OptionColumn>
                                <OptionColumn>
                                    {option?.values?.map(value => (
                                        <ChildrenOptionWrapper key={value?.id}>
                                            <Field name="option-value" label="Wartość" isDisabled={isPending}>
                                                {() => <TextField value={value.value} onChange={e => setMultiOptionValue(option.id, value?.id, e.target.value)} />}
                                            </Field>
                                            <RemoveIconWrapper>
                                                <Button iconBefore={<EditorRemoveIcon />} onClick={() => removeMultiChildrenOption(value?.id)} />
                                            </RemoveIconWrapper>
                                        </ChildrenOptionWrapper>
                                        
                                    ))}
                                    <AddNewRow>
                                        <Button appearance="primary" onClick={() => addNewMultiChildrenRow(option?.id)}>+</Button>
                                    </AddNewRow>
                                </OptionColumn>
                            </OptionWrapper>)}
                            <AddNewRow>
                                <Button appearance="primary" onClick={addNewMultiRow}>Dodaj nową opcję</Button>
                            </AddNewRow>
                        </>
                    )}
                </Field>
            </OptionsWrapper>} */}
            <Field name="position" label={t("admin_create_field_position")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => (<Select
                    {...fieldProps}
                    value={position}
                    onChange={e => setPosition(e)}
                    options={allFields?.sort((a, b) => a?.position - b?.position)?.map(i => ({ label: i.label, value: i.name }))}
                />)}
            </Field>
            <Field id="required" name="required" label="">
                {() => (
                    <Checkbox label={t("admin_create_field_required")} isChecked={required} onChange={() => setRequired(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="hidden" name="hidden" label="">
                {() => (
                    <Checkbox label={t("admin_create_field_hidden")} isChecked={hidden} onChange={() => setHidden(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="quickForm" name="quickForm" label="">
                {() => (
                    <Checkbox label={t("admin_create_field_quick_form")} isChecked={quickForm} onChange={() => setQuickForm(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="readOnly" name="readOnly" label="">
                {() => (
                    <Checkbox label={t("admin_create_field_read_only")} isChecked={readOnly} onChange={() => setReadOnly(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="mustHave" name="mustHave" label="">
                {() => (
                    <Checkbox label={t("admin_create_field_must_have")} isChecked={mustHave} onChange={() => setMustHave(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="workflow" name="workflow" label="">
                {() => (
                    <Checkbox label={t("admin_create_field_worfklow_filled")} isChecked={workflow} onChange={() => setWorkflow(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="help" name="help" label={t("admin_create_field_help")}>
                {() => (
                    <TextArea onChange={e => setHelp(e.target.value)} value={help} isDisabled={isPending} />
                )}
            </Field>

            <Field name="group" label={t("admin_create_field_group")} autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={group} onChange={e => setGroup(e.target.value)} isInvalid={error?.errors?.group} autoComplete={false} />}
            </Field>
            <Field name="group_lp" label={t("admin_create_field_group_lp")} autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={groupLp} onChange={e => setGroupLp(e.target.value)} isInvalid={error?.errors?.group_lp} autoComplete={false} />}
            </Field>
            <Field name="group_flex" label={t("admin_create_field_group_flex")} autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={groupFlex} onChange={e => setGroupFlex(e.target.value)} isInvalid={error?.errors?.group_flex} autoComplete={false} />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;

const OptionsWrapper = styled.div`
    padding: 5px 20px 20px;
    background: rgba(0,0,0,0.02);
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 15px;
`;

const OptionWrapper = styled.div`
    display: flex;
    align-items: start;
`;

const OptionColumn = styled.div`
    width: 50%;
    padding-right: 10px;
    padding-bottom: 0px;
    margin-bottom: 0px;
`;

const AddNewRow = styled.div`
    padding: 20px 0;
`;

const RemoveIconWrapper = styled.div`
    margin: 30px 5px 0;
`;
