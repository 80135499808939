import React, { useState } from "react";
import styled from "styled-components";
import Avatar, { AvatarItem } from "@atlaskit/avatar";
import RunUserWorkflow from "../workflows/run-user-workflow";
import Share from "../share";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import Lozenge from "@atlaskit/lozenge";
import RemoveUserFromDocument from "./remove-user-from-document";
import { useTranslation } from "react-i18next";

function DocumentUsers({ users, documentTypeId, documentId, reload }) {
    const [ userToRemove, setUserToRemove ] = useState(null);
    const { t } = useTranslation();

    return <>
        <Heading>
            <h4>{t("document_users_heading")}</h4>
            <ButtonWrapper>
                <Share documentId={documentId} onSuccess={reload} />
            </ButtonWrapper>
            <ButtonWrapper>
                <RunUserWorkflow documentTypeId={documentTypeId} documentId={documentId} onSuccess={reload} />
            </ButtonWrapper>
        </Heading>
        {users.map(user => (
            <UserWrapper key={user?.email}>
                <AvatarWrapper>
                    <AvatarItem
                        avatar={<Avatar src={user?.user?.avatar_url}  />}
                        primaryText={(user?.user?.first_name ?? "-") + " " + (user?.user?.last_name ?? "-")}
                        secondaryText={user?.role?.name}
                    />
                    {user?.status && <LozengeWrapper>
                        <Lozenge>{user?.status}</Lozenge>
                    </LozengeWrapper>}
                </AvatarWrapper>
                <DropdownMenu position="bottom right" triggerType="button">
                    <DropdownItemGroup>
                        <DropdownItem onClick={() => setUserToRemove(user)}>
                            {t("document_users_remove")}
                        </DropdownItem>
                    </DropdownItemGroup>
                </DropdownMenu>
            </UserWrapper>
        ))}
        {userToRemove && <RemoveUserFromDocument 
            user={userToRemove} 
            documentId={documentId} 
            onRemoved={() => {
                setUserToRemove(null);
                reload();
            }}
            onClose={() => setUserToRemove(null)} />}
    </>;
}

export default DocumentUsers;

const Heading = styled.div`
    display: flex;
    margin-bottom: 10px;

    &>h4 {
        flex-grow: 1;
    }
`;

const ButtonWrapper = styled.div`
    margin-left: 10px;
`;

const UserWrapper = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
`;

const AvatarWrapper = styled.div`
    flex-grow: 1;
`;

const LozengeWrapper = styled.div`
    padding: 5px
    padding-left: 50px;
    padding-top: 0px;
    margin-top: -7px;
`;