import Spinner from "../ui/spinner";
import React, { useState } from "react";
import "react-pivottable/pivottable.css";
import PivotTableUI from "react-pivottable/PivotTableUI";
import useDocumentsPivotList from "../../hooks/use-documents-pivot-list";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import ProgressBar from "@atlaskit/progress-bar";
import styled from "styled-components";
import SavePivotFilters from "../filters/save-pivot-filters";
import SelectPivotFilter from "./select-pivot-filter";

const PlotlyRenderers = createPlotlyRenderers(Plot);

export default function DocumentsListPivot({ id, space, fieldNames = [], options = {
    numericFieldTypeId: 2,
    dateFieldTypeId: 4,
    selectFieldTypeId: 3,
} }) {
    const { documents, isPending, pivotDocuments, progress } = useDocumentsPivotList(id, fieldNames, options, space);
    const [state, setState] = useState(null);
    const [filter, setFilter] = useState(null);
    const [hash, setHash] = useState(() => Math.random());
    const [filterState, setFilterState] = useState(null);

    if (isPending) {
        return <>
            <ProgressBar value={progress} />
        </>;
    }

    const data = documents || [];

    const toSave = {
        aggregatorName: state?.aggregatorName ?? "Count",
        rendererName: state?.rendererName ?? "Table",
        rows: state?.rows ?? [],
        cols: state?.cols ?? [],
        valueFilter: state?.valueFilter ?? {},
        rowOrder: state?.rowOrder ?? "value_a_to_z",
        colOrder: state?.colOrder ?? "value_a_to_z",
    };

    const load = toLoad => {
        setState(s => ({
            ...s,
            ...toLoad,
        }));
    };

    return <>
        <Flex>
            <Col>
                <SelectPivotFilter
                    hash={hash}
                    value={filter}
                    onChange={e => {
                        setFilter(e);
                        if (e) {
                            const filterData = JSON.parse(e?.data);
                            load({
                                ...filterData,
                                // data,
                            });
                        } else {
                            load({
                                aggregatorName: "Count",
                                rendererName: "Table",
                                rows: [],
                                cols: [],
                                valueFilter: {},
                                rowOrder: "value_a_to_z",
                                colOrder: "value_a_to_z",
                                // data: data,
                            });
                        }

                        setHash(h => h + Math.random() + 1);
                    }}
                    documentTypeId={id}
                />
            </Col>
            <Col />
            <SavePivotFilters
                documentTypeId={id}
                data={toSave}
                onSuccess={() => {
                    setHash(h => h + Math.random() + 1);
                }}
            />
        </Flex>
        <PivotTableUI
            key={space + String(hash)}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
            data={data}
            unusedOrientationCutoff={Infinity}
            {...state}
            onChange={e => {
                // delete e.data;
                setState(e);
            }}
            dataSourceSettings={{

            }} />
    </>;
}

const Flex = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
`;

const Col = styled.div`
    flex: 1;
    max-width: 225px;
`;