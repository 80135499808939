import { useEffect, useState } from "react";
import apiClient from "../api/api-client";
import spaceResponse from "../api/space-response";
import useAsync from "../hooks/use-async";

export default function useSpace(slug) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();
    const [forbidden, setForbidden] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        if (!slug) return;

        setForbidden(false);
        setLoaded(false);

        run(apiClient(`space/${slug}`))
            .then(() => setLoaded(true))
            .catch(error => {
                if (error.status === 403) {
                    setForbidden(true);
                } else {
                    setNotFound(true);
                }
            });
    }, [slug]);

    const reload = () => {
        run(apiClient(`space/${slug}`))
            .then(() => setLoaded(true))
            .catch(error => {
                if (error.status === 403) {
                    setForbidden(true);
                } else {
                    setNotFound(true);
                }
            });
    };

    return {
        space: data ? spaceResponse(data) : null,
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        forbidden,
        isLoaded: loaded,
        notFound,
        reload,
    };
}