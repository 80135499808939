import React, { useState } from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function DeleteFileModal({ file, onRemoved, onError }) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const close = () => setIsOpen(false);
    const open = () => setIsOpen(true);
    const remove = () => {
        if (isLoading) return;
        setIsLoading(true);
        apiClient(`file/${file.id}`, { method: "DELETE" })
            .then(() => {
                toast.success(t("attachment_removed"));
                onRemoved && onRemoved(file);
                close();
            })
            .catch(() => {
                toast.error(t("attachment_remove_error"));
                onError && onError(file);
            });
    };

    return (
        <>
            <span appearance="primary" onClick={open}>
                {t("attachment_remove")}
            </span>

            <ModalTransition>
                {isOpen && (
                    <Modal
                        isLoading={isLoading}
                        actions={[
                            { text: t("attachment_remove"), onClick: remove },
                            { text: t("attachment_cancel"), onClick: close },
                        ]}
                        onClose={close}
                        heading={t("attachment_remove_confirm")}
                        appearance="danger"
                    >
                        <p>
                            {t("attachment_remove_confirm_p")}
                        </p>
                    </Modal>
                )}
            </ModalTransition>
        </>
    );
}