import React, { useState } from "react";
import styled from "styled-components";
import AddGroupForm from "./add-group-form";
import Button from "@atlaskit/button";
import AddItemIcon from "@atlaskit/icon/glyph/add-item";
import InviteTeamIcon from "@atlaskit/icon/glyph/invite-team";
import AddGroupUser from "./add-group-user";
import Avatar, { AvatarItem } from "@atlaskit/avatar";
import { useTranslation } from "react-i18next";

function Group({ group, groups, onAdded }) {
    const [ addFormVisible, setAddFormVisible ] = useState(false);
    const [ addUserVisible, setAddUserVisible ] = useState(false);
    const { t } = useTranslation();

    return <Wrapper>
        <Heading>
            <GroupName>{group.name}</GroupName>
            {<ButtonWrapper>
                <Button onClick={() => setAddUserVisible(true)} iconBefore={<InviteTeamIcon />}>
                    {t("admin_group_assign_user")}
                </Button>
            </ButtonWrapper>}
            {<ButtonWrapper>
                <Button onClick={() => setAddFormVisible(v => !v)} iconBefore={<AddItemIcon />}>
                    {t("admin_group_add_department")}
                </Button>
            </ButtonWrapper>}
        </Heading>
        {addFormVisible && <AddGroupForm groupId={group.id} onAdded={onAdded} onHide={() => setAddFormVisible(false)} />}
        {addUserVisible && <AddGroupUser groupId={group.id} onAdded={onAdded} onHide={() => setAddUserVisible(false)} />}
        {group.user_group.length > 0 && <div>
            <UserGroup>
                <UserGroupContent>
                    {group.user_group.map(row => (
                        <UserGroupItem  key={row.user_id}>
                            <AvatarItem
                                key={row.user_id}
                                avatar={<Avatar size="small" src={row.user?.avatar_url} />}
                                primaryText={row.user.first_name + " " + row.user?.last_name}
                                secondaryText={row.position}
                            />
                        </UserGroupItem>
                    ))}
                </UserGroupContent>
            </UserGroup>
        </div>}
        {groups.filter(i => i.parent_id == group.id).map(g => (
            <Group group={g} groups={groups} key={g.id} onAdded={onAdded} />
        ))}
    </Wrapper>;
}

export default Group;

const Wrapper = styled.div`
    background: rgba(0,0,0,0.03);
    padding: 15px;
    border-radius: 3px;
`;

const ButtonWrapper = styled.div`
    margin-left: 10px;
`;

const Heading = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

const GroupName = styled.h3`
    color: #172B4D;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.42857142857143;
    margin: 10px 10px;
    flex-grow: 1;
`;

const UserGroup = styled.div`
margin: 20px 0;
`;

const UserGroupContent = styled.ul`
box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px 0px;
background: rgb(250, 251, 252) none repeat scroll 0% 0%;
border-radius: 3px;
padding: 0px;
`;

const UserGroupItem = styled.div`
background: rgb(255, 255, 255) none repeat scroll 0% 0%;
border-radius: 3px;
cursor: pointer;
display: flex;
position: relative;
height: 40px;
padding: 0px 8px;
border-bottom: 1px solid rgb(235, 236, 240);
display: flex;
-moz-box-align: center;
align-items: center;
`;