import React, { useCallback, useEffect, useState } from "react";
import DocumentLayout from "../layouts/document-layout";
import { useParams, useLocation } from "react-router-dom";
import useSpace from "../hooks/use-space";
import Spinner from "../components/ui/spinner";
import { BreadcrumbsItem, BreadcrumbsStateless } from "@atlaskit/breadcrumbs";
import PageHeader from "@atlaskit/page-header";
import InlineMessage from "@atlaskit/inline-message";
import styled from "styled-components";
import DocumentsList from "../components/documents/documents-list";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import { Link, Redirect } from "react-router-dom";
import spacePath, { modulePath } from "../utils/space-path";
import DropdownMenu, { DropdownItemGroupCheckbox, DropdownItemCheckbox, DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import Badge from "@atlaskit/badge";
import useDocumentsList from "../hooks/use-documents-list";
import useDocumentTypeFilters from "../hooks/use-document-type-filters";
import useDocumentStatuses from "../hooks/use-document-statuses";
import SectionMessage from "@atlaskit/section-message/section-message";
import SectionMessageAction from "@atlaskit/section-message/section-message-action";
import Filters from "../components/filters/filters";
import Pagination from "@atlaskit/pagination";
import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet";
import useAvailableDocumentTypes from "../hooks/use-available-document-types";
import DocumentsListPivot from "../components/pivot/documents-list-pivot";
import CommentsListView from "../components/comments/comments-list-view";
import { useTranslation } from "react-i18next";
import apiClient from "../api/api-client";
import Widgets from "../components/widgets/widgets";
import { toast } from "react-toastify";
import FacebookView from "../components/facebook/facebook-view";
import GanttView from "../components/gantt/gantt-view";
import AbsoluteSpinner from "../components/ui/absolute-spinner";
import title from "../title";
import { NewPageInner } from "./new";
import useAsync from "../hooks/use-async";
import { DisabledBreadcrumb } from "./document";

function SpacePage() {
    const { t } = useTranslation();
    const query = useQuery();
    const [trashed, setTrashed] = useState(false);
    const queryFilterId = query.get("filter");
    const documentFilterId = query.get("document");
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDir, setSortDir] = useState(null);
    const [exporting, setExporting] = useState(false);
    const [columnsOrder, setColumnsOrder] = useState(null);
    const [columnsVisible, setColumnsVisible] = useState(["name"]);
    const { space: slug } = useParams();
    const [createOpen, setCreateOpen] = useState(false);
    const { space, isError, forbidden, isLoaded, notFound, isPending: isSpacePending } = useSpace(slug);
    const documentTypeId = space?.document_type?.id;
    const { data: availableDocumentTypes, isLoaded: isLoadedDocumentTypes } = useAvailableDocumentTypes(documentTypeId);
    const [filters, setFilters] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [skip, setSkip] = useState(0);
    const config = space?.config ?? {};
    let disableLinks = config?.disableLinks === true || config?.disableLinks === "true" || isSpacePending || space?.slug != slug;
    const { isPending, fields, documents, columns, toggleColumn, savedColumns, isIdle, isLoadedFields, exportList, newExportList, reload, related } = useDocumentsList(documentTypeId, page, limit, sortColumn, sortDir, filters, null, queryFilterId, documentFilterId, null, columnsVisible, availableDocumentTypes, disableLinks, false, trashed);
    const [appended, setAppended] = useState([]);
    const { statuses } = useDocumentStatuses(documentTypeId);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const pagination = documents?.pagination;
    const { data: documentTypeFilters } = useDocumentTypeFilters(documentTypeId);
    let filtersEnabled = config?.filters ?? true;
    let exportEnabled = !config?.exportDisabled;
    let createEnabled = space?.document_type?.creatable;

    if (space?.document_type?.config?.disableCreate) {
        createEnabled = false;
    }

    const isDrawer = space?.document_type?.config?.drawer;
    const sortHandle = useCallback((value) => {
        setSortColumn(value?.id ?? value?.name ?? "id");
        setSortDir(parseInt(value?.dir));
    }, []);
    const filteredFields = fields?.filter(f => !["list"].includes(f?.field?.type)).filter(i => i?.field?.type !== "section" && i?.field?.tye !== "section");
    const localStorageKey = `columns-${documentTypeId}`;
    const onColumnVisibleChange = useCallback(({ column, visible }) => {
        const name = column?.name;

        if (visible) {
            setColumnsVisible(v => ([
                ...v,
                name
            ]));
        } else {
            setColumnsVisible(v => v?.filter(i => i !== name));
        }
    }, []);

    useEffect(() => {
        if (!slug) return;
        setCreateOpen(false);
    }, [slug]);

    const updatePage = (l, s) => {
        const p = 1 + parseInt(s / l);
        setSkip(s);
        setLimit(l);
        setPage(p);
    };

    const fieldsMap = fields?.reduce((acc, f) => {
        acc[f?.name] = f?.field?.type;
        return acc;
    }, {});

    const isPivotView = config?.view === "pivot";
    const isCommentsView = config?.view === "comments";
    const isFacebookView = config?.view === "facebook";
    const isGanttView = config?.view === "gantt";
    const isSingleView = config?.view === "single";
    const isEmptyView = fields?.length === 0;
    const colorField = config?.colorField;
    const colorColumn = config?.colorColumn;
    const bgField = config?.bgField;

    if (isCommentsView || isFacebookView || isEmptyView) {
        filtersEnabled = false;
        exportEnabled = false;
        createEnabled = false;
    }

    if (isGanttView) {
        filtersEnabled = false;
        exportEnabled = false;
    }

    const download = (format) => {
        setExporting(true);
        exportList(format)
            .then(data => {
                let element = document.createElement("a");
                element.setAttribute("href", URL.createObjectURL(data));
                element.setAttribute("download", `${slug}.${format}`);
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                setTimeout(() => {
                    URL.revokeObjectURL(data);
                    element.remove();
                }, 100);
            })
            .catch((e) => {
                toast.error(t("space_export_error"));
            })
            .finally(() => setExporting(false));
    };

    const newDownload = (format) => {
        newExportList(format)
            .then(data => {
                const url = data?.url;
                if (url) {
                    window.open(url, "_blank");
                    // window.location.href = url;
                } else {
                    toast.error(t("space_export_error"));
                }
                // let element = document.createElement("a");
                // element.setAttribute("href", url);
                // element.setAttribute("download", `${slug}.${format}`);
                // element.style.display = "none";
                // document.body.appendChild(element);
                // element.click();
                // document.body.removeChild(element);
                // setTimeout(() => {
                //     URL.revokeObjectURL(data);
                //     element.remove();
                // }, 100);
            })
            .catch((e) => {
                toast.error(t("space_export_error"));
            });
    };

    // useEffect(() => {
    //     if (!queryFilterId) {
    //         setFilters({});
    //         return;
    //     }
    //     if (!documentTypeFilters[queryFilterId]) return;

    //     const filt = {
    //         filters: documentTypeFilters[queryFilterId].filters.map(f => ({
    //             ...f,
    //             id: uuidv4(),
    //             type: fieldsMap[f?.name],
    //         })),
    //         type: documentTypeFilters[queryFilterId].condition,
    //     };

    //     filt.filters = filt.filters?.map(f => {
    //         try {
    //             const val = JSON.parse(f?.value);

    //             return {
    //                 ...f,
    //                 name: parseInt(f?.name),
    //                 column: parseInt(f?.column),
    //                 value: val,
    //                 type: "document_type"
    //             };
    //         } catch (e) {
    //             return f;
    //         }
    //     }) || [];

    //     setFilters(filt);
    // }, [ queryFilterId, documentTypeFilters ]);

    useEffect(() => {
        if (!isLoaded) return;
        if (!isLoadedFields) return;
        if (!isLoadedDocumentTypes) return;

        if (window.localStorage.getItem(localStorageKey)) {
            setColumnsVisible(JSON.parse(window.localStorage.getItem(localStorageKey)));
        } else {
            if (space?.document_type?.config?.columnsVisible) {
                setColumnsVisible(space?.document_type?.config?.columnsVisible);
            }
        }

        if (space?.document_type?.config?.columnsOrder) {
            setColumnsOrder(space?.document_type?.config?.columnsOrder);
        } else {
            setColumnsOrder(null);
        }

        if (space?.document_type?.config?.sortColumn) {
            setSortColumn(space?.document_type?.config?.sortColumn);
        } else {
            setSortColumn("id");
        }

        if (space?.document_type?.config?.sortDir) {
            setSortDir(space?.document_type?.config?.sortDir);
        } else {
            setSortDir(1);
        }


        // if (!queryFilterId && !documentFilterId) {
        //     if (filters != null && Object.keys(filters).length > 0) {
        //         setFilters({});
        //     }
        //     return;
        // }
        if (!queryFilterId) {
            if (filters != null && Object.keys(filters).length > 0) {
                setFilters({});
            }
            return;
        }

        if (!documentTypeFilters[queryFilterId]) return;

        const filt = {
            filters: documentTypeFilters[queryFilterId].filters.map(f => ({
                ...f,
                id: uuidv4(),
                type: fieldsMap[f?.name],
            })),
            type: documentTypeFilters[queryFilterId].condition,
            sortColumn: documentTypeFilters[queryFilterId].sort_column ?? undefined,
            sortDir: documentTypeFilters[queryFilterId].sort_dir ?? undefined,
            columnsVisible: documentTypeFilters[queryFilterId].columns_visible ?? undefined,
        };

        filt.filters = filt.filters?.map(f => {
            try {
                const val = JSON.parse(f?.value);

                if (typeof val !== "object" || val == null) return f;

                return {
                    ...f,
                    name: parseInt(f?.name),
                    column: parseInt(f?.column),
                    value: val,
                    type: "document_type"
                };
            } catch (e) {
                return f;
            }
        }) || [];


        if (filt?.sortColumn) setSortColumn(filt?.sortColumn);
        if (filt?.sortDir) setSortDir(filt?.sortDir);
        if (filt?.columnsVisible && !window.localStorage.getItem(localStorageKey)) setColumnsVisible(filt?.columnsVisible);

        setFilters(filt);
    }, [isLoaded, isLoadedFields, isLoadedDocumentTypes, queryFilterId, documentTypeFilters]);

    useEffect(() => {
        if (!isLoaded) return;
        if (!isLoadedFields) return;
        if (!isLoadedDocumentTypes) return;
        if (queryFilterId && !documentTypeFilters) return;


        window.localStorage.setItem(localStorageKey, JSON.stringify(columnsVisible));
    }, [columnsVisible, isLoaded, isLoadedFields, isLoadedDocumentTypes, queryFilterId, documentTypeFilters]);

    // useEffect(() => {
    //     if (!isLoaded) return;
    //     if (!isLoadedFields) return;
    //     if (!isLoadedDocumentTypes) return;
    //     if (!documentFilterId) return;

    //     const load = () => {
    //         apiClient(`workflow/document/${documentFilterId}?legacy_links=false`)
    //             .then(response => {
    //                 const nameField = response?.document_type?.fields?.find(i => i?.name === "name");
    //                 const name = response?.values?.find(i => i?.document_type_field_id === nameField?.id)?.value ?? "-";

    //                 setFilters({
    //                     type: "AND",
    //                     filters: [
    //                         {
    //                             id: 0,
    //                             column: response?.document_type_id,
    //                             operator: "IS",
    //                             type: "document_type",
    //                             value: {
    //                                 data: response,
    //                                 value: documentFilterId,
    //                                 label: name,
    //                             }
    //                         }
    //                     ]
    //                 });
    //             })
    //             .catch(() => { });
    //     };

    //     load();
    // }, [isLoaded, isLoadedFields, isLoadedDocumentTypes, documentFilterId, space?.document_type?.id]);

    if (forbidden) {
        return <DocumentLayout>
            <h1>403</h1>
            <h3>{t("forbidden")}</h3>
        </DocumentLayout>;
    }

    if (notFound) {
        window.location.href = "/404";
        return null;
    }

    if (isPending && !documentTypeId) {
        return <DocumentLayout>
            <Spinner />
        </DocumentLayout>;
    }

    if (isError) return <ErrorMessageWrapper data-testid="alert">
        <InlineMessage type="error" secondaryText={t("unknown_error")} />
    </ErrorMessageWrapper>;

    const breadcrumbs = (
        <BreadcrumbsStateless>
            {space?.category?.module && <BreadcrumbsItem text={space.category.module?.alias} key="module" component={(props) => <Link to={modulePath(space?.category?.module)} {...props} />} />}
            {space?.category && <DisabledBreadcrumb>{space.category.name}</DisabledBreadcrumb>}
        </BreadcrumbsStateless>
    );

    const actionsContent = (
        <ButtonGroup>
            <HeaderButtonWrapper>
                {filtersEnabled && <Button onClick={() => setFiltersOpen(value => !value)}>
                    <span>{t("documents_filters")} <Badge>{filters?.filters?.length || 0}</Badge></span>
                </Button>}
            </HeaderButtonWrapper>
            <HeaderButtonWrapper>
                {createEnabled && !isDrawer && <Link to={`${spacePath(space)}/new`}>
                    <Button appearance="primary">
                        {t("documents_create")}
                    </Button>
                </Link>}
                {createEnabled && isDrawer && <Button appearance="primary" onClick={() => setCreateOpen(true)}>
                    {t("documents_create")}
                </Button>}
            </HeaderButtonWrapper>
            {exportEnabled && <HeaderButtonWrapper>
                <DropdownMenu position="bottom right" triggerType="button">
                    <DropdownItemGroup>
                        <DropdownItem onClick={() => download("xlsx")}>
                            XLSX
                        </DropdownItem>
                        <DropdownItem onClick={() => newDownload("csv")}>
                            CSV
                        </DropdownItem>
                    </DropdownItemGroup>
                </DropdownMenu>
            </HeaderButtonWrapper>}
        </ButtonGroup >
    );

    let content;

    if (isPivotView) {
        content = <>
            <DocumentsListPivot
                key={space?.slug}
                space={space?.slug}
                documentTypeId={documentTypeId}
                fields={fields}
                documents={documents}
                isPending={isPending}
                id={config?.documentTypeId}
                fieldNames={config?.fields}
                options={{
                    numericFieldTypeId: config?.numericFieldTypeId,
                    dateFieldTypeId: config?.dateFieldTypeId,
                    selectFieldTypeId: config?.selectFieldTypeId,
                }}
            />
        </>;
    } else if (isCommentsView) {
        content = <CommentsListView
            documentTypeId={config?.documentTypeId}
            filteredById={config?.filteredBy}
            path={config?.path}
            orderTypeId={config?.orderTypeId}
            positionTypeId={config?.positionTypeId}
            clientTypeId={config?.clientTypeId}
        />;
    } else if (isFacebookView) {
        content = <FacebookView />;
    } else if (isGanttView) {
        content = <GanttView {...config} currentDocumentTypeId={documentTypeId} queryFilterId={queryFilterId} />;
    } else if (isSingleView) {
        if (isPending || isSpacePending || fields?.length == 0 || fields?.[0]?.document_type_id != documentTypeId) {
            console.log("STILL PENDING");

            return <DocumentLayout>
                <Spinner />
            </DocumentLayout>;
        }

        console.log("LENGTH: ", documents?.data?.length);

        if (documents?.data?.length > 0) {
            console.log("LENGTH 0");

            const first = documents?.data?.[0];

            // check if first is the same type as current space
            if (first?.space?.slug !== slug) {
                console.log("INVALID SLUG", slug, first?.space?.slug);
                return <DocumentLayout>
                    <Spinner />
                </DocumentLayout>;
            }


            console.log("SLUGS MATCH", slug, first?.space?.slug);

            return <Redirect to={`${window.location.pathname}/${first?.id}`} />;
        }

        if (space?.slug !== slug) {
            console.log("INVALID SPACE SLUG", slug, space?.slug);

            return <DocumentLayout>
                <Spinner />
            </DocumentLayout>;
        }

        // if (first?.space?.slug !== slug) {
        //     return <DocumentLayout>
        //         <Spinner />
        //     </DocumentLayout>;
        // }


        console.log("REDIRECT", slug, space?.slug);

        return <Redirect to={`${window.location.pathname}/new`} />;

    } else if (fields?.length === 0) {
        content = null;
    } else {
        content = <>
            {!documentTypeId && <DocumentTypeError />}
            {filtersEnabled && filtersOpen && <FiltersArea>
                <Filters documentTypeId={documentTypeId}
                    onCancel={() => setFiltersOpen(false)}
                    onHide={() => setFiltersOpen(false)}
                    initialFilters={filters?.filters}
                    initialLinks={filters?.links}
                    trashed={trashed}
                    setTrashed={e => {
                        setPage(1);
                        setSkip(0);
                        setTrashed(e);
                    }}
                    initialType={filters?.type}
                    onAccept={e => {
                        setPage(1);
                        setFilters(e);
                    }}
                    statuses={statuses}
                    sortColumn={sortColumn}
                    sortDir={sortDir}
                    columnsVisible={columnsVisible}
                    columns={[
                        { id: "name", label: fields?.find(i => i?.name === "name")?.label ?? t("column_name"), name: "name", type: "text" },
                        { id: "users", label: t("column_users"), name: "users", type: "users" },
                        ...columns,
                        ...availableDocumentTypes?.map(dt => ({
                            id: dt.value, label: dt.label, name: dt.value, type: "document_type",
                        })) || [],
                    ]} />
            </FiltersArea>}
            {documentTypeId && <DocumentsListWrapper>
                <DocumentsList isPending={isPending}
                    fields={filteredFields} documents={documents}
                    onSkipChange={s => {
                        updatePage(limit, parseInt(s));
                    }}
                    onLimitChange={l => {
                        setLimit(l);
                        setPage(1);
                        setSkip(0);
                    }}
                    reload={reload}
                    limit={limit}
                    colorField={colorField}
                    colorColumn={colorColumn}
                    bgField={bgField}
                    trashed={trashed}
                    skip={skip}
                    availableDocumentTypes={availableDocumentTypes}
                    savedColumns={savedColumns}
                    sortHandle={sortHandle}
                    sortColumn={sortColumn}
                    sortDir={sortDir}


                    disableCreate={space?.document_type?.config?.disableCreate}
                    disableDelete={space?.document_type?.config?.disableDelete}
                    disableEdit={space?.document_type?.config?.disableEdit}

                    related={related}
                    columnsOrder={columnsOrder}
                    columnsFlex={space?.document_type?.config?.columnsFlex ?? {}}
                    columnsWidths={space?.document_type?.config?.columnsWidths ?? {}}
                    defaultColumnWidth={space?.document_type?.config?.defaultColumnWidth}
                    onColumnOrderChange={setColumnsOrder}
                    columnVisible={columnsVisible}
                    onColumnVisibleChange={onColumnVisibleChange}
                    setColumnsOrder={setColumnsOrder}
                    isDrawer={isDrawer}
                    space={space} columns={columns} />
            </DocumentsListWrapper>}
        </>;
    }

    return <>
        {space && space.name && <Helmet>
            <title>{`${space?.name} - ${title}`}</title>
        </Helmet>}
        <DocumentLayout>
            {config?.widgets && space?.category?.module && <Widgets widgets={config?.widgets} module={space?.category?.module?.name} />}
            {config?.message && <SectionMessageWrapper>
                <SectionMessage
                    title={config?.message?.title}
                    appearance={config?.message?.appearance}
                    actions={config?.message?.actions?.map(a => (
                        <SectionMessageAction key={a.label} href={a?.href}>{a?.label}</SectionMessageAction>
                    ))}
                >
                    {config?.message?.body}
                </SectionMessage>
            </SectionMessageWrapper>}
            {exporting && <SectionMessageWrapper>
                <SectionMessage
                    title="Export..."
                    appearance="information"
                >
                    <Spinner />
                </SectionMessage>
            </SectionMessageWrapper>}
            <PageHeader
                breadcrumbs={breadcrumbs}
                actions={isPivotView ? null : actionsContent}>
                {space?.name}
            </PageHeader>
            {content}

            {createOpen && <Drawer space={space} onClose={() => setCreateOpen(false)} onReload={() => {
                setCreateOpen(false);
                reload();
            }} />}
        </DocumentLayout>
    </>;

    function DocumentTypeError() {
        return <SectionMessage
            appearance="error"
            testId="alert"
            title="Error"
        >
            {t("documents_no_document_type")}
        </SectionMessage>;
    }
}

export default SpacePage;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ErrorMessageWrapper = styled.div`
    padding: 20px;
    color: black;
`;

const HeaderButtonWrapper = styled.div`
    margin-left: 10px;
`;

export const FiltersArea = styled.div`
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0;
    background: #FAFBFC;
`;

const DocumentsListWrapper = styled.div`
max-width: 100%;
`;

const SectionMessageWrapper = styled.div`
margin-top: 20px;
`;

export function Drawer({ onClose, onReload = null, space, initialValues, onSaved, renderer, isNested = false }) {
    const { run, isPending: isCreating, error, isError: isCreatingError } = useAsync();

    const handleSubmit = data => {
        run(apiClient(`workflow/document-type/${space?.document_type?.id}/document`, { data }))
            .then(response => {
                if (onSaved) {
                    onSaved();
                } else {
                    window.location.href = `${spacePath(space)}/${response.id}`;
                }
            })
            .catch(() => {
                toast.error(t("new_error"));
            });
    };

    return <div className="drawerWrapper" tabIndex="-1">

        <div className="drawerClose" onClick={() => onClose()}>
            &times;
        </div>

        <div className="">
            <NewPageInner
                isNested={isNested}
                space={space}
                onSuccess={onReload}
                handleSubmit={handleSubmit}
                isCreating={isCreating}
                error={error}
                isCreatingError={isCreatingError}
                initialValues={initialValues}
                renderer={renderer}
            />
        </div>

        <style dangerouslySetInnerHTML={{
            __html: `
            .drawerWrapper {
                background-color: rgb(255, 255, 255);
                border-bottom-color: rgb(229, 231, 235);
                border-bottom-style: solid;
                border-bottom-width: 0px;
                border-left-color: rgb(229, 231, 235);
                border-left-style: solid;
                border-left-width: 0px;
                border-right-color: rgb(229, 231, 235);
                border-right-style: solid;
                border-right-width: 0px;
                border-top-color: rgb(229, 231, 235);
                border-top-style: solid;
                border-top-width: 0px;
                box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px;
                box-sizing: border-box;
                color: rgb(0, 0, 0);
                display: block;
                font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                font-feature-settings: normal;
                font-variation-settings: normal;
                height: 100vh;
                line-height: 24px;
                max-width: 1000px;
                outline-color: rgb(0, 0, 0);
                outline-style: none;
                outline-width: 0px;
                overflow-y: auto;
                padding-bottom: 24px;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 24px;
                position: fixed;
                right: 0px;
                tab-size: 4;
                top: 0px;
                width: 100%;
                z-index: 300;
            }

            .drawerClose {
                border-bottom-color: rgb(229, 231, 235);
                border-bottom-style: solid;
                border-bottom-width: 0px;
                border-left-color: rgb(229, 231, 235);
                border-left-style: solid;
                border-left-width: 0px;
                border-right-color: rgb(229, 231, 235);
                border-right-style: solid;
                border-right-width: 0px;
                border-top-color: rgb(229, 231, 235);
                border-top-style: solid;
                border-top-width: 0px;
                box-sizing: border-box;
                color: rgb(0, 0, 0);
                cursor: pointer;
                display: block;
                font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                font-feature-settings: normal;
                font-variation-settings: normal;
                font-size: 24px;
                height: 56px;
                left: 6px;
                line-height: 24px;
                outline-color: rgb(0, 0, 0);
                outline-style: none;
                outline-width: 0px;
                padding-bottom: 16px;
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 21px;
                position: absolute;
                tab-size: 4;
                top: 0px;
                width: 41.765625px;
            }
            `
        }}>

        </style>
    </div>;
}