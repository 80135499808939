import React, { useState } from "react";
import UserLayout from "../layouts/user-layout";
import { ProfileCard } from "@atlaskit/profilecard";
import styled from "styled-components";
import { useAuth } from "../providers/auth-provider";
import Tabs from "@atlaskit/tabs";
import NotificationSettings from "../components/profile/notification-settings";
import AvatarUpload from "../components/profile/avatar-upload";
import ChangePassword from "../components/profile/change-password";
import PersonalSettings from "../components/profile/personal-settings";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function Profile() {
    const { user } = useAuth();
    const [ avatarUploadOpen, setAvatarUploadOpen ] = useState(false);
    const { t } = useTranslation();

    const tabs = [
        { label: t("profile_basic"), content: <PersonalSettings /> },
        { label: t("profile_notifications"), content: <NotificationSettings /> },
        { label: t("profile_password"), content: <ChangePassword /> },
    ];

    return <>
        <Helmet>
            <title>{t("profile_title")}</title>
        </Helmet>
        <UserLayout>
            <Wrapper>
                <Left>
                    <ProfileCard
                        fullName={user.first_name + " " + user.last_name}
                        email={user.email}
                        avatarUrl={user.avatar_url}
                        actions={[
                            { label: t("profile_change_avatar"), callback: () => setAvatarUploadOpen(true) }
                        ]}
                    />
                    <AvatarUpload isOpen={avatarUploadOpen} onClose={() => setAvatarUploadOpen(false)} />
                </Left>
                <Right>
                    <Tabs tabs={tabs} />
                </Right>
            </Wrapper>
        </UserLayout>
    </>;
}

export default Profile;

const Wrapper = styled.div`
@media (min-width: 1300px) {
    display: flex;
}
`;

const Left = styled.div`
margin-bottom: 50px;
`;

const Right = styled.div`
@media (min-width: 1300px) {
    flex-grow: 1;
    padding: 0 40px;
}
`;