import React, { useState } from "react";
import styled from "styled-components";
import Form, { Field } from "@atlaskit/form";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import PageHeader from "@atlaskit/page-header";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import TextField from "@atlaskit/textfield";
import { useTranslation } from "react-i18next";

function ChangePassword() {
    const [ pending, setPending ] = useState(false);
    const [ password, setPassword ] = useState("");
    const [ passwordConfirmation, setPasswordConfirmation ] = useState("");
    const { t } = useTranslation();

    const actionsContent = <ButtonGroup>
        <Button appearance="primary" type="submit" isLoading={pending} isDisabled={!password || !passwordConfirmation || passwordConfirmation !== password}>
            {t("profile_password_save")}
        </Button>
    </ButtonGroup>;

    const  handleSubmit = (e) => {
        e.preventDefault();
        setPending(true);
        apiClient("user/password", {
            method: "PATCH",
            data: {
                password: password,
                password_confirmation: passwordConfirmation
            }
        })
            .then(res => {
                toast.success(t("profile_password_success"));
                setPassword("");
                setPasswordConfirmation("");
            })
            .catch(() => toast.error(t("profile_password_error")))
            .finally(() => setPending(false));
    };

    return <Wrapper>
        <Form>
            {({ formProps }) => (
                <form {...formProps} onSubmit={handleSubmit}>
                    <PageHeader actions={actionsContent}>
                        {t("profile_password")}
                    </PageHeader>
                    <Field label={t("profile_password_password")} name="password">
                        {({ fieldProps }) => <TextField {...fieldProps} name="password" type="password" label="Nowe hasło" value={password} onChange={e => setPassword(e.target.value)} />}
                    </Field>
                    <Field label={t("profile_password_password_confirmation")} name="password_confirmation">
                        {({ fieldProps }) => <TextField {...fieldProps} name="password_confirmation" type="password" label="Powtórz hasło" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} />}
                    </Field>
                </form>
            )}
        </Form>
    </Wrapper>;
}

export default ChangePassword;

const Wrapper = styled.div`
width: 100%;
`;
