import React, { useState } from "react";
import styled from "styled-components";
import { API_URL } from "../../api/api-client";
import { toast } from "react-toastify";
import { AvatarPickerDialog } from "@atlaskit/media-avatar-picker";

function AvatarUpload({ isOpen, onClose }) {
    const [ pending, setPending ] = useState(false);

    if (!isOpen) return null;

    return <AvatarPickerDialog
        isLoading={pending}
        avatars={[]}
        onImagePicked={(selectedImage, crop) => {
            const token = window.localStorage.getItem("auth.token");
            setPending(true);
            const data = new FormData();
            data.append("avatar", selectedImage);
            window.fetch(`${API_URL}/user/profile/avatar`, {
                method: "POST",
                body: data,
                headers: {
                    "Authorization": token ? `Bearer ${JSON.parse(token)}` : undefined,
                },
            })
                .then(response => {
                    if (response.ok) {
                        toast.success("Avatar został zaktualizowany");
                        window.location.reload();
                    } else {
                        toast.error("Nie udało się zaktualizować avatara");
                    }
                    setPending(false);
                    onClose();
                });
        }}
        onCancel={() => onClose()}
    />;
}

export default AvatarUpload;
