import React, { createContext, useContext } from "react";
import useModulesHook from "../hooks/use-modules";

export const ModulesContext = createContext();

export default function ModulesProvider(props) {
    const data = useModulesHook();

    return <ModulesContext.Provider value={data} {...props} />;
}

export function useModules() {
    const data = useContext(ModulesContext);

    if (data === undefined) {
        throw new Error("useModules must be used inside ModulesProvider");
    }

    return data;
}