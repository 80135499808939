import { useEffect } from "react";
import apiClient from "../api/api-client";
import groupsResponse from "../api/groups-response";
import useAsync from "../hooks/use-async";

export default function useStructure() {
    const { run, isError, isSuccess, isIdle, isPending, data } = useAsync();

    useEffect(() => {
        reload();
    }, []);

    const reload = () => run(apiClient("groups")).catch(() => { });

    const addUserToGroup = ({ userId, groupId, position, supervisor }) => {
        return apiClient("group/user", { data: {
            user_id: userId,
            group_id: groupId,
            position,
            supervisor,
        }});
    };

    return {
        groups: data ? groupsResponse(data) : [],
        isPending: isIdle || isPending,
        isError: isError,
        isSuccess: isSuccess,
        addUserToGroup,
        reload,
    };
}