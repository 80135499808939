import { useEffect, useState } from "react";
import apiClient from "../api/api-client";
import useAsync from "../hooks/use-async";

export default function useWidget(name, documentId) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();
    const [ notFound, setNotFound ] = useState(false);

    useEffect(() => {
        if (!name) return;
        if ([ "ASSIGNED", "NOTIFICATIONS"].includes(name)) return;

        setNotFound(false);

        let uri = `widgets/${name}`;

        if (documentId) {
            uri = uri + "?document_id=" + documentId;
        }

        run(apiClient(uri))
            .catch(() => setNotFound(true));
    }, [name]);

    return {
        data: data?.data ?? {},
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        notFound,
    };
}