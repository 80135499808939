/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function CreateDocumentType({ onClose, onAdded, spaceId }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [ name, setName ] = useState("");
    const { t } = useTranslation();

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit}>
                    {t("create_document_type_create")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("create_document_type_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        run(apiClient("document-types", { data: {
            name,
            space_id: spaceId,
        }}))
            .then(response => {
                onAdded(response);
                toast.success(t("create_document_type_success"));
                onClose();
            })
            .catch(() => {
                toast.error(t("create_document_type_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("create_document_type_heading")}
            scrollBehavior="inside-wide"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("create_document_type_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="name" label={t("create_document_type_name")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={name} onChange={e => setName(e.target.value)} isInvalid={error?.errors?.name} autoComplete={false} />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;