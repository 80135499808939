/* eslint-disable no-unreachable */
import React, { useEffect, useState } from "react";
import Spinner from "../../components/ui/spinner";
import AbsoluteSpinner from "../../components/ui/absolute-spinner";
import AdminLayout from "../../layouts/admin-layout";
import PageHeader from "@atlaskit/page-header";
import styled from "styled-components";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import DynamicTable from "@atlaskit/dynamic-table";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { Link, useParams } from "react-router-dom";
import useAdminSpace from "../../hooks/use-admin-space";
import useAdminDocumentType from "../../hooks/use-admin-document-type";
import useAdminFields from "../../hooks/use-admin-fields";
import { Checkbox } from "@atlaskit/checkbox";
import toBoolean from "../../utils/to-boolean";
import CreateField from "../../components/fields/create-field";
import EditField from "../../components/fields/edit-field";
import RemoveField from "../../components/fields/remove-field";
import EmptyState from "@atlaskit/empty-state";
import CreateDocumentType from "../../components/documents/create-document-type";
import RemoveRelation from "../../components/relations/remove-relation";
import CreateRelation from "../../components/relations/create-relation";
import useAdminDocumentTypes from "../../hooks/use-admin-document-types";
import RemoveDocumentType from "../../components/documents/remove-document-type";
import ImportModal from "../../components/import/import-modal";
import ExportModal from "../../components/export/export-modal";
import ConfigModal from "../../components/spaces/config-modal";
import CreatePdfConfig from "../../components/document-type/create-pdf-config";
import EditPdfConfig from "../../components/document-type/edit-pdf-config";
import RemovePdfConfig from "../../components/document-type/remove-pdf-config";
import EditDocumentType from "../../components/document-type/edit-document-type";
import useAdminDocumentTypeWorkflows from "../../hooks/use-admin-document-type-workflows";
import RemoveWorkflowFromDocumentType from "../../components/document-type/remove-workflow-from-document-type";
import AttachWorkflowToDocumentType from "../../components/document-type/attach-workflow-to-document-type";
import EditDocumentTypeWorkflow from "../../components/document-type/edit-document-type-workflow";
import EditDocumentTypeLink from "../../components/document-type/edit-document-type-link";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAdminRoles from "../../hooks/use-admin-roles";

function AdminSpace() {
    const { t } = useTranslation();
    const { module, category, slug } = useParams();
    const { isPending: isPendingFields, fields } = useAdminFields();
    const { isPending, space, reload: reloadSpace } = useAdminSpace(module, category, slug);
    const { isPending: isPendingDocumentType, documentType, reload, error } = useAdminDocumentType(space?.document_type?.id);
    const [docType, setDocType] = useState(null);
    const [addOpen, setAddOpen] = useState(false);
    const [addRelationOpen, setAddRelationOpen] = useState(false);
    const [fieldToEdit, setFieldToEdit] = useState(null);
    const [fieldToRemove, setFieldToRemove] = useState(null);
    const [addDocumentOpen, setAddDocumentOpen] = useState(false);
    const [relationToRemove, setRelationToRemove] = useState(false);
    const { data: documentTypes, dataMap: documentTypesMap } = useAdminDocumentTypes(1000);
    const data = [];
    const { roles } = useAdminRoles();
    const [itemToRemove, setItemToRemove] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [importOpen, setImportOpen] = useState(false);
    const [exportOpen, setExportOpen] = useState(false);
    const [configOpen, setConfigOpen] = useState(false);
    const [addConfigOpen, setAddConfigOpen] = useState(false);
    const [configToEdit, setConfigToEdit] = useState(null);
    const [configToRemove, setConfigToRemove] = useState(null);
    const [documentTypeToEdit, setDocumentTypeToEdit] = useState(null);
    const { data: documentTypeWorkflows, isPending: isPendingDocumentTypeWorkflows, reload: reloadWorkflows } = useAdminDocumentTypeWorkflows(space?.document_type?.id);
    const [workflowToRemove, setWorkflowToRemove] = useState(null);
    const [workflowToEdit, setWorkflowToEdit] = useState(null);
    const [addWorkflowOpen, setAddWorkflowOpen] = useState(null);
    const [maxPos, setMaxPos] = useState(0);
    const reorder = useAsync();

    useEffect(() => {
        if (isPendingDocumentType) return;
        if (!documentType?.fields) return;

        setMaxPos(0);
        documentType?.fields?.map(f => {
            const position = parseInt(f?.position);
            if (position > maxPos) {
                setMaxPos(position);
            }
        });

        setDocType(documentType);
    }, [isPendingDocumentType, documentType?.fields]);

    const pdfsData = documentType?.print_patterns ?? [];

    if (isPending || (space?.document_type?.id && isPendingDocumentType && !documentType) || isPendingFields) {
        return <AdminLayout>
            <Spinner />
        </AdminLayout>;
    }

    if (space?.document_type?.id && documentType?.space?.slug && documentType?.space?.slug !== slug) {
        return <AdminLayout>
            <Spinner />
        </AdminLayout>;
    }

    if (!space?.document_type) {
        return <AdminLayout>
            <PageHeader>{space?.name}</PageHeader>
            <EmptyState header="Brak typu dokumentu" description="" primaryAction={<Button appearance="primary" onClick={() => setAddDocumentOpen(true)}>Dodaj typ dokumentu</Button>} />
            {addDocumentOpen && <CreateDocumentType spaceId={space?.id} onClose={() => setAddDocumentOpen(false)} onAdded={reloadSpace} />}
        </AdminLayout>;
    }

    const head = {
        cells: [
            { key: "id", content: "ID", isSortable: false },
            { key: "label", content: t("admin_space_column_label"), isSortable: false },
            { key: "name", content: t("admin_space_column_name"), isSortable: false },
            { key: "type", content: t("admin_space_column_type"), isSortable: false },
            { key: "position", content: "LP.", isSortable: false },
            { key: "required", content: t("admin_space_column_required"), isSortable: false },
            { key: "hidden", content: t("admin_space_column_hidden"), isSortable: false },
            { key: "quick_form", content: t("admin_space_column_quick_form"), isSortable: false },
            { key: "read_only", content: t("admin_space_column_read_only"), isSortable: false },
            { key: "must_have", content: t("admin_space_column_must_have"), isSortable: false },
            { key: "workflow", content: t("admin_space_column_workflow"), isSortable: false },
            { key: "visibleRoles", content: t("admin_space_column_visible_roles"), isSortable: false },
            { key: "editableRoles", content: t("admin_space_column_editable_roles"), isSortable: false },
            { key: "actions", content: null }
        ]
    };

    const relationsHead = {
        cells: [
            { key: "id", content: "ID", isSortable: false },
            { key: "module", content: t("admin_space_column_module"), isSortable: false },
            { key: "label", content: t("admin_space_column_document"), isSortable: false },
            { key: "actions", content: null }
        ]
    };

    const pdfsHead = {
        cells: [
            { key: "name", content: t("admin_space_column_name"), isSortable: false },
            { key: "roles", content: t("admin_space_column_roles"), isSortable: false },
            { key: "status", content: t("admin_space_column_status"), isSortable: false },
            { key: "actions", content: null }
        ]
    };

    const rows = docType?.fields?.sort((a, b) => a.position - b.position)?.map(f => ({
        key: f.name,
        cells: [
            { key: "id", content: f?.field?.type === "section" || f.field?.type === "group" ? <strong>{f.id}</strong> : <span>{f.id}</span> },
            { key: "label", content: f?.field?.type === "section" || f.field?.type === "group" ? <strong>{f.label}</strong> : <span>{f.label}</span> },
            { key: "name", content: f?.field?.type === "section" || f.field?.type === "group" ? <strong>{f.name}</strong> : <span>{f.name} {f?.group && <strong>({f?.group}:{f?.group_lp}:{f?.group_flex})</strong>}</span> },
            { key: "type", content: <span>{f?.field?.type}: {f?.field?.id}</span> },
            { key: "position", content: <span>{f?.position}</span> },
            { key: "required", content: <Checkbox isChecked={toBoolean(f.required)} /> },
            { key: "hidden", content: <Checkbox isChecked={toBoolean(f.hidden)} /> },
            { key: "quick_form", content: <Checkbox isChecked={toBoolean(f.quick_form)} /> },
            { key: "read_only", content: <Checkbox isChecked={toBoolean(f.read_only)} /> },
            { key: "must_have", content: <Checkbox isChecked={toBoolean(f.must_have)} /> },
            { key: "workflow", content: <Checkbox isChecked={toBoolean(f.workflow)} /> },
            {
                key: "visibleRoles", content: <div>
                    {roles?.filter(r => f?.visible_roles?.includes(r?.id))?.map(r => r?.name)?.join(", ")}
                </div>
            },
            {
                key: "editableRoles", content: <div>
                    {roles?.filter(r => f?.editable_roles?.includes(r?.id))?.map(r => r?.name)?.join(", ")}</div>
            },
            {
                key: "actions",
                content: <DropdownMenu position="bottom right" triggerType="button">
                    <DropdownItemGroup>
                        <DropdownItem onClick={() => setFieldToEdit(f)}>
                            {t("admin_space_edit")}
                        </DropdownItem>
                        <DropdownItem onClick={() => setFieldToRemove(f)}>
                            {t("admin_space_remove")}
                        </DropdownItem>
                    </DropdownItemGroup>
                </DropdownMenu>
            }
        ],
    })) || [];

    const relationsRows = documentType?.links?.sort((a, b) => a?.position - b?.position)?.map(l => {
        const documentType = documentTypesMap ? documentTypesMap[l?.can_link_to_document_type_id] : null;
        return ({
            key: l.id,
            cells: [
                { key: "id", content: <span>{documentType?.id}</span> },
                {
                    key: "module", content: <Link to={`/admin/modules/${documentType?.category?.module?.name}/${documentType?.category?.slug}/${documentType?.space?.slug}`}>
                        {documentType?.category?.module?.alias}/{documentType?.category?.name}/{documentType?.space?.name}
                    </Link>
                },
                { key: "label", content: <span>{documentType?.name}</span> },
                {
                    key: "actions",
                    content: <DropdownMenu position="bottom right" triggerType="button">
                        <DropdownItemGroup>
                            <DropdownItem onClick={() => setItemToEdit(l)}>
                                {t("edit")}
                            </DropdownItem>
                            <DropdownItem onClick={() => setRelationToRemove(l)}>
                                {t("admin_space_remove")}
                            </DropdownItem>
                        </DropdownItemGroup>
                    </DropdownMenu>
                }
            ],
        });
    }) || [];

    const pdfsRows = pdfsData?.map(p => {
        return ({
            key: p.id,
            cells: [
                { key: "name", content: <span>{p?.name}</span> },
                { key: "roles", content: <span>{p?.attached_roles?.map(i => i?.name)?.join(", ")}</span> },
                { key: "status", content: <span>{p?.status ? t("admin_space_production") : t("admin_space_working")}</span> },
                {
                    key: "actions",
                    content: <DropdownMenu position="bottom right" triggerType="button">
                        <DropdownItemGroup>
                            <DropdownItem onClick={() => setConfigToEdit(p)}>
                                {t("admin_space_edit")}
                            </DropdownItem>
                            <DropdownItem onClick={() => setConfigToRemove(p)}>
                                {t("admin_space_remove")}
                            </DropdownItem>
                        </DropdownItemGroup>
                    </DropdownMenu>
                }
            ],
        });
    }) || [];

    const workflowsHead = {
        cells: [
            { key: "id", content: "ID", isSortable: false },
            { key: "name", content: t("admin_space_column_name"), isSortable: false },
            { key: "worker", content: t("admin_space_worker"), isSortable: false },
        ]
    };

    const workflowsRows = documentTypeWorkflows?.map(w => {
        return ({
            key: w.id,
            cells: [
                { key: "id", content: <span>{w?.id}</span> },
                { key: "name", content: <span>{w?.name}</span> },
                { key: "worker", content: <span>{w?.worker}</span> },
                {
                    key: "actions",
                    content: <DropdownMenu position="bottom right" triggerType="button">
                        <DropdownItemGroup>
                            <DropdownItem onClick={() => setWorkflowToEdit(w)}>
                                {t("admin_space_edit")}
                            </DropdownItem>
                            <DropdownItem onClick={() => setWorkflowToRemove(w)}>
                                {t("admin_space_remove")}
                            </DropdownItem>
                        </DropdownItemGroup>
                    </DropdownMenu>
                }
            ],
        });
    }) || [];

    const actionsContent = <ButtonGroup>
        {documentType && <Button onClick={() => setExportOpen(true)}>
            {t("admin_space_export")}
        </Button>}
        {documentType && <Button onClick={() => setImportOpen(true)}>
            {t("admin_space_import")}
        </Button>}
        {documentType && <Button onClick={() => setDocumentTypeToEdit(documentType)}>
            {t("admin_space_edit_type")}
        </Button>}
        {documentType && <Button onClick={() => setItemToRemove(documentType)}>
            {t("admin_space_remove_type")}
        </Button>}
        <Button appearance="primary" onClick={() => setAddOpen(true)}>
            {t("admin_space_new_field")}
        </Button>
    </ButtonGroup>;

    const relationsActionsContent = <ButtonGroup>
        <Button appearance="primary" onClick={() => setAddRelationOpen(true)}>
            {t("admin_space_new_link")}
        </Button>
    </ButtonGroup>;

    const pdfsActionsContent = <ButtonGroup>
        <Button appearance="primary" onClick={() => setAddConfigOpen(true)}>
            {t("admin_space_new_config")}
        </Button>
    </ButtonGroup>;

    const workflowsActionsContent = <ButtonGroup>
        <Button appearance="primary" onClick={() => setAddWorkflowOpen(true)}>
            {t("admin_space_add_workflow")}
        </Button>
    </ButtonGroup>;

    return <AdminLayout>
        <PageHeader actions={actionsContent} bottomBar={<div>
            {t("admin_space_document_type_id")}: <strong>{space?.document_type?.id}</strong>
        </div>}>{space?.name}</PageHeader>
        <Wrapper>
            <Left>
                <DynamicTable
                    head={head}
                    rows={rows}
                    loadingSpinnerSize="large"
                    isLoading={isPending}
                    isRankable
                    isRankingDisabled={reorder.isPending}
                    onRankEnd={e => {
                        const allNames = docType?.fields
                            ?.sort((a, b) => a.position - b.position)
                            ?.filter(i => i?.name != e.sourceKey)
                            ?.map(i => i?.name);
                        allNames.splice(e.destination.index, 0, e.sourceKey);

                        setDocType(dt => ({
                            ...dt,
                            fields: dt?.fields?.map(f => ({
                                ...f,
                                position: allNames.indexOf(f?.name),
                            })),
                        }));

                        reorder.run(apiClient(`document-type/${documentType?.id}/sort`, {
                            data: {
                                fields: allNames,
                            }
                        }))
                            .then(res => {
                                setDocType(res?.data);
                                setMaxPos(0);
                                res?.data
                                    ?.fields
                                    ?.sort((a, b) => a.position - b.position)
                                    ?.map(f => {
                                        const position = parseInt(f?.position);
                                        if (position > maxPos) {
                                            setMaxPos(position);
                                        }
                                    });
                            })
                            .catch(() => {
                                toast.error("Could not reorder fields");
                            });
                    }}
                />
            </Left>
        </Wrapper>
        <PageHeader actions={relationsActionsContent}>
            {t("admin_space_links")}
        </PageHeader>
        <Wrapper>
            <Left>
                <DynamicTable
                    head={relationsHead}
                    rows={relationsRows}
                    loadingSpinnerSize="large"
                    isLoading={isPending}
                />
            </Left>
        </Wrapper>
        <PageHeader actions={pdfsActionsContent}>
            {t("admin_space_pdf_configs")}
        </PageHeader>
        <Wrapper>
            <Left>
                <DynamicTable
                    head={pdfsHead}
                    rows={pdfsRows}
                    loadingSpinnerSize="large"
                    isLoading={isPending}
                />
            </Left>
        </Wrapper>
        <PageHeader actions={workflowsActionsContent}>
            {t("admin_space_workflows")}
        </PageHeader>
        <Wrapper>
            <Left>
                <DynamicTable
                    head={workflowsHead}
                    rows={workflowsRows}
                    loadingSpinnerSize="large"
                    isLoading={isPendingDocumentTypeWorkflows}
                />
            </Left>
        </Wrapper>
        {addOpen && <CreateField open={addOpen} onClose={() => setAddOpen(false)} fields={fields} documentTypeId={documentType?.id} onAdded={() => reload()} pos={maxPos + 1} allFields={docType?.fields?.sort((a, b) => b?.position - a?.position)} />}
        {addRelationOpen && <CreateRelation open={addRelationOpen} onClose={() => setAddRelationOpen(false)} documentTypeId={documentType?.id} onAdded={() => reload()} />}
        {fieldToEdit && <EditField open={!!fieldToEdit} field={fieldToEdit} fields={fields} documentTypeId={documentType?.id} onClose={() => setFieldToEdit(null)} onAdded={() => reload()} />}
        {fieldToRemove && <RemoveField field={fieldToRemove} onRemoved={() => {
            setFieldToRemove(null);
            reload();
        }} onClose={() => setFieldToRemove(null)} />}
        {relationToRemove && <RemoveRelation relation={relationToRemove} onRemoved={() => {
            setRelationToRemove(null);
            reload();
        }} onClose={() => setRelationToRemove(null)} />}
        {itemToRemove && <RemoveDocumentType documentType={itemToRemove} onRemoved={() => {
            setItemToRemove(null);
            reloadSpace();
        }} onClose={() => setItemToRemove(null)} />}
        {itemToEdit && <EditDocumentTypeLink id={itemToEdit?.id} attributes={itemToEdit?.attributes} position={itemToEdit?.position} onSuccess={() => {
            setItemToEdit(null);
            reload();
        }} onClose={() => setItemToEdit(null)} />}
        {importOpen && <ImportModal documentTypeId={documentType?.id} onSuccess={() => setImportOpen(false)} onClose={() => setImportOpen(false)} />}
        {exportOpen && <ExportModal slug={slug} onClose={() => setExportOpen(false)} />}
        {configOpen && <ConfigModal documentTypeId={documentType?.id} onSuccess={() => setConfigOpen(false)} onClose={() => setConfigOpen(false)} />}
        {addConfigOpen && <CreatePdfConfig documentTypeId={documentType?.id} onSuccess={() => {
            setAddConfigOpen(false);
            reload();
        }} onClose={() => setAddConfigOpen(false)} />}
        {configToEdit && <EditPdfConfig pdf={configToEdit} documentTypeId={documentType?.id} onSuccess={() => {
            setConfigToEdit(false);
            reload();
        }} onClose={() => setConfigToEdit(false)} />}
        {configToRemove && <RemovePdfConfig pdf={configToRemove} onRemoved={() => {
            setConfigToRemove(false);
            reload();
        }} onClose={() => setConfigToRemove(false)} />}
        {documentTypeToEdit && <EditDocumentType documentType={documentTypeToEdit} onSuccess={() => {
            setDocumentTypeToEdit(false);
            reload();
        }} onClose={() => setDocumentTypeToEdit(false)} />}
        {workflowToRemove && <RemoveWorkflowFromDocumentType
            documentTypeId={space?.document_type?.id}
            workflow={workflowToRemove} onRemoved={() => {
                setWorkflowToRemove(false);
                reloadWorkflows();
            }} onClose={() => setWorkflowToRemove(false)} />}
        {addWorkflowOpen && <AttachWorkflowToDocumentType
            documentTypeId={space?.document_type?.id}
            onClose={() => setAddWorkflowOpen(false)}
            onSuccess={() => {
                setAddWorkflowOpen(false);
                reloadWorkflows();
            }} />}
        {workflowToEdit && <EditDocumentTypeWorkflow workflow={workflowToEdit} documentTypeId={documentType?.id} onSuccess={() => {
            setWorkflowToEdit(false);
            reloadWorkflows();
        }} onClose={() => setWorkflowToEdit(false)} />}
        {reorder.isPending && <AbsoluteSpinner />}
    </AdminLayout>;
}

export default AdminSpace;

const Wrapper = styled.div`
    display: flex;
`;

const Left = styled.div`
    width: 100%;
`;