import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/LogoErp.svg";
import { ReactComponent as LogoVseli } from "../assets/LogoVseli.svg";
import LogoUwr from "../assets/LogoUwr.png";

const domain = window.location.hostname;
const uwrDomains = ["uwr.app.erpspace.pl", "simulation.akta.american-systems.pl"];
let href = domain == "app.vseli.com" || domain == "staging.vseli.com" ? "https://vseli.com" : "https://erpspace.io";
if (uwrDomains.includes(domain)) {
    href = "https://uwr.edu.pl/";
}

export default function AuthLayout({ children }) {
    return <>
        <LogoWrapper>
            <a href={href}>
                {
                    (domain == "app.vseli.com" || domain == "staging.vseli.com")
                        ? <LogoVseli width="80px" height="30px" style={{
                            paddingTop: "10px",
                        }} />
                        : 
                        uwrDomains.includes(domain)
                        ?
                            <img src={LogoUwr} width="30px" height="30px" style={{
                                paddingTop: "10px",
                            }} />
                        : <Logo />
                }
            </a>
        </LogoWrapper>
        <Wrapper>
            {children}
        </Wrapper>
    </>;
}

const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LogoWrapper = styled.div`
padding: 20px;
position: absolute;
left: 0;
top: 0;
`;