import React from "react";
import styled from "styled-components";

function IconWidget({ name, size }) {
    return <span className="material-icons" style={{ fontSize: size ?? "inherit"}}>{name}</span>;
}

export default IconWidget;

const Wrapper = styled.div`
`;