import React from "react";
import NewPasswordForm from "../../components/auth/new-password-form";
import AuthLayout from "../../layouts/auth-layout";

function Password() {
    return <AuthLayout>
        <NewPasswordForm />
    </AuthLayout>;
}

export default Password;