import React from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import useAsync from "../../hooks/use-async";
import { useTranslation } from "react-i18next";

export default function RemoveRole({ onSubmit, onClose, isPending }) {
    const { t } = useTranslation();
    
    return (
        <>
            <ModalTransition>
                <Modal
                    isLoading={isPending}
                    actions={[
                        { text: t("admin_remove_role_remove"), onClick: onSubmit },
                        { text: t("admin_remove_role_cancel"), onClick: onClose },
                    ]}
                    onClose={close}
                    heading={t("admin_remove_role_heading")}
                    appearance="danger"
                >
                    <p>
                        {t("admin_remove_role_p")}
                    </p>
                </Modal>
            </ModalTransition>
        </>
    );
}