import { useEffect, useState, useCallback } from "react";
import apiClient from "../api/api-client";
import documentsListResponse from "../api/documents-list-response";
import documentFieldsResponse from "../api/document-fields-response";
import useAsync from "../hooks/use-async";
import { getMonth, getQuarter, getYear } from "date-fns";

export default function useDocumentsPivotList(documentTypeId, fieldNames = [], options = {
    numericFieldTypeId: 2,
    dateFieldTypeId: 4,
    selectFieldTypeId: 3,
}, space) {
    const { numericFieldTypeId, dateFieldTypeId, selectFieldTypeId } = options;
    const fields = useAsync();
    const list = useAsync();
    const [pivotDocuments, setPivotDocuments] = useState([]);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!documentTypeId) return;
        fields.run(apiClient(`document-types/${documentTypeId}`)).catch(() => { });
    }, [documentTypeId]);

    const loadPivot = async () => {
        let page = 1;
        let loaded = false;
        let docs = [];
        let perPage = 1000;

        while (!loaded) {
            try {
                const response = await apiClient(`workflow/document-type/${documentTypeId}/analitycs?paginate=true&page=${page}&per_page=${perPage}&loadLinks=true`, { method: "POST" });
                loaded = !response?.links?.next;
                try {
                    setProgress(response?.meta?.current_page / response?.meta?.last_page);
                } catch (err) {
                    setProgress(0);
                }
                // const newDocs = response?.data?.map(document => {
                //     const documentValuesMap = document?.values?.reduce((acc, i) => {
                //         if (!fieldNames?.includes(i?.name)) return acc;

                //         const field = fields?.data?.find(f => f?.name === i?.name);
                //         let value = i.value;

                //         if (field?.field_id === numericFieldTypeId) {
                //             value = Number(value)?.toFixed(2)?.replace(".", ",");
                //         }

                //         if (field?.field_id === dateFieldTypeId) {
                //             try {
                //                 const date = Date.parse(value);
                //                 const year = getYear(date);
                //                 const month = getMonth(date) + 1;
                //                 const quarter = getQuarter(date);
                //                 acc[`${i.label} (Rok)`] = year;
                //                 acc[`${i.label}  (Miesiąc)`] = month;
                //                 acc[`${i.label}  (Kwartał)`] = `Q${quarter} ${year}`;
                //             } catch (e) {
                //                 acc[`${i.label}  (Rok)`] = null;
                //                 acc[`${i.label}  (Miesiąc)`] = null;
                //                 acc[`${i.label}  (Kwartał)`] = null;
                //             }
                //         }

                //         if (field?.field_id === selectFieldTypeId) {
                //             const currentOption = field?.options?.find(opt => opt?.value === value);

                //             if (currentOption?.name) {
                //                 value = currentOption?.name;
                //             }

                //             field?.options?.map(o => {
                //                 const name = document?.values?.find(dv => dv?.name === "name")?.value;
                //                 if (name) {
                //                     acc[`${i.label}: ${field?.label} - ${o?.name}`] = name;
                //                 }
                //             });
                //         }

                //         if (field?.field_id === 13) {
                //             //document type
                //             const doc = response?.related?.find(i => parseInt(i?.id) === parseInt(value));
                //             if (doc) {
                //                 value = doc?.name;
                //             }
                //         }

                //         // if (field?.field_id === 18) {
                //         //     //document type multi
                //         //     console.log("DOCUMENT TYPE MULTI", {
                //         //         field,
                //         //         i,
                //         //         data: response,
                //         //     });
                //         // }

                //         acc[i.label] = value;
                //         return acc;
                //     }, {});

                //     let acc = {
                //         ...documentValuesMap,
                //     };

                //     document?.links?.forEach(link => {
                //         const documentTypeName = link?.document_type?.name;
                //         const documentTypeLabel = link?.document_type?.name;
                //         if (!link) return;
                //         if (!link?.values) return;
                //         if (!Array.isArray(link?.values)) return;

                //         link?.values?.forEach(v => {
                //             if (!fieldNames?.includes(`${documentTypeName}:${v?.name}`)) return;
                //             const fieldLabel = v?.label;
                //             let value = v?.value;
                //             if (v?.field_id === numericFieldTypeId) {
                //                 acc[`${documentTypeLabel}: ${fieldLabel}`] = Number(value)?.toFixed(2)?.replace(".", ",");
                //                 return;
                //             }

                //             if (v?.field_id === dateFieldTypeId) {
                //                 try {
                //                     const date = Date.parse(value);
                //                     const year = getYear(date);
                //                     const month = getMonth(date) + 1;
                //                     const quarter = getQuarter(date);
                //                     acc[`${documentTypeLabel}: ${fieldLabel} (Rok)`] = year;
                //                     acc[`${documentTypeLabel}: ${fieldLabel} (Miesiąc)`] = month;
                //                     acc[`${documentTypeLabel}: ${fieldLabel} (Kwartał)`] = `Q${quarter} ${year}`;
                //                 } catch (e) {
                //                     acc[`${documentTypeLabel}: ${fieldLabel} (Rok)`] = null;
                //                     acc[`${documentTypeLabel}: ${fieldLabel} (Miesiąc)`] = null;
                //                     acc[`${documentTypeLabel}: ${fieldLabel} (Kwartał)`] = null;
                //                 }
                //             }

                //             if (v?.field_id === selectFieldTypeId) {
                //                 const currentOption = v?.options?.find(opt => opt?.value === value);

                //                 if (currentOption?.name) {
                //                     value = currentOption?.name;
                //                 }

                //                 v?.options?.map(o => {
                //                     const name = link?.values?.find(i => i?.name === "name")?.value;
                //                     if (name) {
                //                         acc[`${documentTypeLabel}: ${fieldLabel} - ${o?.name}`] = name;
                //                     }
                //                 });

                //                 // return;
                //             }

                //             acc[`${documentTypeLabel}: ${fieldLabel}`] = value;
                //         });
                //     });

                //     return acc;
                // });

                // docs = [
                //     ...docs,
                //     ...newDocs,
                // ];

                const newDocs = (response?.data ?? [])?.map(doc => {
                    return doc?.reduce((acc, i) => {
                        switch (i?.type) {
                            case "details":
                                if (!fieldNames?.includes(i?.slug)) {
                                    return acc;
                                }
                                break;

                            case "related":
                                if (!fieldNames?.includes(`${i?.documentTypeName}:${i?.slug}`)) {
                                    return acc;
                                }
                                break;
                            // break;

                            case "linked":
                                if (!fieldNames?.includes(`${i?.documentTypeName}:name`)) {
                                    return acc;
                                }
                                break;

                            case "multi":
                                if (!fieldNames?.includes(`${i?.documentTypeName}:multi`)) {
                                    return acc;
                                }
                                break;

                            case "date":
                                if (!fieldNames?.includes(i?.slug)) {
                                    return acc;
                                }
                                break;

                            case "related_date":
                                if (!fieldNames?.includes(`${i?.documentTypeName}:${i?.slug}`)) {
                                    return acc;
                                }
                                break;

                            case "linked_value":
                                if (!fieldNames?.includes(`${i?.documentTypeName}:${i?.slug}`)) {
                                    return acc;
                                }
                                break;

                        }

                        acc[i?.label] = i?.value ?? null;
                        return acc;
                    }, {});
                });

                docs = [
                    ...docs,
                    ...(newDocs),
                ];

                page++;
            } catch (err) {
                if (perPage == 1000) {
                    perPage = 100;
                    loaded = false;
                    docs = [];
                    page = 1;
                } else if (perPage == 100) {
                    perPage = 10;
                    loaded = false;
                    docs = [];
                    page = 1;
                } else {
                    loaded = true;
                }
            }
        }

        return docs;
    };

    useEffect(() => {
        if (!documentTypeId) return;
        if (fields?.isPending || fields?.isIdle) return;

        setProgress(0);

        list.run(
            new Promise((resolve, reject) => {
                loadPivot().then(data => {
                    resolve(data);
                });
            })
        )
            .catch(() => { });


    }, [documentTypeId, fields?.isPending, fields?.isIdle]);

    const keys = new Set();
    const data = list?.data ?? [];

    (list?.data ?? [])?.map(item => {
        for (const key in item) {
            keys.add(key);
        }
    });

    return {
        documents: data?.map(d => {
            for (const key of keys) {
                if (!(key in d) || !d[key]) {
                    d[key] = "-";
                }
            }
            return d;
        }) ?? null,
        pivotDocuments,
        fields: fields.data ? documentFieldsResponse(fields.data) : [],
        isPending: fields.isIdle || fields.isPending || list.isIdle || list.isPending,
        isError: list.isError || fields.isError,
        isSuccess: list.isSuccess && fields.isSuccess,
        progress,
    };
}