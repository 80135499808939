import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import FullPageSpinner from "../components/ui/full-page-spinner";
import useDocument from "../hooks/use-document";

export default function RedirectDocument() {
    const { id } = useParams();
    const { document } = useDocument(id, null);

    // Todo - na razie niepotrzebny komponent, brak info o kategorii, module i space

    return <FullPageSpinner />;
}