import React from "react";
import styled from "styled-components";
import { default as AtlassianSpinner } from "@atlaskit/spinner";

function Spinner() {
    return <Wrapper data-testid="spinner">
        <AtlassianSpinner size="medium" />
    </Wrapper>;
}

export default Spinner;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;