import { useCallback, useEffect } from "react";
import apiClient from "../api/admin-api-client";
import useAsync from "../hooks/use-async";

export default function useAdminPermissions() {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        reload();
    }, []);

    const reload = useCallback(() => run(apiClient("permissions")).catch(() => { }));

    return {
        permissions: permissionsResponse(data),
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        reload,
    };
}

function permissionsResponse(data) {
    if (!data) return {};
    
    return data?.data?.reduce((acc, item) => {
        acc[item?.name] = item?.id;
        return acc;
    }, {});
}