import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Button from "@atlaskit/button";
import { Checkbox } from "@atlaskit/checkbox";
import TextField from "@atlaskit/textfield";
import { Field } from "@atlaskit/form";
import { Radio } from "@atlaskit/radio";
import Lozenge from "@atlaskit/lozenge";
import useOrder from "../../hooks/use-order";
import Spinner from "../../components/ui/spinner";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function PaymentStatus() {
    const query = useQuery();
    const orderID = query.get("OrderID");
    const hash = query.get("Hash");

    const { order, isPending, pay } = useOrder(orderID);
    const delivery = order?.payment?.deliverer;
    let total = parseFloat(order?.package?.orders?.reduce((acc, order) => {
        if (order?.status !== "W toku" && order?.status != null && order?.status !== "") return acc;

        return acc + order?.positions?.reduce((acc, pos) => {
            const price = parseFloat(pos.sale) > 0 ? parseFloat(pos.sale) : parseFloat(pos.price);
            const count = parseFloat(pos?.count ?? 1);

            return acc + count * (price ?? 0);
        }, 0) ?? 0;
    }, 0));

    const deliveryPaid = order?.package?.orders?.map(o => parseFloat(o?.payment?.delivery_paid ?? 0))?.reduce((acc, i) => {
        acc = acc + i;
        return acc;
    }, 0);

    return <>
        <Helmet>
            <title>{order?.name}</title>
        </Helmet>
        <Wrapper>
            <Left>
                <DFlex>
                    <DMain>
                        <Header>Zamówienie nr {order?.name}</Header>
                    </DMain>
                    {order?.organization && <Organization>
                        <OrganizationName>{order?.organization?.name}</OrganizationName>
                        <OrganizationAddress>{order?.organization?.address}</OrganizationAddress>
                    </Organization>}
                </DFlex>
                <Summary>
                    <h3 style={{ marginBottom: "25px" }}>Podsumowanie</h3>

                    {order?.package?.orders?.map((order, i) => (
                        <>
                            <Flex>
                                <Main>
                                    <h4>Zamówienie #{i + 1} {order?.created_at && <DateSmall>({format(new Date(order?.created_at ?? new Date()), "dd.MM.Y")})</DateSmall>}</h4>
                                </Main>
                                <div>
                                    {(order?.status == "W toku" || order?.status == null || order?.status === "") ? <Lozenge appearance="inprogress">W toku</Lozenge> : null}
                                    {order?.status == "Anulowane" ? <Lozenge appearance="removed">Anulowane</Lozenge> : null}
                                    {(order?.status != "W toku" && order?.status != null && order?.status !== "") && order?.status != "Anulowane" ? <Lozenge appearance="success">Zapłacone</Lozenge> : null}
                                </div>
                            </Flex>
                            <SummaryList style={{ opacity: "0.5" }}>
                                {order?.positions?.map(position => (
                                    <FlexListItem key={position?.name}>
                                        <Main>{position?.count} x {position?.name}</Main>
                                        <RightAlign>{parseFloat(position.sale) > 0 ? parseFloat(parseFloat(position?.count) * parseFloat(position.sale)) : parseFloat(parseFloat(position?.count) * parseFloat(position.price))}zł</RightAlign>
                                    </FlexListItem>
                                ))}
                            </SummaryList>
                        </>
                    ))}
                    {order?.package?.delivery_paid && <SummaryList>
                        <FlexListItem>
                            <Main>Koszty wysyłki</Main>
                            <RightAlign>{`${parseFloat(order?.package?.delivery_paid).toFixed(2)}zł`}</RightAlign>
                        </FlexListItem>
                    </SummaryList>}
                    <SummaryPrice>
                        <Flex>
                            <Main>
                                <strong>Razem</strong>
                            </Main>
                            <div>
                                <SummaryPriceHighlight>
                                    <span>{!isNaN(total) ? parseFloat(total).toFixed(2) : "-"}</span> zł
                                </SummaryPriceHighlight>
                            </div>
                        </Flex>
                    </SummaryPrice>
                </Summary>

                <Section>
                    <h3>Dziękujemy za zakupy! ❤️</h3>
                    <p>O każdym kroku związanym z twoją przesyłką poinformujemy Cię w wiadomości na messenger lub e mail</p>
                </Section>

                <Section>
                    {deliveryPaid > 0 && <OpenPackageSection>
                        Zapłacono już za dostawę: <strong>{parseFloat(deliveryPaid).toFixed(2)}zł</strong>
                    </OpenPackageSection>}
                </Section>
            </Left>
        </Wrapper>
    </>;
}

const Wrapper = styled.div`
padding: 20px;
@media (min-width: 600px) {
    padding: 40px;
    display: flex;
    max-width: 600px;
    margin: 0px auto;
}
`;

const DFlex = styled.div`
    display: flex;
    align-items: center;
`;

const DMain = styled.div`
    flex-grow: 1;
`;

const Left = styled.div`
flex: 3;
`;

const Right = styled.div`
flex: 2;

margin-top: 40px;
@media (min-width: 900px) {
    padding: 20px;
    padding-left: 100px;
}
`;

const Header = styled.h1`
font-size: 25px;
margin: 0;
padding: 0;
`;


const Section = styled.div`
    padding: 30px 0;
`;

const SectionHeader = styled.h3`
font-size: 16px;
margin-bottom: 10px;
`;

const Summary = styled.div`
    background: #F5F5F7;
    padding: 25px;
    border-radius: 5px;
    margin-top: 20px;
`;

const SummaryList = styled.div`
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
`;

const SummaryPrice = styled.div`

`;

const Flex = styled.div`
    display: flex;
    align-items: flex-end;
`;

const FlexListItem = styled.div`
display: flex;
align-items: flex-end;
margin: 10px 0;
`;

const FlexLg = styled.div`
@media (min-width: 900px) {
    display: flex;
    align-items: flex-end;
}
`;

const Main = styled.div`
    flex: 1;
`;

const SummaryPriceHighlight = styled.h2`
`;

const OpenPackageSection = styled.div`
    background: #F5F5F7;
    padding: 15px;
    border-radius: 5px;
    margin: 15px 0 0;
`;

const Block15Lg = styled.div`
@media (min-width: 900px) {
    width: 15px;
    height: 100%;
}
`;

const RightAlign = styled.div`
    text-align: right;
`;

const Organization = styled.div`
    opacity: 0.7;
    text-align: right;
`;
const OrganizationName = styled.div`
font-weight: 600;
font-size: 0.9rem;
`;
const OrganizationAddress = styled.div`
    font-size: 0.8rem;
    white-space: pre-wrap;
`;

const DateSmall = styled.div`
    font-size: 0.8rem;
    opacity: 0.5;
    display: inline-block;
    height: 10px;
`;