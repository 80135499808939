import React, { useEffect, useState } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import styled from "styled-components";
import FormField from "./form-field";
import EmptyState from "@atlaskit/empty-state";
import Button from "@atlaskit/button";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import FormTableNewRecord from "./form-table-new-record";
import { v4 as uuidv4 } from "uuid";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import FormTableEditRecord from "./form-table-edit-record";

function FormTable({ table_column, columns_values = [], label, onChange, editable = true }) {
    const [ addFormOpen, setAddFormOpen ] = useState(false);
    const [ recordToEdit, setRecordToEdit ] = useState(null);
    const [ data, setData ] = useState(() => reduceColumnsValues(columns_values, table_column));

    useEffect(() => {
        onChange && onChange(data.map((d, i) => ({
            ...d.data,
            row: i,
        })));
    }, [ data ]);

    const head = {
        cells: [
            ...table_column.map(column => ({
                key: column.name, content: column.label,
            })),
            { key: "actions", content: null }
        ]
    };

    const fields = table_column.reduce((acc, c) => {
        acc[c.name] = c;
        return acc;
    }, {});

    const rows = data.map(row => {
        return {
            key: row.id,
            cells: [
                ...table_column.map(i => i.name).map(key => {
                    return { key, content: cellContent(fields[key], row.data[key]) };
                }),
                {
                    key: "actions",
                    content: editable && <DropdownMenu  position="bottom right" triggerType="button">
                        <DropdownItemGroup>
                            <DropdownItem onClick={() => {
                                setRecordToEdit(row);
                            }}>
                        Edytuj
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                setData(d => d.filter(i => i.id !== row.id));
                            }}>
                        Usuń
                            </DropdownItem>
                        </DropdownItemGroup>
                    </DropdownMenu>
                }
            ]
        };
    });

    return <Wrapper>
        <Heading>
            <h4>{label}</h4>
            { editable && <Button iconBefore={<EditorAddIcon />} onClick={() => setAddFormOpen(true)}></Button>}
        </Heading>
        <DynamicTable
            head={head} 
            rows={rows}
            loadingSpinnerSize="large"
            emptyView={<div>Brak rekordów.</div>}
        />
        {data?.length === 0 && editable && <EmptyState header="" description="" primaryAction={<Button appearance="primary" onClick={() => setAddFormOpen(true)}>Dodaj</Button>} />}
        {addFormOpen && editable && <FormTableNewRecord open={addFormOpen} onClose={() => setAddFormOpen(false)} fields={table_column} onSubmit={(formData) => {
            setAddFormOpen(false);
            setData(d => ([
                ...d,
                { id: uuidv4(), data: formData }
            ]));
        }} />}
        {recordToEdit && editable && <FormTableEditRecord record={recordToEdit} open={!!recordToEdit} onClose={() => setRecordToEdit(null)} fields={table_column} onSubmit={(formData) => {
            setRecordToEdit(null);
            setData(data => {
                return data.map(d => {
                    if (d.id === formData.id) {
                        return { ...d, data: formData };
                    }
                    return d;
                });
            });
        }} />}
    </Wrapper>;
}

export default FormTable;

const Wrapper = styled.div`
margin: 20px 0 0;
margin-top: 30px;
padding: 20px 25px 20px;
background: rgba(0,0,0,0.03);
border-radius: 5px;
`;

const Heading = styled.div`
    display: flex;

    &>h4 {
        flex-grow: 1;
        margin-bottom: 10px;
    }
`;

function cellContent(field, value) {
    if (field.field.type === "checkbox") return value ? "tak" : "nie";
    if (field.field.type === "select") {
        return field.options
            .map(i => ({ label: i.name, value: i.value }))
            .find(i => i.value == value)
            ?.label;
    }
    return value || "-";
}

function reduceColumnsValues(values, columns) {
    return values.reduce((acc, item) => {
        if (!acc[item.row]) {
            acc[item.row] = {
                row: item.row,
                id: uuidv4(),
                data: {},
            };
        }

        const column = columns.find(i => i.id === item.table_column_id);
        acc[item.row].data[column.name] = item.value;

        return acc;
    }, []);
}