import useAsync from "./use-async";
import apiClient from "../api/api-client";
import { useEffect, useState } from "react";

export default function useDocumentHistory(documentId, type, onReloaded, page = 1) {
    const { run, data, isPending, isSuccess } = useAsync();
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        if (!documentId) return;
        reload().then(() => setLoaded(true));
    }, [ documentId, type, page ]);

    const reload = () => {
        return run(apiClient(`document/${documentId}/events/type/${type}?page=${page}`))
            .then(() => {
                if (loaded) onReloaded();
            })
            .catch(() => { });
    };

    return {
        isLoading: isPending,
        data,
        reload,
        isSuccess,
        pagination: {
            total: data?.total,
            per_page: data?.per_page,
        },
    };
}