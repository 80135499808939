import { useCallback, useEffect } from "react";
import apiClient from "../api/admin-api-client";
import useAsync from "../hooks/use-async";

export default function useAdminSpaces(module, category) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        if (!module || ! category) return;
        reload();
    }, [ module, category ]);

    const reload = useCallback(() => run(apiClient(`category/${category.toLowerCase()}/spaces`)).catch(() => { }));

    return {
        spaces: data?.data || [],
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        reload,
    };
}