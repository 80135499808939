import React from "react";
import ResetPasswordForm from "../../components/auth/reset-password-form";
import AuthLayout from "../../layouts/auth-layout";

function Reset() {
    return <AuthLayout>
        <ResetPasswordForm />
    </AuthLayout>;
}

export default Reset;