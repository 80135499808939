import React from "react";
import styled from "styled-components";
import Navbar from "../components/nav/navbar";
import { useAuth } from "../providers/auth-provider";

export default function UserLayout({ children }) {
    const { user, logout, searchEnabled } = useAuth();

    return <Wrapper>
        <Navbar user={user} logout={logout} searchEnabled={searchEnabled} />
        <Content>
            {children}
        </Content>
    </Wrapper >;
}

const Wrapper = styled.div`
`;

const Content = styled.div`
    background: white;
    padding: 40px;
    padding-top: 96px;
`;