import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import "@atlaskit/css-reset";
import "./app.css";
import AuthProvider from "./providers/auth-provider";

ReactDOM.render(
    <AuthProvider>
        <App />
    </AuthProvider>,
    document.getElementById("root")
);