/* eslint-disable react/display-name */
import React from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import Form from "@atlaskit/form";
import prepareFormData from "../../utils/prepare-form-data";
import styled from "styled-components";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import FormField from "./form-field";

export default function FormTableNewRecord({ open, onClose, onSubmit, fields }) {
    const handleSubmit = data => {
        const formData = prepareFormData(data);
        onSubmit(formData);
    };

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="submit">
                    Dodaj
                </Button>
                <Button type="button" onClick={() => onClose()}>
                    Anuluj
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    return <>
        <ModalTransition>
            {open && (
                <Modal
                    onClose={() => {
                        onClose();
                    }}
                    heading="Dodaj nowy rekord"
                    components={{
                        Container: ({ children, className }) => (
                            <Form onSubmit={handleSubmit}>
                                {({ formProps }) => (
                                    <form {...formProps} onSubmit={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        formProps.onSubmit(e);
                                    }}>
                                        {children}
                                    </form>
                                )}
                            </Form>
                        ),
                        Footer: footer,
                    }}
                    scrollBehavior="outside"
                >
                    <Wrapper>
                        {fields.map(field => {
                            const inputProps = {
                                key: field.id,
                                name: field.name,
                                // label: field.label,
                                isRequired: !!field.required,
                                defaultValue: field.field.type === "date" ? format(new Date(), "yyyy-MM-dd") : undefined,
                                options: field?.options || [],
                            };

                            return <FormField key={field.id} inputProps={inputProps} field={field} />;
                        })}
                    </Wrapper>
                </Modal>
            )}
        </ModalTransition>
    </>;
}

const Wrapper = styled.div`
    margin-bottom: 20px;
`;