import React from "react";
import LoginForm from "../../components/auth/login-form";
import AuthLayout from "../../layouts/auth-layout";
import { useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import title from "../../title";
import { API_URL } from "../../api/api-client";
import { toast } from "react-toastify";
import FullPageSpinner from "../../components/ui/full-page-spinner";

function Callback() {
    const query = useQuery();
    const oauth_token = query.get("oauth_token");
    const oauth_verifier = query.get("oauth_verifier");

    const authorize = async (token, pin) => {
		const nonce = sessionStorage.getItem("nonce");
		const timestamp = sessionStorage.getItem("timestamp");
		const secret = sessionStorage.getItem("secret");

		try {
			const response = await fetch(`${API_URL}/auth/access_token`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json",
				},
				body: JSON.stringify({
					oauth_token: token,
					oauth_verifier: pin,
					nonce,
					timestamp,
					secret,
				}),
			});

			if (!response.ok) {
				toast.error("Wystąpił błąd podczas logowania");
				window.location.href = "/login";
				return;
			}

			const body = await response.json();
			toast.success("Zalogowano");
            window.localStorage.setItem("auth.token", JSON.stringify(body.token));
			window.location.href = "/";
		} catch (e) {
			// todo
			console.error(e);
			window.location.href = "/";
		}
	};

	React.useEffect(() => {
		if (!oauth_token || !oauth_verifier) return;

		authorize(oauth_token, oauth_verifier);

	}, [oauth_token, oauth_verifier]);

    return <AuthLayout>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <FullPageSpinner />
    </AuthLayout>;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default Callback;