/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import SelectCategory from "./select-category";
import { useTranslation } from "react-i18next";

export default function AssignCategory({ onClose, onSaved, module }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [ category, setCategory ] = useState(null);
    const { t } = useTranslation();

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit} isDisabled={!category}>
                    {t("admin_assign_category_assign")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_assign_category_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        run(apiClient(`categories/${category?.slug}`, { method: "PATCH", data: {
            module_id: module?.id,
            name: category?.name,
        }}))
            .then(response => {
                onSaved(response);
                toast.success(t("admin_assign_success"));
            })
            .catch(() => {
                toast.error(t("admin_assign_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_assign_heading")}
            scrollBehavior="outside"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_assign_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="category" label={t("admin_assign_category")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <SelectCategory value={category?.value} onChange={e => setCategory(e)} />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;