import { API_URL } from "../api/api-client";

export default function downloadFile(file) {
    const token = window.localStorage.getItem("auth.token");
    return window.fetch(`${API_URL}/file/${file.id}`, {
        headers: {
            "Authorization": token ? `Bearer ${JSON.parse(token)}` : undefined,
        },
    })
        .then(async response => {
            if (response.status === 401) {
                window.localStorage.removeItem("auth.token");
                window.location.href = "/login?redirect=" + window.location.pathname;
                return Promise.reject(data);
            }
            
            if (response.ok) {
                const data = await response.blob();
                return Promise.resolve(data);
            } else {
                return Promise.reject(response);
            }
        });
}