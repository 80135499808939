import React, { useEffect, useState } from "react";
import UserLayout from "../layouts/user-layout";
import { Helmet } from "react-helmet";
import Spinner from "../components/ui/spinner";
import { Link, Redirect, useLocation } from "react-router-dom";
import apiClient from "../api/api-client";
import { toast } from "react-toastify";
import useGlobalConfig from "../hooks/use-global-config";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function FacebookCallback() {
    const query = useQuery();
    const code = query.get("code");
    const [ error, setError ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const { config } = useGlobalConfig("facebook");

    useEffect(() => {
        if (!code) return;

        apiClient("facebook-callback?code=" + code, { method: "POST" })
            .then(response => {
                setSuccess(true);
            })
            .catch(error => {
                toast.error("Wystąpił błąd");
                setError(true);
            });
    }, [ code ]);

    if (!code) {
        return <Redirect to="/login" />;
    }

    if (error) {
        return <Redirect to="/" />;
    }

    if (success) {
        return <Redirect to={`/${config?.path}`}/>;
    }

    return <>
        <Helmet>
            <title>Facebook</title>
        </Helmet>
        <UserLayout>
            <Spinner />
        </UserLayout>
    </>;
}