import React, { useMemo } from "react";
import styles from "./task-list-table.module.css";
import { Task } from "../../types/public-types";
import { format } from "date-fns";

const localeDateStringCache = {};
const toLocaleDateStringFactory =
  (locale: string) =>
    (date: Date, dateTimeOptions: Intl.DateTimeFormatOptions) => {
      const key = date.toString();
      //@ts-ignore
      let lds = localeDateStringCache[key];
      if (!lds) {
        // const start_date = format(project.start, "yyyy-MM-dd");
        // lds = date.toLocaleDateString(locale, dateTimeOptions);
        lds = format(date, "dd.MM.yyyy");
        //@ts-ignore
        localeDateStringCache[key] = lds;
      }
      return lds;
    };
const dateTimeOptions: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const TaskListTableDefault: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
}> = ({
  rowHeight,
  rowWidth,
  tasks,
  fontFamily,
  fontSize,
  locale,
  onExpanderClick,
}) => {
    const toLocaleDateString = useMemo(
      () => toLocaleDateStringFactory(locale),
      [locale]
    );

    return (
      <div
        className={styles.taskListWrapper}
        style={{
          fontFamily: fontFamily,
          fontSize: fontSize,
        }}
      >
        {tasks.map(t => {
          let expanderSymbol = "";
          if (t.hideChildren === false) {
            expanderSymbol = "▼";
          } else if (t.hideChildren === true) {
            expanderSymbol = "▶";
          }

          return (
            <div
              className={styles.taskListTableRow}
              style={{ height: rowHeight }}
              key={`${t.id}row`}
            >
              <div
                className={styles.taskListCell}
                style={{
                  minWidth: "250px",
                  maxWidth: "250px",
                  wordWrap: "normal",
                  height: "50px",
                  maxHeight: "50px",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  lineHeight: "1rem !important",
                  display: "flex",
                  alignItems: "center",
                }}
                title={t.name}
              >
                <div className={styles.taskListNameWrapper} style={{
                  alignItems: "center",
                }}>
                  <div
                    className={
                      expanderSymbol
                        ? styles.taskListExpander
                        : styles.taskListEmptyExpander
                    }
                    onClick={() => onExpanderClick(t)}
                  >
                    {expanderSymbol}
                  </div>
                  <div style={{
                    maxHeight: "50px",
                    lineHeight: "1rem !important",
                    alignItems: "center",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    lineClamp: 2,
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}>{t.name}</div>
                </div>
              </div>
              <div
                className={styles.taskListCell}
                style={{
                  minWidth: rowWidth,
                  maxWidth: rowWidth,
                  textAlign: "center",
                }}
              >
                {toLocaleDateString(t.start, dateTimeOptions)}
              </div>
              <div
                className={styles.taskListCell}
                style={{
                  minWidth: rowWidth,
                  maxWidth: rowWidth,
                  textAlign: "center",
                }}
              >
                {toLocaleDateString(t.end, dateTimeOptions)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
