import MultiSelect from "@atlaskit/multi-select";
import React, { useEffect } from "react";
import Spinner from "../ui/spinner";
import { useTranslation } from "react-i18next";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import Select from "@atlaskit/select";
import { toast } from "react-toastify";

export default function SelectOrganization({ onChange, value }) {
	const { t } = useTranslation();
	const { run, isPending, data } = useAsync();

	useEffect(() => {
		reload();
	}, []);

	const reload = () => {
		run(apiClient("organizations"))
			.catch(() => {
				toast.error(t("admin_organizations_error"));
			});
	};

	const organizations = data?.data ?? [];

	return <Select
		isDisabled={isPending}
		isClearable
		value={value}
		placeholder={t("select_organization_placeholder")}
		shouldFitContainer
		onChange={onChange}
		options={organizations?.map(o => ({
			label: o?.name,
			value: o?.id,
		}))} />;
}