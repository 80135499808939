import React from "react";
import { AsyncSelect } from "@atlaskit/select";
import apiClient from "../../api/admin-api-client";
import { useTranslation } from "react-i18next";

function SelectWorker({ isDisabled, onChange, value }) {
    const { t } = useTranslation();
    const search = () => apiClient("workers")
        .then(response => {
            const data = Object.keys(response)
                ?.map(moduleSlug => {
                    const workers = response[moduleSlug];
                    return workers?.map(worker => {
                        worker.module = moduleSlug;
                        return worker;
                    });
                })
                ?.reduce((acc, workers) => {
                    workers?.map(worker => {
                        acc.push({
                            module: worker.module,
                            value: worker.worker,
                            type: worker.type,
                            name: worker.worker,
                            id: worker.worker,
                            label: `[${worker.module}] ${worker.worker}`
                        });
                    });
                    return acc;
                }, []);
            console.log(data);
            return data;
        })
        .catch(() => Promise.resolve([]));

    return  <AsyncSelect placeholder={t("select_worker_placeholder")}
        isSearchable={false}
        loadOptions={search} 
        value={value} 
        onChange={onChange} 
        defaultOptions
        isDisabled={isDisabled} />;
}

export default SelectWorker;