import React, { useEffect, useState } from "react";
import PageHeader from "@atlaskit/page-header";
import AdminLayout from "../../layouts/admin-layout";
import useModules from "../../hooks/use-modules";
import DynamicTable from "@atlaskit/dynamic-table";
import Spinner from "../../components/ui/spinner";
import { Checkbox } from "@atlaskit/checkbox";
import useAdminRoles from "../../hooks/use-admin-roles";
import Select from "@atlaskit/select";
import styled from "styled-components";
import useAdminRolePermissions from "../../hooks/use-admin-role-permissions";
import apiClient from "../../api/admin-api-client";
import { toast } from "react-toastify";
import useAdminPermissions from "../../hooks/use-admin-permissions";
import useAdminModules from "../../hooks/use-admin-modules";
import useAdminAllCategories from "../../hooks/use-admin-all-categories";
import useAdminAllSpaces from "../../hooks/use-admin-all-spaces";
import { useTranslation } from "react-i18next";

function AdminPermissions() {
    const { roles, isPending: isPendingRoles } = useAdminRoles();
    const { modules, isPending } = useAdminModules();
    const { categories, isPending: isCategoriesPending } = useAdminAllCategories();
    // const { spaces, isPending: isSpacesPending } = useAdminAllSpaces();
    const [role, setRole] = useState(false);
    const { permissions, isPending: isPermissionsRolesPending, reload } = useAdminRolePermissions(role);
    const { permissions: allPermissions, isPending: isPermissionsPending } = useAdminPermissions();
    // const [ spaceInfo, setSpaceInfo ] = useState(null);
    const { t } = useTranslation();

    // useEffect(() => {
    //     if (spaces?.length === 0) return;

    //     Promise.all(spaces?.map(space => apiClient(`spaces/${space?.slug}`)))
    //         .then(spacesInfoData => {
    //             const data = spacesInfoData?.reduce((acc, item) => {
    //                 acc[item?.data?.slug] = item?.data;
    //                 return acc;
    //             }, {});
    //             setSpaceInfo(data);
    //         })
    //         .catch((e) => {
    //             console.error(e);
    //             toast.error("Nie udało się pobrać informacji o przestrzeniach");
    //         });
    // }, [ spaces ]);

    if (isPending || isCategoriesPending) {
        return <AdminLayout>
            <Spinner />
        </AdminLayout>;
    }

    const isLoading = isPermissionsPending || isPermissionsRolesPending;

    const toggle = (space, name) => {
        const permission = permissions[space?.id] && permissions[space?.id][name] ? permissions[space?.id][name] : null;
        const permissionId = allPermissions[name];
        const currentValue = permission ? true : false;
        const newValue = !currentValue;

        if (newValue) {
            apiClient(`roles/${role}/spaces/${space?.slug}/permissions/${permissionId}`, { method: "POST" })
                .then(() => {
                    toast.success(t("admin_permissions_success"), {
                        position: "bottom-left"
                    });
                    reload();
                })
                .catch(() => toast.error(t("admin_permissions_error")));
        } else {
            apiClient(`roles/${role}/spaces/${space?.slug}/permissions/${permissionId}`, { method: "DELETE" })
                .then(() => {
                    toast.success(t("admin_permissions_revoked"));
                    reload();
                })
                .catch(() => toast.error(t("admin_permissions_revoke_error")));
        }
    };

    const turnOn = (space, name) => {
        if (permissions[space?.id] && !!permissions[space?.id][name]) {
            return Promise.resolve();
        }

        return apiClient(`roles/${role}/spaces/${space?.slug}/permissions/${allPermissions[name]}`, { method: "POST" });
    };

    const turnOff = (space, name) => {
        if (!permissions[space?.id] || !permissions[space?.id][name]) {
            return Promise.resolve();
        }

        return apiClient(`roles/${role}/spaces/${space?.slug}/permissions/${allPermissions[name]}`, { method: "DELETE" });
    };

    const turnOnAll = (space) => {
        Promise.all([
            turnOn(space, "access"),
            turnOn(space, "create"),
            turnOn(space, "link"),
            turnOn(space, "read"),
            turnOn(space, "edit"),
            turnOn(space, "delete"),
            turnOn(space, "users"),
            turnOn(space, "attachments"),
            turnOn(space, "comments"),
            turnOn(space, "links"),
        ])
            .then(() => {
                toast.success(t("admin_permissions_success"), {
                    position: "bottom-left"
                });
                reload();
            })
            .catch(e => {
                toast.error(t("admin_permissions_error"));
            });
    };

    const turnOffAll = (space) => {
        Promise.all([
            turnOff(space, "access"),
            turnOff(space, "create"),
            turnOff(space, "link"),
            turnOff(space, "read"),
            turnOff(space, "edit"),
            turnOff(space, "delete"),
            turnOff(space, "users"),
            turnOff(space, "attachments"),
            turnOff(space, "comments"),
            turnOff(space, "links"),
        ])
            .then(() => {
                toast.success(t("admin_permissions_success"), {
                    position: "bottom-left"
                });
                reload();
            })
            .catch(() => toast.error(t("admin_permissions_error")));
    };


    const head = {
        cells: [
            { key: "module", content: t("admin_permissions_module"), isSortable: false },
            { key: "category", content: t("admin_permissions_category"), isSortable: false },
            { key: "space", content: t("admin_permissions_space"), isSortable: false, width: 300, },
            { key: "access", content: t("admin_permissions_access"), isSortable: false, width: 50 },
            { key: "create", content: t("admin_permissions_create"), isSortable: false },
            { key: "link", content: t("admin_permissions_link"), isSortable: false, width: 50 },
            { key: "read", content: t("admin_permissions_browse"), isSortable: false, width: 50 },
            { key: "edit", content: t("admin_permissions_update"), isSortable: false, width: 50 },
            { key: "delete", content: t("admin_permissions_delete"), isSortable: false, width: 50 },
            { key: "users", content: t("admin_permissions_users"), isSortable: false, width: 50 },
            { key: "attachments", content: t("admin_permissions_attachments"), isSortable: false, width: 50 },
            { key: "comments", content: t("admin_permissions_comments"), isSortable: false, width: 50 },
            { key: "links", content: t("admin_permissions_links"), isSortable: false, width: 50 },
            { key: "all", content: "", isSortable: false, width: 50 },
        ]
    };

    const rows = modules?.reduce((acc, module) => {
        categories?.filter(c => c.module?.id === module?.id)?.map(category => {
            category?.spaces?.map(space => {
                const checkedNum = permissions[space?.id] ? Object.keys(permissions[space?.id]).map(i => !!permissions[space?.id][i]).filter(i => i).length ?? 0 : 0;

                acc.push({
                    key: space?.id,
                    cells: [
                        { key: "module", content: module?.alias },
                        { key: "category", content: category?.name },
                        { key: "space", content: <strong>{space?.name}</strong> },
                        { key: "access", content: <Checkbox isDisabled={isLoading} isChecked={!!permissions[space?.id]?.access} onChange={e => toggle(space, "access")} /> },
                        { key: "create", content: <Checkbox isDisabled={isLoading} isChecked={!!permissions[space?.id]?.create} onChange={e => toggle(space, "create")} /> },
                        { key: "link", content: <Checkbox isDisabled={isLoading} isChecked={!!permissions[space?.id]?.link} onChange={e => toggle(space, "link")} /> },
                        { key: "read", content: <Checkbox isDisabled={isLoading} isChecked={!!permissions[space?.id]?.read} onChange={e => toggle(space, "read")} /> },
                        { key: "edit", content: <Checkbox isDisabled={isLoading} isChecked={!!permissions[space?.id]?.edit} onChange={e => toggle(space, "edit")} /> },
                        { key: "delete", content: <Checkbox isDisabled={isLoading} isChecked={!!permissions[space?.id]?.delete} onChange={e => toggle(space, "delete")} /> },
                        { key: "users", content: <Checkbox isDisabled={isLoading} isChecked={!!permissions[space?.id]?.users} onChange={e => toggle(space, "users")} /> },
                        { key: "attachments", content: <Checkbox isDisabled={isLoading} isChecked={!!permissions[space?.id]?.attachments} onChange={e => toggle(space, "attachments")} /> },
                        { key: "comments", content: <Checkbox isDisabled={isLoading} isChecked={!!permissions[space?.id]?.comments} onChange={e => toggle(space, "comments")} /> },
                        { key: "links", content: <Checkbox isDisabled={isLoading} isChecked={!!permissions[space?.id]?.links} onChange={e => toggle(space, "links")} /> },
                        {
                            key: "all", content: <Checkbox isDisabled={isLoading} isChecked={checkedNum > 0} isIndeterminate={checkedNum > 0 && checkedNum < 10} onChange={e => {
                                if (checkedNum == 10) {
                                    turnOffAll(space);
                                } else {
                                    turnOnAll(space);
                                }
                            }} />
                        },
                    ]
                });
            });
        });
        return acc;
    }, []);

    return <AdminLayout>
        <PageHeader>
            {t("admin_permissions_heading")}
        </PageHeader>
        <RoleSelectWrapper>
            <Select
                isLoading={isPendingRoles || isPermissionsPending}
                onChange={e => setRole(e?.value)}
                placeholder={t("admin_permissions_select_role")}
                options={roles?.map(r => ({ label: r?.name, value: r?.id }))}
            />
        </RoleSelectWrapper>
        {role && <DynamicTable
            head={head}
            rows={rows}
            isLoading={isPendingRoles}
            emptyView={<div>{t("admin_permissions_empty")}</div>}
        />}
    </AdminLayout>;
}

export default AdminPermissions;

const RoleSelectWrapper = styled.div`
    margin-bottom: 20px;
    
`;