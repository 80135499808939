import React, { useState } from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import apiClient from "../../api/admin-api-client";
import { toast } from "react-toastify";
import useAsync from "../../hooks/use-async";
import { format } from "date-fns";
import { DateTimePicker } from "@atlaskit/datetime-picker";
import { Field } from "@atlaskit/form";
import { useTranslation } from "react-i18next";

export default function BlockUserDate({ user, onBlocked, onClose }) {
    const { t } = useTranslation();
    const { run, isPending } = useAsync();
    const [ date, setDate ] = useState(format(new Date(), "yyyy-MM-dd HH:mm"));
    const remove = () => {
        run(apiClient(`user/${user.id}/block`, { method: "PATCH", data: {
            date: format(Date.parse(date), "yyyy-MM-dd HH:mm"),
        }}))
            .then(() => {
                toast.success(t("admin_block_user_success"));
                onBlocked && onBlocked();
            })
            .catch(error => toast.error(t("admin_block_user_error") +  error.message));
    };

    return (
        <>
            <ModalTransition>
                <Modal
                    isLoading={isPending}
                    actions={[
                        { text: t("admin_block_user_block"), onClick: remove, isLoading: isPending, isDisabled: !date },
                        { text: t("admin_block_user_cancel"), onClick: onClose, isDisabled: isPending },
                    ]}
                    onClose={close}
                    heading={t("admin_block_user_heading")}
                    appearance="warning"
                >
                    <Field name="date" label={t("admin_block_user_from_date")}>
                        {({ fieldProps }) => <DateTimePicker {...fieldProps} value={date} onChange={e => setDate(e)} autoFocus={false}  />}
                    </Field>
                </Modal>
            </ModalTransition>
        </>
    );
}