/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import TextArea from "@atlaskit/textarea";
import TextField from "@atlaskit/textfield";
import SelectRoles from "../roles/select-roles";
import Select from "@atlaskit/select";
import { useTranslation } from "react-i18next";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import Ajv from "ajv";

export default function EditDocumentTypeLink({ onClose, onSuccess, id, attributes, position }) {
    const { run, isPending, error, isError } = useAsync();
    const [config, setConfig] = useState(JSON.stringify(attributes));
    const [pos, setPosition] = useState(position ?? 0);
    const { t } = useTranslation();


    const [parsedConfig, setParsedConfig] = useState(() => {
        try {
            return JSON.parse(config);
        } catch (e) {
            return "";
        }
    });

    useEffect(() => {
        try {
            setParsedConfig(JSON.parse(config));
        } catch (e) {
            console.log(e);
        }
    }, [config]);

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit} isDisabled={!config}>
                    {t("save")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        run(apiClient(`document-type-link/${id}/attributes`, {
            data: {
                attributes: config,
                position: pos,
            }, method: "PATCH"
        }))
            .then(response => {
                toast.success(t("admin_edit_document_type_link_success"));
                onSuccess();
            })
            .catch(() => {
                toast.error(t("admin_edit_document_type_link_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_edit_document_type_link_heading")}
            scrollBehavior="outside"
            components={{
                Footer: footer,
            }}
        >
            {isError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_edit_document_type_link_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            {/* <Field name="config" label={t("admin_edit_document_type_link_config")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextArea
                    {...fieldProps}
                    isMonospaced
                    isDisabled={isPending}
                    value={config}
                    onChange={e => setConfig(e?.target?.value)}
                    minimumRows={5}
                />}
            </Field> */}
            <Field name="config" label={t("admin_edit_document_type_link_config")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <Editor
                    value={parsedConfig}
                    mode={Editor.modes.code}
                    theme="ace/theme/tomorrow_night_blue"
                    ajv={Ajv({ allErrors: true, verbose: true })}
                    onChange={e => {
                        try {
                            setConfig(JSON.stringify(e));
                        } catch (e) {
                            console.log(e);
                        }
                    }}
                />}
            </Field>
            <Field name="position" label={t("admin_edit_field_position")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField type="number" {...fieldProps} value={pos} onChange={e => setPosition(e.target.value)} isInvalid={error?.errors?.position} autoComplete={false} />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;

