import { jsx } from "@emotion/core";
import React, { useState } from "react";
import Popup from "@atlaskit/popup";
import { NotificationIndicator } from "@atlaskit/notification-indicator";
import { NotificationLogClient } from "@atlaskit/notification-log-client";
import { Notifications } from "@atlaskit/atlassian-navigation";
import { default as NotificationsList } from "./notifications";
import apiClient from "../../api/api-client";
import styled from "styled-components";

const wrapperCSS = {
    width: 460,
    maxHeight: "calc(100vh - 100px)",
    padding: "10px",
    display: "flex",
    overflowX: "hidden",
    overflowY: "auto"
};

const NotificationsContent = () => (
    <div style={wrapperCSS}>
        <NotificationsList />
    </div>
);

class MockNotificationLogClient extends NotificationLogClient {
    constructor() {
        super("", "");
    }

    async countUnseenNotifications() {
        const counted = localStorage.getItem("counted");

        if (counted) {
            const countedDate = localStorage.getItem("counted-date");
            if (countedDate) {
                const now = Date.now();
                const diff = now - parseInt(countedDate);

                if (diff < 60 * 1000) {
                    return { count: counted };
                }
            }
        }

        return apiClient("notifications/unread/counted")
            .then(response => {
                localStorage.setItem("counted", response.counted);
                localStorage.setItem("counted-date", Date.now());
                return { count: response.counted };
            })
            .catch(() => ({ count: 0 }));
    }
}

const client = new MockNotificationLogClient();

export const NotificationsPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [buttonLabel, setButtonLabel] = useState();

    const updateButtonLabel = ({ newCount }) => {
        setButtonLabel(newCount || 0);
    };

    const NotificationsBadge = () => (
        <>
            <Hidden>
                <NotificationIndicator
                    onCountUpdated={updateButtonLabel}
                    notificationLogProvider={Promise.resolve(client)}
                />
            </Hidden>
            {parseInt(buttonLabel) > 0 ? <Badge>{buttonLabel}</Badge> : null}

        </>
    );

    const onClick = () => {
        setIsOpen(!isOpen);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <Popup
            placement="bottom-end"
            content={NotificationsContent}
            isOpen={isOpen}
            rootBoundary="viewport"
            onClose={onClose}
            trigger={triggerProps => (
                <Notifications
                    badge={NotificationsBadge}
                    onClick={onClick}
                    tooltip={`Powiadomienia (${buttonLabel})`}
                    isSelected={isOpen}
                    {...triggerProps}
                />
            )}
        />
    );
};


const Badge = styled.div`
    font-size: 11px;
    color: white;
    background-color: #fa1111;
    border-radius: 20px;
    padding: 1px 6px;
    text-align: center;
`;

const Hidden = styled.div`
    visibility: hidden !important;
    position: absolute;
    width: 1px;
    height: 1px;
    left: -9999px;
`;