import React, { useState } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import useDashboardDocuments from "../../hooks/use-dashboard-documents";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import styled from "styled-components";
import { Link } from "react-router-dom";
import slugify from "slugify";
import { useTranslation } from "react-i18next";

function DashboardDocuments({ module = null }) {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const { data, isPending } = useDashboardDocuments(module, currentPage);

    const head = {
        cells: [
            { key: "type", content: "", width: "16px", padding: "5px" },
            { key: "name", content: t("dashboard_column_name"), isSortable: false },
            { key: "module", content: t("dashboard_column_module"), isSortable: false },
        ]
    };

    const rows = data?.data?.map(document => ({
        key: document.id,
        cells: [
            { key: "type", content: <RowSpacer><Page16Icon /></RowSpacer> },
            { key: "name", content: <RowHeader document={document} /> },
            {
                key: "module", content:
                    <Category>
                        {document?.category?.module?.alias}/
                        {document?.category?.name}/
                        {document?.space?.name}
                    </Category>
            }
        ]
    }));

    return <>
        <DynamicTable
            head={head}
            rows={rows}
            loadingSpinnerSize="large"
            isLoading={isPending}
            emptyView={<div>{t("dashboard_empty")}</div>}
            page={currentPage}
            onSetPage={p => setCurrentPage(p)}
            rowsPerPage={data?.per_page}
        />
    </>;
}

export default DashboardDocuments;

function RowHeader({ document }) {
    return <Link to={`/${document?.category?.module?.name}/${document?.category?.slug ?? slugify(document?.category?.name || "")}/${document?.space?.slug}/${document.id}`.toLowerCase()}>{document.name}</Link>;
}

const RowSpacer = styled.div`
    height: 30px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Category = styled.span`
color: rgb(137, 147, 164);
text-decoration: none;
outline: currentcolor none medium;
margin-left: 8px;
color: rgb(137, 147, 164);
font-weight: 600;
font-size: 12px;
text-decoration: none;
white-space: nowrap;
margin-right: 10px;
`;