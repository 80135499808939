import { useEffect, useMemo } from "react";
import apiClient from "../api/api-client";
import useAsync from "../hooks/use-async";
import { useLocation } from "react-router-dom";

export default function useDocumentTypeFilters(documentTypeId) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();
    const query = useQuery();
    const queryFilterId = query.get("filter");

    useEffect(() => {
        if (!queryFilterId) return;
        if (!documentTypeId) return;
        run(apiClient(`document-type/${documentTypeId}/filters`)).catch(() => { });
    }, [ documentTypeId, queryFilterId ]);

    return {
        data: useMemo(() => data ? data.reduce((acc, item) => {
            acc[item.id] = {
                ...item.data,
                filters: item.data.filters.map(f => ({
                    ...f,
                    column: f.name,
                }))  
            };
            return acc;
        }, {}) : {}, [ data ]),
        isPending: isIdle || isPending,
        isError,
        isSuccess,
    };
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}