/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useAdminModules from "../../hooks/use-admin-modules";
import Select from "@atlaskit/select";

export default function EditOrganization({ onClose, onAdded, organization }) {
	const { run, isPending, error, isError: isCreatingError } = useAsync();
	const [name, setName] = useState(organization.name);
	const { t } = useTranslation();


	const { modules, isPending: isPendingModules } = useAdminModules();
	const [selectedModules, setSelectedModules] = useState([]);

	useEffect(() => {
		if (isPendingModules) return;

		const organizationModules = (organization?.modules ?? [])?.map(m => m?.id) ?? [];

		setSelectedModules(
			modules
				?.filter(i => organizationModules?.includes(i?.id))
				?.map(i => ({
					label: i?.name,
					value: i?.id,
				}))
		);
	}, [isPendingModules]);

	const footer = (props) => (
		<ModalFooter showKeyline={props.showKeyline}>
			<ButtonGroup>
				<Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit}>
					{t("admin_edit_organization_create")}
				</Button>
				<Button type="button" isDisabled={isPending} onClick={onClose}>
					{t("admin_edit_organization_cancel")}
				</Button>
			</ButtonGroup >
		</ModalFooter>
	);

	const handleSubmit = () => {
		run(apiClient(`organizations/${organization.id}`, {
			data: {
				name: name,
				modules: selectedModules?.map(m => m?.value) ?? [],
			},
			method: "PATCH"
		}))
			.then(response => {
				onAdded(response);
				toast.success(t("admin_edit_organization_success"));
				onClose();
			})
			.catch(() => {
				toast.error(t("admin_edit_organization_error"));
			});
	};

	return <ModalTransition>
		<Modal
			onClose={onClose}
			heading={t("admin_edit_organization_heading")}
			scrollBehavior="outside-wide"
			components={{
				Footer: footer,
			}}
		>
			{isCreatingError && <SectionMessageWrapper>
				<SectionMessage title={t("admin_edit_organization_error_heading")} appearance="error">
					<p>{error?.message}</p>
					{error?.errors && <ul>
						{Object.keys(error.errors).map(key => (
							<li key={key}>{error.errors[key]}</li>
						))}
					</ul>}
				</SectionMessage>
			</SectionMessageWrapper>}
			<Field name="name" label={t("admin_edit_organization_name")} isRequired autoFocus isDisabled={isPending}>
				{({ fieldProps }) => <TextField {...fieldProps} value={name} onChange={e => setName(e.target.value)} isInvalid={error?.errors?.name} autoComplete={false} />}
			</Field>
			<Field name="modules" label={t("admin_edit_role_modules")}>
				{({ fieldProps }) => <Select
					value={selectedModules}
					options={modules?.map(m => ({
						label: m?.name,
						value: m?.id,
					}))}
					onChange={e => setSelectedModules(e)}
					isDisabled={isPending}
					isLoading={isPendingModules}
					isClearable={true}
					isMulti={true} />}
			</Field>
		</Modal>
	</ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;