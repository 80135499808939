import React, { useEffect, useState } from "react";
import FileItemForField from "./file-item-for-field";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

export default function FilesFiled({ isNew = false, isSingle = false, accept = undefined, size = null, value = [], onChange, editMode, onRemoved }) {
    const { t } = useTranslation();
    const [files, setFiles] = useState(value);

    useEffect(() => {
        setFiles(value);
    }, [value]);
    const addFiles = f => setFiles(oldFiles => [...oldFiles, ...f]);
    const handleDrop = f => {
        addFiles(f.map(i => ({
            id: uuidv4(),
            file: i,
            name: i.path,
            status: "uploading"
        })));
        // onDrop(files);
    };
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop: handleDrop,
        multiple: false,
        accept,
    });
    const onFileUploadError = f => setFiles(oldFiles => oldFiles.filter(i => {
        return i.id !== f.id;
    }));
    const onFileUploadSuccess = (f, data) => {
        setFiles(oldFiles => oldFiles.map(i => {
            return i.id === f.id ? { ...f, status: "uploaded", data } : i;
        }));

        onChange(data);
    };

    return <>
        <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <span>{t("attachments_drop_here")}</span> :
                    <span>{t("attachments_drop_n_drag")}</span>
            }
        </Container>
        {files.length > 0 && <FilesList>
            {files.map(f => <FileItemForField key={f.id}
                file={f}
                isNew={isNew}
                isSingle={isSingle}
                size={size}
                editMode={editMode}
                onRemoved={onRemoved}
                onSuccess={(data) => onFileUploadSuccess(f, data)}
                onError={() => onFileUploadError(f)} />)}
        </FilesList>}
    </>;
}

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  `;

const FilesList = styled.div`
    margin-top: 30px;
`;

const getColor = (props) => {
    if (props.isDragAccept) {
        return "#36B37E";
    }
    if (props.isDragReject) {
        return "#DE350B";
    }
    if (props.isDragActive) {
        return "#42526E";
    }
    return "#eeeeee";
};