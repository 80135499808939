export default function documentName(document) {
    const nameField = document?.document_type?.fields?.find(i => i.name === "name");
    const isSingle = document?.document_type?.space?.config?.view === "single";

    if (isSingle) {
        return document?.document_type?.space?.name;
    }

    if (!nameField) return null;
    return document.values[nameField.id] || null;
}
