import { useCallback, useEffect } from "react";
import apiClient from "../api/api-client";
import useAsync from "../hooks/use-async";

export default function useCms(namespace, uuid) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        if (namespace == "p") return;
        
        reload();
    }, [ namespace, uuid ]);

    const reload = useCallback(() => run(apiClient(`cms/${namespace}/${uuid}`)).catch(() => { }));

    return {
        page: data, 
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        reload,
    };
}