import React, { useState } from "react";
import TextField from "@atlaskit/textfield";
import { Field } from "@atlaskit/form";
import FormField from "../forms/form-field";
import toBoolean from "../../utils/to-boolean";

function EditDocumentForm({ document, fields, error, onChange, setDocumentsToLinkAfterSave, setDocumentsToUnlinkAfterSave, setDocumentsToUpdateAfterSave, data = {}, propagateFields, hiddenSections = [], onSectionToggle }) {
    const [ hidden, setHidden ] = useState(hiddenSections);
    const toggleSection = sectionId => {
        if (hidden.includes(sectionId)) {
            setHidden(h => h.filter(i => i != sectionId));
        }
        else {
            setHidden(h => [ ...h, sectionId ]);
        }

        onSectionToggle(sectionId);
    };

    return <>
        {fields.filter(i => !toBoolean(i.hidden)).map(field => {
            const inputProps = {
                key: field.id,
                name: field.name,
                label: field?.field?.type === "checkbox" ? field?.options?.label : field.label,
                isRequired: (!!field.required || !!field?.must_have) && !toBoolean(field.read_only),
                defaultValue: field?.field?.type === "checkbox" ? toBoolean(document.values[field.id]) : document.values[field.id],
                isInvalid: error?.errors[field.name],
                table_column: field?.table_column || [],
                columns_values: document?.columns_values,
                isDisabled: toBoolean(field?.read_only ?? 0),
                rawValues: document?.rawValues || [],
            };

            return <FormField 
                key={field.id} 
                inputProps={inputProps} 
                onChange={onChange}
                document={document}
                setDocumentsToUnlinkAfterSave={setDocumentsToUnlinkAfterSave}
                setDocumentsToLinkAfterSave={setDocumentsToLinkAfterSave}
                setDocumentsToUpdateAfterSave={setDocumentsToUpdateAfterSave}
                value={data[field.name]}
                propagateFields={propagateFields}
                onSectionToggle={sectionId => toggleSection(sectionId)}
                sectionHidden={hidden?.includes(field?.sectionId)}
                field={field} />;
        })}
    </>;
}

export default EditDocumentForm;