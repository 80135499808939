import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";

function GuestRoute({ children, ...props }) {
    const { user } = useAuth();

    if (user) {
        return <Redirect to="/" />;
    }

    return <Route {...props}>
        {children}
    </Route>;
}

export default GuestRoute;