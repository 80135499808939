import React, { useCallback, useEffect, useRef, useState } from "react";

import Breadcrumbs, { BreadcrumbsItem } from "@atlaskit/breadcrumbs";
import Button from "@atlaskit/button";
import { Field } from "@atlaskit/form";
import { Link, useParams, useHistory } from "react-router-dom";
import Textfield from "@atlaskit/textfield";
import styled from "styled-components";
import {
    Section,
    ButtonItem,
    SideNavigation,
} from "@atlaskit/side-navigation";
import SearchIcon from "@atlaskit/icon/glyph/search";

import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from "@atlaskit/modal-dialog";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import IconWidget from "../widgets/icon-widget";
import { useTranslation } from "react-i18next";

export default function SearchModal({ isOpen, setIsOpen, initialQuery = "" }) {
    const openModal = useCallback(() => setIsOpen(true), []);
    const { t } = useTranslation();
    const history = useHistory();
    const [selected, setSelected] = useState(null);
    const closeModal = useCallback(() => {
        setIsOpen(false);
        setQuery("");
    }, []);
    const focusRef = useRef();
    const [query, setQuery] = useState(initialQuery);
    const { isPending, run, data } = useAsync();

    useEffect(() => {
        if (!isOpen) return;

        run(apiClient(`search?query=${query}`, {}))
            .then((data) => {
                const item = data?.hits?.[0];
                const id = item?.id;

                setSelected(id ?? null);
            });
    }, [query, isOpen]);

    useEffect(() => {
        if (!isOpen) return;
        setQuery(initialQuery);
    }, [isOpen, initialQuery]);

    const onKeyPress = (e) => {
        if (e?.key == "ArrowUp") {
            e.stopPropagation();
            e.preventDefault();
            goUp();
            return;
        }

        if (e?.key == "ArrowDown") {
            e.stopPropagation();
            e.preventDefault();
            goDown();
            return;
        }

        if (e?.key == "Enter") {
            e.stopPropagation();
            e.preventDefault();

            const selectedHit = data?.hits?.find(i => i?.id === selected || i?.meta?.base?.id === selected);
            if (!selectedHit) return;

            if (selectedHit?.meta?.base) {
                const space = selectedHit?.meta?.base?.meta?.space;
                const category = selectedHit?.meta?.base?.meta?.category;
                const module = selectedHit?.meta?.base?.meta?.module;


                const dSpace = selectedHit?.meta?.space;
                const dCategory = selectedHit?.meta?.category;
                const dModule = selectedHit?.meta?.module;

                const path = `${module?.slug}/${category?.slug}/${space.slug}`.toLowerCase();
                const url = `/${path}/${selectedHit?.meta?.base.id}#${dModule?.alias}/${dCategory?.name}/${dSpace.name}`;

                history.push(url);
            } else {
                const space = selectedHit?.meta?.space;
                const category = selectedHit?.meta?.category;
                const module = selectedHit?.meta?.module;

                const path = `${module?.slug}/${category?.slug}/${space.slug}`.toLowerCase();
                const url = `/${path}/${selectedHit.id}`;

                history.push(url);
            }


            closeModal();
            return;
        }
    };

    const goUp = () => {
        if (!data?.hits) return;
        if (data?.hits?.length == 0) return;
        if (data?.hits?.[0]?.id === selected) return;

        const index = data?.hits?.findIndex(i => i?.id === selected);
        const item = data?.hits?.[index - 1];
        const id = item?.id;

        setSelected(id);
    };

    const goDown = () => {
        if (!data?.hits) return;
        if (data?.hits?.length == 0) return;

        if (data?.hits?.[data?.hits?.length - 1]?.id === selected) return;

        const index = data?.hits?.findIndex(i => i?.id === selected);
        const item = data?.hits?.[index + 1];
        const id = item?.id;
        setSelected(id);
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeyPress);

        return () => document.removeEventListener("keydown", onKeyPress);
    });


    const hits = data?.hits ?? [];
    const results = hits;
    const count = data?.estimatedTotalHits ?? 0;
    const ms = data?.processingTimeMs ?? 0;

    return (
        <>
            <ModalTransition>
                {isOpen && (
                    <Modal autoFocus={focusRef} onClose={closeModal} scrollBehavior="outside" style={{
                        backgroundColor: "blue"
                    }}>
                        <FlexCol>
                            <div>
                                <Field label="" name="search" defaultValue="" style={{
                                }} >
                                    {({ fieldProps }) => (
                                        <Textfield
                                            ref={focusRef}
                                            autoComplete="off"
                                            placeholder={t("Search")}
                                            style={{
                                            }}
                                            elemBeforeInput={
                                                <SearchIconWrapper>
                                                    <SearchIcon />
                                                </SearchIconWrapper>
                                            }
                                            {...fieldProps}
                                            value={query}
                                            onChange={e => setQuery(e.target.value)}
                                        />
                                    )}
                                </Field>
                            </div>
                            <CustomBody>
                                {query?.length > 0 && results?.length == 0 && !isPending && <NoResults>
                                    {t("dashboard_empty")}
                                </NoResults>}
                                {results?.length > 0 && <Results>
                                    {results?.map(result => {
                                        const space = result?.meta?.space;
                                        const category = result?.meta?.category;
                                        const module = result?.meta?.module;
                                        const base = result?.meta?.base;

                                        const path = `${module?.slug}/${category?.slug}/${space.slug}`.toLowerCase();
                                        const breadcrumb = `${module?.alias}/${category?.name}/${space?.name}`;
                                        const isSelected = selected === result?.id;

                                        if (base) {
                                            const baseMeta = base?.meta;
                                            const baseModule = baseMeta?.module;
                                            const baseSpace = baseMeta?.space;
                                            const baseCategory = baseMeta?.category;
                                            const basePath = `${baseModule?.slug}/${baseCategory?.slug}/${baseSpace.slug}`.toLowerCase();
                                            const baseBreadcrumb = `${baseModule?.alias}/${baseCategory?.name}/${baseSpace?.name}`;
                                            const baseIsSelected = selected === result?.id;

                                            return <BaseWrapper>
                                                <BaseDocument>
                                                    <LinkWrapper>
                                                        <Link to={`/${basePath}/${base.id}#${module?.alias}/${category?.name}/${space?.name}`} onClick={() => closeModal()}>
                                                            <ResultWrapper selected={false}>
                                                                <ResultIcon>
                                                                    <IconWidget name={baseSpace?.icon ?? "article"} size="20px" />
                                                                </ResultIcon>
                                                                <ResultFlex1>
                                                                    <ResultName>
                                                                        <HighlightedText
                                                                            text={base?.name}
                                                                            highlight={query}
                                                                        />
                                                                    </ResultName>
                                                                    <BreadcrumbWrapper>
                                                                        <HighlightedText
                                                                            text={baseBreadcrumb}
                                                                            highlight={query}
                                                                        />
                                                                    </BreadcrumbWrapper>
                                                                </ResultFlex1>
                                                            </ResultWrapper>
                                                        </Link>
                                                    </LinkWrapper>
                                                </BaseDocument>
                                                <ComponentDocument>
                                                    <LinkWrapper>
                                                        <Link to={`/${basePath}/${base.id}#${module?.alias}/${category?.name}/${space?.name}`} onClick={() => closeModal()}>
                                                            <ResultWrapper selected={selected === result?.id}>
                                                                <div>
                                                                    └─
                                                                </div>
                                                                <ComponentIcon>
                                                                    <IconWidget name={result?.meta?.space?.icon ?? "article"} size="16px" />
                                                                </ComponentIcon>
                                                                <ResultFlex1>
                                                                    <ResultName>
                                                                        <HighlightedText
                                                                            text={result?.name}
                                                                            highlight={query}
                                                                        />
                                                                    </ResultName>
                                                                    <BreadcrumbWrapper>
                                                                        <HighlightedText
                                                                            text={breadcrumb}
                                                                            highlight={query}
                                                                        />
                                                                    </BreadcrumbWrapper>
                                                                    <HighlightedFields
                                                                        highlight={query}
                                                                        values={result?.values}
                                                                    />
                                                                </ResultFlex1>
                                                                {/* {baseIsSelected && <div>
                                                                    <KeyboardKey>⤷</KeyboardKey>
                                                                </div>} */}
                                                            </ResultWrapper>
                                                        </Link>
                                                    </LinkWrapper>
                                                </ComponentDocument>
                                            </BaseWrapper>;
                                        }

                                        return <LinkWrapper>
                                            <Link to={`/${path}/${result.id}`} onClick={() => closeModal()}>
                                                <ResultWrapper selected={isSelected}>
                                                    <ResultIcon>
                                                        <IconWidget name={result?.meta?.space?.icon ?? "article"} size="20px" />
                                                    </ResultIcon>
                                                    <ResultFlex1>
                                                        <ResultName>
                                                            <HighlightedText
                                                                text={result?.name}
                                                                highlight={query}
                                                            />
                                                        </ResultName>
                                                        <BreadcrumbWrapper>
                                                            <HighlightedText
                                                                text={breadcrumb}
                                                                highlight={query}
                                                            />
                                                        </BreadcrumbWrapper>

                                                        <HighlightedFields
                                                            highlight={query}
                                                            values={result?.values}
                                                        />
                                                    </ResultFlex1>
                                                    {/* {isSelected && <div>
                                                        <KeyboardKey>⤷</KeyboardKey>
                                                    </div>} */}
                                                </ResultWrapper>
                                            </Link>
                                        </LinkWrapper>;
                                    })}
                                </Results>}
                            </CustomBody>
                            <Flex>
                                <Button appearance="subtle" onClick={closeModal}>
                                    {t("Close")}
                                </Button>
                                {results?.length > 0 && <div>
                                    <HitsInfo>
                                        ~{count} ({ms}ms)
                                    </HitsInfo>
                                </div>}
                            </Flex>
                        </FlexCol>
                    </Modal>
                )}
            </ModalTransition >
        </>
    );
}

const Results = styled.div`
    padding: 0px 0;
`;

const ResultWrapper = styled.div`
    display: flex;
    gap: 10px;
    padding: 4px 4px;
    background-color: ${props => props.selected ? "#f4f4f4" : ""};
    border-radius: 4px;
    align-items: start;
`;

const ResultFlex1 = styled.div`
    flex:1;
    flex-shrink: 0;
`;

const LinkWrapper = styled.div`
    text-decoration: none !important;
`;

const ResultIcon = styled.div`
    color: white;
    background-color: #2684ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 2px;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
`;

const ComponentIcon = styled.div`
    color: white;
    background-color: #2684ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 2px;
    width: 20px;
    height: 20px;
`;

const SearchIconWrapper = styled.div`
    font-size: 20px;
    padding: 2px 5px;
    opacity: 0.6;
    margin-top: 5px;
`;

const ResultName = styled.div`
    font-weight: 600;
    color: black;
    opacity: 0.9;
`;

const BreadcrumbWrapper = styled.div`
    font-size: 12px;
    color: #777;
`;

const HitsInfo = styled.div`
    font-size: 12px;
    color: black;
    opacity: 0.5;
    text-align: right;
`;

const KeyboardKey = styled.div`
    font-size: 13px;
    color: #777;
    padding: 3px 7px;
    border-radius: 5px;
    border: 1px solid #aaa;
    margin-right: 5px;
`;

const NoResults = styled.div`
    font-size; 13px;
    text-align: center;
    padding: 30px 10px 10px;
    opacity: 0.7;
`;

const CustomHeader = styled.div`
padding: 10px 0;
`;

const CustomBody = styled.div`
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 600px;
    gap: 10px;
    padding: 20px 0;
`;

// const HighlightedText = ({ text, highlight }) => {
//     const splitText = (text, highlight) => {
//         const parts = text.split(new RegExp(`(${highlight})`, "gi"));
//         return parts.map((part, index) =>
//             part.toLowerCase() === highlight.toLowerCase() ? (
//                 <span key={index} style={{ backgroundColor: "yellow" }}>
//                     {part}
//                 </span>
//             ) : (
//                 part
//             )
//         );
//     };

//     return <div>{splitText(text, highlight)}</div>;
// };

const HighlightedText = ({ text, highlight }) => {
    try {
        const splitHighlight = highlight.split(" ").filter(Boolean); // Split highlight string by space and remove empty strings

        const splitText = (text, highlight) => {
            const regex = new RegExp(`(${highlight.join("|")})`, "gi");
            const parts = text.split(regex);
            return parts.map((part, index) => {
                const isHighlighted = highlight.some(h => part.toLowerCase() === h.toLowerCase());
                return isHighlighted ? (
                    <span key={index} style={{ backgroundColor: "yellow" }}>
                        {part}
                    </span>
                ) : (
                    part
                );
            });
        };

        return <div>{splitText(text, splitHighlight)}</div>;
    } catch (e) {
        return <div>{text}</div>;
    }
};

const HighlightedFields = ({ values, highlight }) => {
    try {
        const result = [];
        const splitHighlight = highlight.split(" ").filter(Boolean);
        const regex = new RegExp(`(${splitHighlight.join("|")})`, "gi");

        for (const [key, text] of Object.entries(values)) {
            const parts = text.split(regex);

            parts.map((part, index) => {
                const isHighlighted = splitHighlight.some(h => part.toLowerCase() === h.toLowerCase());

                if (isHighlighted && !result.some(i => i?.key === key)) {
                    result.push({
                        key,
                        text,
                    });
                }
            });
        }

        if (result.length == 0) {
            return null;
        }

        return <div style={{
            padding: "6px 45px 10px 0",
            color: "black",
            opacity: 0.7,
            fontSize: "11px",
        }}>
            {result?.map(({ key, text }) => (
                <div style={{
                    display: "flex",
                    overflow: "hidden",
                }}>
                    <div style={{
                        fontWeight: 600,
                        flexShrink: 0,
                    }}>- {key}: </div>
                    <div style={{
                        flex: 1,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}>
                        <HighlightedText text={text} highlight={highlight} />
                    </div>
                </div>
            ))}
        </div>;

    } catch (e) {
        return null;
    }
};



const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;


const BaseWrapper = styled.div`
   
`;
const BaseDocument = styled.div`
   
`;
const ComponentDocument = styled.div`
   padding-left: 10px;
`;
