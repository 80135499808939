/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import TextArea from "@atlaskit/textarea";
import { useTranslation } from "react-i18next";

export default function ImportModal({ onClose, onSuccess, documentTypeId }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [ data, setData ] = useState("");
    const { t } = useTranslation();

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit}>
                    {t("admin_import_import")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_import_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        let dataJson;
        try {
            dataJson = JSON.parse(data);
        } catch (e) {
            //
        }

        if (!dataJson) {
            toast.error(t("admin_import_wrong_format"));
            return;
        }

        run(apiClient(`import/document-type/${documentTypeId}/documents`, { data: dataJson, method: "POST" }))
            .then(() => {
                toast.success(t("admin_import_success"));
                onSuccess();
            })
            .catch(() => {
                toast.error(t("admin_import_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_space_import")}
            scrollBehavior="inside-wide"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_import_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                    <code>
                        {JSON.stringify(error)}
                    </code>
                </SectionMessage>
            </SectionMessageWrapper>}
            
            <Field name="data" label={t("admin_import_data")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextArea
                    {...fieldProps}
                    isMonospaced
                    value={data}
                    onChange={e => setData(e?.target?.value)}
                    minimumRows={5}
                />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;

