import React, { useState } from "react";
import Form, { Field, FormFooter } from "@atlaskit/form";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import TextField from "@atlaskit/textfield";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import PageHeader from "@atlaskit/page-header";
import useLogin from "./use-login";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ResetPasswordForm() {
    const { reset, isError, error, isPending } = useLogin();
    const [ submitted, setSubmitted ] = useState(false);
    const { t } = useTranslation();

    const submit = data => {
        reset(data).then(() => {
            setSubmitted(true);
        }).catch(() => { });
    };

    return <Wrapper>
        <PageHeader>{t("reset_password_form_heading")}</PageHeader>
        {!submitted && <Form onSubmit={data => submit(data)}>
            {({ formProps }) => (
                <form {...formProps}>
                    <Field name="email" defaultValue="" label={t("reset_password_form_email")} isRequired>
                        {({ fieldProps }) => <TextField {...fieldProps} testId="email" isInvalid={isError && error?.errors?.email} />}
                    </Field>
                    <FormFooter>
                        <ButtonGroup>
                            <Button type="button" appearance="default">
                                <Link to="/login">
                                    {t("reset_password_form_back_to_login")}
                                </Link>
                            </Button>
                            <Button type="submit" appearance="primary" isLoading={isPending}>
                                {t("reset_password_form_remind_password")}
                            </Button>
                        </ButtonGroup>
                    </FormFooter>
                </form>
            )}
        </Form>}

        <SectionMessageWrapper>
            {isError
                && <SectionMessage title={t("reset_password_form_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                </SectionMessage>}
            {submitted && <SectionMessage title={t("reset_password_form_remind_heading")} appearance="success">
                <p>{t("reset_password_form_remind_p")}</p>
            </SectionMessage>}
        </SectionMessageWrapper>
    </Wrapper>;
}

export default ResetPasswordForm;

const Wrapper = styled.div`
    width: 400px;
`;

const SectionMessageWrapper = styled.div`
    margin-top: 50px;
    font-size: 13px;
`;