import React, { useState } from "react";
import styled from "styled-components";
import ShareIcon from "@atlaskit/icon/glyph/share";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import useFacebookTokens from "../../hooks/use-facebook-tokens";
import DynamicTable from "@atlaskit/dynamic-table";
import Lozenge from "@atlaskit/lozenge";
import { useTranslation } from "react-i18next";

export default function FacebookView() {
    const [loading, setLoading] = useState(false);
    const { tokens, isPending } = useFacebookTokens();
    const { t } = useTranslation();

    const submit = () => {
        setLoading(true);
        apiClient("facebook-redirect", { method: "POST" })
            .then(response => {
                const url = response.redirect;
                window.location.href = url;
            })
            .catch(error => {
                toast.error("Wystąpił błąd");
            })
            .finally(() => setLoading(false));
    };

    const subscribe = () => {
        setLoading(true);
        apiClient("facebook-subscribe", { method: "POST" })
            .then(response => {
                toast.success("Webhook został zasubskrybowany");
            })
            .catch(error => {
                toast.error("Wystąpił błąd");
            })
            .finally(() => setLoading(false));
    };

    const head = {
        cells: [
            { key: "avatar", content: "", width: "16px", padding: "5px" },
            { key: "name", content: t("dashboard_column_name"), isSortable: false },
            { key: "expires", content: t("dashboard_column_expires"), isSortable: false },
            { key: "is_default", content: "", isSortable: false },
        ]
    };

    const rows = tokens?.map(token => ({
        key: document.id,
        cells: [
            { key: "type", content: <RowSpacer>{token.avatar && <Img src={token.avatar} />}</RowSpacer> },
            { key: "name", content: <div>{token.name}</div> },
            { key: "expires", content: <div>{token.expires}</div> },
            { key: "is_default", content: token.default ? <Lozenge>{t("default")}</Lozenge> : "" }
        ]
    }));

    return <div>
        <FacebookButtonWrapper onClick={submit}>
            <FacebookButton>
                Połącz z Facebook
            </FacebookButton>
        </FacebookButtonWrapper>
        <div>
            <a href="https://vseli.com/polityka-prywatnosci.html" target="_blank" rel="noreferrer">Privacy Policy</a>
        </div>
        <Spacer />
        {tokens?.length > 0 && <FacebookButtonWrapper onClick={subscribe}>
            <FacebookButton>
                Subskrybuj webhook
            </FacebookButton>
        </FacebookButtonWrapper>}
        <Spacer />
        <div>
            <DynamicTable
                head={head}
                rows={rows}
                loadingSpinnerSize="large"
                isLoading={isPending}
                emptyView={<div>{t("dashboard_empty")}</div>}
            />
        </div>
    </div>;
}

const FacebookButtonWrapper = styled.div`
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #1877F2;
    color: white;
    width: 200px;
    display: flex;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
`;

const FacebookButton = styled.div`
    margin-left: 5px;
`;

const RowSpacer = styled.div`
    height: 30px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Spacer = styled.div`
    margin-top: 40px;
`;

const Img = styled.img`
width: 30px;
height: 30px;
border-radius: 15px;
`;