import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useDocumentLinkTypes from "../../hooks/use-document-link-types";
import Spinner from "../ui/spinner";
import Select from "@atlaskit/select";
import useAvailableDocumentTypes from "../../hooks/use-available-document-types";
import Button from "@atlaskit/button";
import SearchDocumentsByType from "./search-documents-by-type";
import SearchCompany from "./search-company";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import useGlobalConfig from "../../hooks/use-global-config";

function LinkDocumentFormOnEvent({ onCancel, onSuccess, documentTypeId }) {
    const { data, isPending } = useDocumentLinkTypes();
    const [ type, setType ] = useState(null);
    const [ documentType, setDocumentType ] = useState(null);
    const [ selectedDocument, setSelectedDocument ] = useState(null);
    const { data: availableDocumentTypes, isPending: isAvailableDocumentTypesLoading } = useAvailableDocumentTypes(documentTypeId);
    const { run, isPending: isSaving } = useAsync();
    const types = data?.map(i => ({ value: i.id, label: i.name })) || [];
    const { config: typesMapConfig } = useGlobalConfig("types_map");

    useEffect(() => {
        if (!types) return;
        if (type) return;
        setType(types?.find(i => i.value === typesMapConfig?.relatedId));
    }, [ types ]);

    if (isPending) {
        return <Spinner />;
    }

    const handleSubmit = () => {
        if (!selectedDocument) return;

        onSuccess({
            linked_document_id: selectedDocument.value,
            link_type_id: type?.value,
            linked_document: selectedDocument?.data,
            id: uuidv4(),
        });
    };

    const selectedDocumentType = availableDocumentTypes?.find(i => i.value === documentType?.value);
    const isModal = selectedDocumentType?.isModal;

    return <AddForm onSubmit={handleSubmit}>
        <AddFormRow>
            <Select 
                options={types} 
                isDisabled={isSaving}
                isLoading={!types}
                defaultValue={types?.find(i => i.value === typesMapConfig?.relatedId)}
                placeholder="Typ powiązania" onChange={e => {
                    setSelectedDocument(null);
                    setDocumentType(null);
                    setType(e);
                }} />
        </AddFormRow>
        {availableDocumentTypes && <AddFormRow>
            <Select options={availableDocumentTypes} 
                value={documentType}
                isDisabled={isSaving}
                autoFocus
                placeholder="Typ dokumentu" onChange={e => {
                    setSelectedDocument(null);
                    setDocumentType(e);
                }} isLoading={isAvailableDocumentTypesLoading} />
        </AddFormRow>}
        {documentType && <AddFormRow>
            <SearchDocumentsByType 
                value={selectedDocument}
                isDisabled={isSaving} 
                typeId={documentType?.value} 
                selectedDocumentId={selectedDocument?.value}
                selectedDocumentTypeId={documentType?.value}
                isModal={isModal}
                onChange={e => setSelectedDocument(e)} />
        </AddFormRow>}
        <ButtonsWrapper>
            <Spacer />
            <ButtonWrapper>
                <Button appearance="default" onClick={onCancel} isDisabled={isSaving}>Anuluj</Button>
            </ButtonWrapper>
            <ButtonWrapper>
                <Button appearance="primary" isDisabled={!selectedDocument || !type} isLoading={isSaving}  onClick={handleSubmit}>Dodaj powiązanie</Button>
            </ButtonWrapper>
        </ButtonsWrapper>
    </AddForm>;
}

export default LinkDocumentFormOnEvent;

const AddForm = styled.form`
display: block;
width: 100%;
`;

const AddFormRow = styled.div`
width: 100%;
margin-bottom: 10px;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
    display: flex;
`;

const ButtonWrapper = styled.div`
    margin-left: 10px;
`;