import { useCallback, useEffect } from "react";
import apiClient from "../api/admin-api-client";
import useAsync from "../hooks/use-async";

export default function useAdminDocumentType(documentTypeId) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        if (!documentTypeId) return;
        reload();
    }, [ documentTypeId ]);

    const reload = useCallback(() => run(apiClient(`document-types/${documentTypeId}`)).catch(() => { }));

    return {
        documentType: data?.data || [],
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        isIdle,
        reload,
    };
}