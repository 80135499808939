import React, { ReactChild } from "react";
import { Task, ViewMode } from "../../types/public-types";
import { addToDate } from "../../helpers/date-helper";
import styles from "./grid.module.css";

export type GridBodyProps = {
  tasks: Task[];
  dates: Date[];
  svgWidth: number;
  rowHeight: number;
  columnWidth: number;
  todayColor: string;
  rtl: boolean;
  viewMode: ViewMode;
};
export const GridBody: React.FC<GridBodyProps> = ({
  tasks,
  dates,
  rowHeight,
  svgWidth,
  columnWidth,
  todayColor,
  rtl,
  viewMode,
}) => {
  let y = 0;
  const gridRows: ReactChild[] = [];
  const rowLines: ReactChild[] = [
    <line
      key="RowLineFirst"
      x="0"
      y1={0}
      x2={svgWidth}
      y2={0}
      className={styles.gridRowLine}
    />,
  ];
  for (const task of tasks) {
    gridRows.push(
      <rect
        key={"Row" + task.id}
        x="0"
        y={y}
        width={svgWidth}
        height={rowHeight}
        className={styles.gridRow}
      />
    );
    rowLines.push(
      <line
        key={"RowLine" + task.id}
        x="0"
        y1={y + rowHeight}
        x2={svgWidth}
        y2={y + rowHeight}
        className={styles.gridRowLine}
      />
    );
    y += rowHeight;
  }

  let now = new Date();
  let tickX = 0;
  const ticks: ReactChild[] = [];
  const weekendTicks: ReactChild[] = [];
  let today: ReactChild = <line />;
  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];
    ticks.push(
      <line
        key={date.getTime()}
        x1={tickX}
        y1={0}
        x2={tickX}
        y2={y}
        className={styles.gridTick}
      />
    );

    if (date.getDay() == 6 || date.getDay() == 0) {
      weekendTicks.push(
        <rect
          x={tickX}
          y={0}
          width={columnWidth}
          height={y}
          fill="#f5f5f5"
        />
      );
    }

    if (
      (i + 1 !== dates.length &&
        date.getTime() < now.getTime() &&
        dates[i + 1].getTime() >= now.getTime()) ||
      // if current date is last
      (i !== 0 &&
        i + 1 === dates.length &&
        date.getTime() < now.getTime() &&
        addToDate(
          date,
          date.getTime() - dates[i - 1].getTime(),
          "millisecond"
        ).getTime() >= now.getTime())
    ) {
      let todayX = tickX + columnWidth / 2 - 1;

      const startX = tickX;
      const endX = tickX + columnWidth - 2;
      const diffX = endX - startX;

      const dayPercentage = (now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds() + now.getMilliseconds() / 1000) / 86400;
      const weekPercentage = now.getDay() / 6;

      const daysInCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
      const monthPercentage = now.getDate() / daysInCurrentMonth;

      // @ts-ignore
      const dayOfYear = Math.floor((now - new Date(now.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
      const daysInYear = ((now.getFullYear() % 4 === 0 && now.getFullYear() % 100 > 0) || now.getFullYear() % 400 == 0) ? 366 : 365;
      const yearPercentage = dayOfYear / daysInYear;

      switch (viewMode) {
        case ViewMode.Day:
          todayX = startX + diffX * dayPercentage;
          break;
        case ViewMode.Week:
          todayX = startX + diffX * weekPercentage;
          break;
        case ViewMode.Month:
          todayX = startX + diffX * monthPercentage;
          break;
        case ViewMode.Year:
          todayX = startX + diffX * yearPercentage;
          break;
      }


      today = (
        // <line
        //   x1={tickX}
        //   y1={0}
        //   x2={tickX}
        //   y2={y}
        //   fill={"rgba(255, 10, 10, 1.0)"}
        // />
        <rect
          x={todayX}
          y={0}
          width={2}
          height={y}
          fill="rgba(255, 10, 10, 1.0)"
        />
      );
    }
    // rtl for today
    if (
      rtl &&
      i + 1 !== dates.length &&
      date.getTime() >= now.getTime() &&
      dates[i + 1].getTime() < now.getTime()
    ) {
      today = (
        <rect
          x={tickX + columnWidth}
          y={0}
          width={columnWidth}
          height={y}
          fill={todayColor}
        />
      );
    }
    tickX += columnWidth;
  }
  return (
    <g className="gridBody">
      <g className="rows">{gridRows}</g>
      <g className="ticks">{weekendTicks}</g>
      <g className="rowLines">{rowLines}</g>
      <g className="ticks">{ticks}</g>
      <g className="today">{today}</g>
    </g>
  );
};
