/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Spinner from "../ui/spinner";
import Textarea from "@atlaskit/textarea";

export default function ExportModal({ slug, onClose }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [ data, setData ] = useState(null);
    const { t } = useTranslation();

    const submit = () => {
        run(apiClient(`export/spaces/${slug}`))
            .then(response => {
                setData(JSON.stringify(response));
            })
            .catch(() => {
                toast.error(t("error"));
            });
    };

    useEffect(() => submit(), []);

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_export_modal_heading")}
            isLoading={isPending}
            scrollBehavior="inside-wide"
        >
            {isCreatingError ? <SectionMessageWrapper>
                <SectionMessage title={t("admin_export_modal_error")} appearance="error">
                    <p>{error?.message}</p>
                </SectionMessage>
            </SectionMessageWrapper> : null}
            <br />
            {isPending && <Spinner />}
            {data && <Textarea value={data} isReadOnly={true} minimumRows={10} />}
            <br />
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;