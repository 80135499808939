/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import SelectModule from "../modules/select-module";
import TextArea from "@atlaskit/textarea";
import { useTranslation } from "react-i18next";

export default function EditCategory({ category, onClose, onSaved, module }) {
    const { t } = useTranslation();
    const { run, isPending, error, isError } = useAsync();
    const [ name, setName ] = useState(category.name);
    const [ icon, setIcon ] = useState(category.icon);
    const [ position, setPosition ] = useState(category.position ?? 0);
    const [ config, setConfig ] = useState(() => {
        try {
            return JSON.stringify(category.config ?? {}) ?? "";
        } catch (e) {
            return "";
        }
    });
    const [ selectedModule, setSelectedModule ] = useState(module?.id);

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit}>
                    {t("admin_edit_category_save")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_edit_category_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        const formData = {
            name,
            position,
            module_id: selectedModule,
            icon,
            config: JSON.parse(config),
        };

        run(apiClient(`categories/${category.slug}`, { method: "PATCH", data: formData}))
            .then(() => {
                onSaved();
                onClose();
                toast.success(t("admin_edit_category_success"));
            })
            .catch(e => {
                toast.error(t("admin_edit_category_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_edit_category_heading")}
            scrollBehavior="outside"
            components={{
                Footer: footer,
            }}
        >
            {isError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_edit_category_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="name" label={t("admin_edit_category_name")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={name} onChange={e => setName(e.target.value)} isInvalid={error?.errors?.name} autoComplete={false} />}
            </Field>
            <Field name="module" label={t("admin_edit_category_module")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <SelectModule value={selectedModule} onChange={e => setSelectedModule(e?.value)} />}
            </Field>
            <Field name="config" label={t("admin_edit_category_config")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextArea
                    {...fieldProps}
                    isMonospaced
                    value={config}
                    onChange={e => setConfig(e?.target?.value)}
                    minimumRows={5}
                />}
            </Field>
            <Field name="position" label={t("create_module_position")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={position} onChange={e => setPosition(e.target.value)} isInvalid={error?.errors?.position} autoComplete={false} />}
            </Field>
            <Field name="icon" label={t("create_module_icon")} isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={icon} onChange={e => setIcon(e.target.value)} isInvalid={error?.errors?.icon} autoComplete={false} />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;
