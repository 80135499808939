export default function documentResponse(response) {
    return {
        ...response,
        values: reduceValues(response.values),
        rawValues: response.values,
    };
}


function reduceValues(values) {
    return values.reduce((acc, value) => {
        acc[value.document_type_field_id] = value.value;
        return acc;
    }, {});
}