import slugify from "slugify";

export default function spacePath(space) {
    const moduleSlug = space?.category?.module?.slug ?? slugify(space?.category?.module?.name ?? "");
    const module = moduleSlug ?? space?.module?.name ?? space?.module ?? "";
    return `/${module}/${space?.category?.slug ?? slugify(space?.category?.name || "")}/${space?.slug}`.toLowerCase();
}

export function modulePath(module) {
    const moduleSlug = module?.slug ?? slugify(module?.name ?? "");
    return `/modules/${moduleSlug}`.toLowerCase();
}