import React, { useState } from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import useAsync from "../../hooks/use-async";

export default function RemoveDocumentModal({ document, onRemoved, onClose }) {
    const { run, isPending } = useAsync();
    const remove = () => {
        run(apiClient(`workflow/document/${document.id}`, { method: "DELETE"}))
            .then(() => {
                toast.success("Dokument został usunięty");
                onRemoved && onRemoved();
            })
            .catch(error => toast.error("Nie udało się usunąć dokumentu: " +  error.message));
    };

    return (
        <>
            <ModalTransition>
                <Modal
                    isLoading={isPending}
                    actions={[
                        { text: "Usuń", onClick: remove },
                        { text: "Anuluj", onClick: onClose },
                    ]}
                    onClose={close}
                    heading="Czy chcesz usunąć ten dokument?"
                    appearance="danger"
                >
                    <p>
                        Tej czynności nie można cofnąć.
                    </p>
                </Modal>
            </ModalTransition>
        </>
    );
}