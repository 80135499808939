import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Spinner from "../components/ui/spinner";
import { Link, Redirect, useLocation } from "react-router-dom";
import apiClient from "../api/business-client";
import { toast } from "react-toastify";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function OrderCallback() {
    const query = useQuery();
    const code = query.get("code");
    const uuid = query.get("state");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (!code) return;

        apiClient("order-callback?code=" + code + "&uuid=" + uuid, { method: "POST" })
            .then(response => {
                setSuccess(true);
            })
            .catch(error => {
                toast.error("Wystąpił błąd");
                setError(true);
            });
    }, [code]);

    if (!code) {
        return <Redirect to={`/p/${uuid}`} />;
    }

    if (error) {
        return <Redirect to={`/p/${uuid}`} />;
    }

    if (success) {
        return <Redirect to={`/p/${uuid}`} />;
    }

    return <>
        <Helmet>
            <title>...</title>
        </Helmet>
        <Spinner />
    </>;
}