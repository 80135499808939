import { useEffect } from "react";
import apiClient from "../api/api-client";
import useAsync from "../hooks/use-async";

export default function useNotifications(type, module = null, page = 1) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    const reload = () => {
        run(apiClient(`notifications?type=${type}&module=${module?.toLowerCase()}&page=${page}`))
            .catch(() => { });
    };

    useEffect(() => {
        reload();
    }, [ type, module, page ]);

    return {
        data: data?.data || [],
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        pagination: {
            total: data?.meta?.total,
            per_page: data?.meta?.per_page,
        },
        reload
    };
}