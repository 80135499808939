/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function CreateUser({ onClose, onAdded }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [ firstName, setFirstName ] = useState("");
    const [ lastName, setLastName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ passwordConfirmation, setPasswordConfirmation ] = useState("");
    const { t } = useTranslation();

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit}>
                    {t("admin_create_user_create")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_create_user_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        run(apiClient("user", { data: {
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            password_confirmation: passwordConfirmation,
        }}))
            .then(response => {
                onAdded(response);
                toast.success(t("admin_create_user_success"));
                onClose();
            })
            .catch(() => {
                toast.error(t("admin_create_user_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_create_user_heading")}
            scrollBehavior="inside-wide"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_create_user_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="first_name" label={t("admin_create_user_first_name")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={firstName} onChange={e => setFirstName(e.target.value)} isInvalid={error?.errors?.first_name} autoComplete={false} />}
            </Field>
            <Field id="last_name" name="last_name" label={t("admin_create_user_last_name")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={lastName} onChange={e => setLastName(e.target.value)} isInvalid={error?.errors?.last_name} autoComplete={false} />}
            </Field>
            <Field id="email" name="email" label={t("admin_create_user_email")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={email} onChange={e => setEmail(e.target.value)} isInvalid={error?.errors?.email} autoComplete={false} />}
            </Field>
            <Field id="password" name="password" label={t("admin_create_user_password")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} type="password" value={password} onChange={e => setPassword(e.target.value)} isInvalid={error?.errors?.password} autoComplete={false} />}
            </Field>
            <Field id="password_confirmation" name="password_confirmation" label={t("admin_create_user_password_confirmation")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} type="password" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} isInvalid={error?.errors?.password_confirmation} autoComplete={false} />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;