import MultiSelect from "@atlaskit/multi-select";
import React, { useEffect } from "react";
import Spinner from "../ui/spinner";
import { useTranslation } from "react-i18next";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";

export default function SelectOrganizations({ onChange, ids = [] }) {
	const { t } = useTranslation();
	const { run, isPending, data } = useAsync();

	useEffect(() => {
		reload();
	}, []);

	const reload = () => {
		run(apiClient("organizations"))
			.catch(() => {
				toast.error(t("admin_organizations_error"));
			});
	};

	const organizations = data?.data ?? [];

	if (isPending) {
		return <Spinner />;
	}

	return <MultiSelect
		isDisabled={isPending}
		placeholder={t("select_organizations_placeholder")}
		shouldFitContainer
		onSelectedChange={e => {
			onChange && onChange(e?.items);
		}}
		defaultSelected={organizations?.filter(o => ids?.includes(o?.id))?.map(o => ({
			content: o?.name,
			value: o?.id,
		})) || []}
		items={organizations?.map(o => ({
			content: o?.name,
			value: o?.id,
		}))} />;
}