import React, { useState } from "react";
import Spinner from "../../components/ui/spinner";
import useStructure from "../../hooks/use-structure";
import AdminLayout from "../../layouts/admin-layout";
import PageHeader from "@atlaskit/page-header";
import Group from "../../components/groups/group";
import AddGroupForm from "../../components/groups/add-group-form";
import AddItemIcon from "@atlaskit/icon/glyph/add-item";
import Button from "@atlaskit/button";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

function AdminStructure() {
    const { groups, reload, isPending } = useStructure();
    const [ addFormVisible, setAddFormVisible ] = useState(false);
    const { t } = useTranslation();

    if (isPending) return <AdminLayout><Spinner /></AdminLayout>;

    return <AdminLayout>
        <PageHeader>
            {t("admin_structure_heading")}
        </PageHeader>
        <Wrapper>
            <Left>
                {groups.filter(i => !i.parent_id).map(group => (
                    <Group group={group} groups={groups} key={group.id} onAdded={reload} />
                ))}
                {groups?.length === 0 && !addFormVisible && <ButtonWrapper>
                    <Button onClick={() => setAddFormVisible(true)} iconBefore={<AddItemIcon />} appearance="primary">
                        {t("admin_structure_add")}
                    </Button>
                </ButtonWrapper>}
                {groups?.length === 0 && addFormVisible && <AddGroupForm groupId={null} onAdded={reload} onHide={() => setAddFormVisible(false)} />}
            </Left>
        </Wrapper>
    </AdminLayout>;
}

export default AdminStructure;

const ButtonWrapper = styled.div`
    padding-top: 20px;
`;

const Wrapper = styled.div`
    display: flex;
`;

const Left = styled.div`
`;