import React, { useState, useEffect, createRef } from "react";
import DocumentLayout from "../layouts/document-layout";
import { Redirect, useParams } from "react-router-dom";
import Document from "../components/documents/document";
import Spinner from "../components/ui/spinner";
import useDocument from "../hooks/use-document";
import useSpace from "../hooks/use-space";
import { BreadcrumbsItem, BreadcrumbsStateless } from "@atlaskit/breadcrumbs";
import { Link } from "react-router-dom";
import spacePath, { categoryPath, modulePath } from "../utils/space-path";
import PageHeader from "@atlaskit/page-header";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import Form from "@atlaskit/form";
import useAsync from "../hooks/use-async";
import apiClient from "../api/api-client";
import prepareFormData from "../utils/prepare-form-data";
import { toast } from "react-toastify";
import LinkedDocuments from "../components/documents/linked-documents";
import styled from "styled-components";
import DocumentUsers from "../components/documents/document-users";
import DocumentComments from "../components/documents/document-comments";
import Dropzone from "../components/files/dropzone";
import FileItem from "../components/files/file-item";
import DocumentWorkflowsDropdown from "../components/documents/document-workflows-dropdown";
import { Helmet } from "react-helmet";
import RemoveDocumentModal from "../components/documents/remove-document-modal";
import documentName from "../utils/document-name";
import DocumentTable from "../components/documents/document-table";
import clonedeep from "lodash.clonedeep";
import { useAuth } from "../providers/auth-provider";
import toBoolean from "../utils/to-boolean";
import { useTranslation } from "react-i18next";
import useDocumentHistory from "../hooks/use-document-history";
import Pagination from "@atlaskit/pagination";
import ChevronLeftCircleIcon from "@atlaskit/icon/glyph/chevron-left-circle";
import ChevronRightCircleIcon from "@atlaskit/icon/glyph/chevron-right-circle";
import { useLocation } from "react-router-dom";
import SectionMessage, { SectionMessageAction } from "@atlaskit/section-message";
import title from "../title";
import DrawerLayout from "../layouts/drawer-layout";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import slugify from "slugify";
import { DocumentMenuTabs } from "../components/nav/sidebar";

function useHash() {
    return useLocation().hash?.slice(1);
}

function DocumentPage() {
    const { space: slug, id } = useParams();

    return <DocumentPageInner slug={slug} id={id} />;
}

export function DocumentPageInner({ slug, id, drawerLayout = false, onReload = null, showTabs = false, onDrawerClose }) {
    const { t } = useTranslation();
    const { space, forbidden, notFound } = useSpace(slug);
    const documentTypeId = space?.document_type?.id;
    const { isIdle, isPending, isError, document, fields, reload, error, forbidden: documentForbidden } = useDocument(id, documentTypeId, true, false, true);
    const editMode = document?.editable;
    const { run, isPending: isSaving } = useAsync();
    const [removed, setRemoved] = useState(false);
    const [showRemoved, setShowRemoved] = useState(false);
    const [additionalData, setAdditionalData] = useState({});
    const [documentsToLinkAfterSave, setDocumentsToLinkAfterSave] = useState([]);
    const [documentsToUnlinkAfterSave, setDocumentsToUnlinkAfterSave] = useState([]);
    const [documentsToUpdateAfterSave, setDocumentsToUpdateAfterSave] = useState([]);
    const { user } = useAuth();
    const currentUserFromDocument = document?.users?.find(i => i?.user_id === user?.id);
    const currentUserFromDocumentRole = currentUserFromDocument?.role;
    const [refreshCount, setRefreshCount] = useState(0);
    const commentsRef = createRef();
    const [type, setType] = useState("all");
    const [historyPage, setHistoryPage] = useState(1);
    const { isPending: isHistoryPending, data: history, reload: reloadHistory, pagination: historyPagination, isSuccess: isHistorySuccess } = useDocumentHistory(document?.id, type, () => {
        scrollToRefObject(commentsRef, 200);
    }, historyPage);
    const isWorkflowField = field => toBoolean(field?.workflow);
    const isWorkflowFieldEmpty = field => isWorkflowField(field) && !document?.values[field?.id];
    const isAnyWorkflowFields = fields?.some(f => isWorkflowField(f));
    const isAnyWorkflowEmptyFields = fields?.some(f => isWorkflowFieldEmpty(f));
    const currentSection = useHash();
    const isDocumentLinksView = document?.linkable?.some(l => decodeURI(currentSection) == l?.name);
    const [status, setStatus] = useState(document?.documentStatus);
    const [documentAlert, setDocumentAlert] = useState(null);
    const [currentSectionValue, setCurrentSectionValue] = useState("");

    useEffect(() => {
        const channelName = `document-status.${document?.id}`;
        Echo.channel(channelName)
            .listen(".Updated", (e) => {
                reload();
                setStatus(e);
            })
            .listen(".Removed", (e) => {
                reload();
                setStatus(undefined);
            });


        return () => Echo.leaveChannel(channelName);
    }, [document?.id, reload]);

    useEffect(() => {
        const channelName = `document-alert.${document?.id}`;
        Echo.channel(channelName)
            .listen(".Create", (e) => {
                setDocumentAlert(e);
                reload();
                setTimeout(() => {
                    setDocumentAlert(null);
                }, 5000);
            });

        return () => Echo.leaveChannel(channelName);
    }, [document?.id, reload]);


    useEffect(() => {
        if (isDocumentLinksView && rightPanel) {
            setRightPanel(false);
        }

        if (!isDocumentLinksView) {
            const value = localStorage.getItem("rightPanel.visible");
            setRightPanel(value === "true" || value === true);
        }
    }, [isDocumentLinksView]);

    const [rightPanel, setRightPanel] = useState(() => {
        const value = localStorage.getItem("rightPanel.visible");
        if (!value) return true;
        return value === "true" || value === true;
    });

    const toggle = () => {
        localStorage.setItem("rightPanel.visible", !rightPanel);
        setRightPanel(v => !v);
    };

    useEffect(() => {
        setStatus(document?.documentStatus);
    }, [document?.id]);


    useEffect(() => {
        if (editMode) return;
        if (!isAnyWorkflowFields) return;
        if (!isAnyWorkflowEmptyFields && refreshCount > 20) return;

        const interval = setInterval(() => {
            reload();
            setRefreshCount(c => c + 1);
        }, 3000);

        return () => clearInterval(interval);
    }, [editMode, refreshCount, isAnyWorkflowFields]);

    useEffect(() => {
        if (!editMode) return;
        fields?.filter(f => f?.field?.type === "multiselect").map(field => {
            const values = document?.rawValues?.filter(i => i?.document_type_field_id === field?.id) || [];
            const selected = values?.map(i => i?.value) || [];

            setAdditionalData(d => ({
                ...d,
                [field?.name]: selected
            }));
        });
        fields?.filter(f => f?.field?.type !== "multiselect")
            .map(field => {
                setAdditionalData(d => ({
                    ...d,
                    [field?.name]: document?.values[field?.id]
                }));
            });
    }, [editMode, document?.id]);

    if (forbidden || documentForbidden) {
        return <DocumentLayout>
            <h1>403</h1>
            <h3>{t("forbidden")}</h3>
        </DocumentLayout>;
    }

    if (notFound) {
        window.location.href = "/404";
        return null;
    }

    if (isError) {
        window.location.href = "/404";
        return null;
    }

    if ((!document && isPending || isIdle) && drawerLayout) {
        return <DrawerLayout>
            <Spinner />
        </DrawerLayout>;
    }

    if (!document && isPending || isIdle) {
        return <DocumentLayout>
            <Spinner />
        </DocumentLayout>;
    }

    let breadcrumbs = (
        <BreadcrumbsStateless>
            {space?.category?.module && <BreadcrumbsItem text={space.category.module?.alias} key="module" component={(props) => <Link to={modulePath(space?.category?.module)} {...props} />} />}
            {space?.category && <DisabledBreadcrumb>{space.category.name} <DisabledSlash>/</DisabledSlash></DisabledBreadcrumb>}
            {space && <BreadcrumbsItem text={space.name} key="space" component={(props) => <Link to={spacePath(space)} {...props} />} />}
        </BreadcrumbsStateless>
    );

    if (space?.hiddenMenu === true || space?.hiddenMenu === "true") {
        breadcrumbs = (
            <BreadcrumbsStateless>
                {space?.category?.module && <DisabledBreadcrumb>{space.category.module?.alias}<DisabledSlash>/</DisabledSlash></DisabledBreadcrumb>}
                {space?.category && <DisabledBreadcrumb>{space.category.name} <DisabledSlash>/</DisabledSlash></DisabledBreadcrumb>}
                {space && <DisabledBreadcrumb>{space.name}</DisabledBreadcrumb>}
            </BreadcrumbsStateless>
        );
    }

    const actionsContent =
        <ButtonGroup>
            <DocumentWorkflowsDropdown canRemove={document.deletable} document={document} onRemove={() => setShowRemoved(true)} onSuccess={() => {
                toast.success(t("document_action_success"));
                reload();
                setRefreshCount(0);
                // onDrawerClose && onDrawerClose();
            }} />
        </ButtonGroup>;

    const saveDocument = data => {
        const defaultFields = fields.reduce((acc, f) => {
            if (f?.field?.type === "checkbox") {
                acc[f.name] = false;
            }
            return acc;
        }, {});
        const dataToSave = fields.reduce((acc, f) => {
            if (toBoolean(f?.read_only) || toBoolean(f?.hidden)) {
                return acc;
            }

            acc[f?.name] = additionalData[f?.name];
            return acc;
        }, {});
        const allFormData = Object.assign({}, defaultFields, dataToSave);
        return run(apiClient(`workflow/document/${document.id}`, { method: "PATCH", data: allFormData }));
    };

    const saveNewLinks = () => {
        return Promise.all(documentsToLinkAfterSave?.map(doc => {
            const originalData = clonedeep(doc?.data);
            const originalDocuments = clonedeep(doc?.documents);
            const docData = clonedeep(doc?.data);
            delete docData["id"];
            delete docData["added"];

            doc?.documents?.forEach(d => {
                delete docData[d?.name];
            });

            return apiClient(`workflow/document-type/${doc?.document_type_id}/document`, { data: docData })
                .then(response => {
                    const responseId = response?.id;
                    return Promise.all([
                        run(apiClient(`document/${document?.id}/link/${responseId}/type/${doc?.link_type_id}`, { method: "POST" })),
                        ...originalDocuments?.map(documentToLink => {
                            const documentToLinkData = originalData[documentToLink?.name];
                            return run(apiClient(`document/${responseId}/link/${documentToLinkData?.data?.id}/type/${doc?.link_type_id}`, { method: "POST" }));
                        }),
                    ]);
                });
        }));
    };

    const removeLinks = () => {
        const ids = new Set(documentsToUnlinkAfterSave);
        return Promise.all(Array.from(ids)?.filter(i => !!i)?.map(linkId => {
            return run(apiClient(`document/${document?.id}/unlink/${linkId}`, { method: "DELETE" }));
        }));
    };

    const updateDocuments = () => {
        return Promise.all(
            documentsToUpdateAfterSave?.map(doc => {
                const data = { ...doc };
                delete data["id"];
                return run(apiClient(`workflow/document/${doc.id}`, { method: "PATCH", data: data }));
            })
        );
    };

    const name = document ? documentName(document) : "";
    const uri = `/${space?.category?.module?.slug}/${space?.category?.slug}/${space?.slug}`.toLowerCase();

    if (drawerLayout) {
        return <>
            <Helmet>
                <title>{`${name} - ${title}`}</title>
            </Helmet>
            <DrawerLayout document={document}>
                <Wrapper>
                    <Left>
                        {status && <GlobalStatus status={status} />}
                        {documentAlert && <DocumentAlert status={documentAlert} />}
                        <DocumentLink document={document} section={currentSectionValue} />
                        <PageHeader breadcrumbs={breadcrumbs} actions={actionsContent}>{name}</PageHeader>
                        {
                            showTabs && <DocumentMenuTabs document={document} uri={uri} currentSection={currentSectionValue} setCurrentSection={setCurrentSectionValue} />
                        }
                        <Document id={id} currentSection={currentSectionValue} document={document}
                            hideSectionTitles={showTabs}
                            fields={fields}
                            editMode={editMode}
                            reload={() => {
                                reload();
                                reloadHistory();
                                onReload && onReload();
                            }}
                            data={additionalData}
                            propagateFields={(propagatingField, valuesMap) => {
                                const propagatingFieldOptions = parseOptions(propagatingField?.options);
                                const documentTypeId = propagatingFieldOptions?.documentTypeId;

                                fields.map(f => {
                                    const fieldOptions = parseOptions(f?.options);

                                    if (fieldOptions?.document_type?.id == documentTypeId && fieldOptions?.document_type?.name === propagatingField?.name) {
                                        const fieldName = fieldOptions?.document_type?.field_name;
                                        const value = valuesMap[fieldName];

                                        setAdditionalData(d => ({
                                            ...d,
                                            [f?.name]: value
                                        }));
                                    }
                                });
                            }}
                            error={error} onChange={(field, data) => {
                                setAdditionalData(d => ({
                                    ...d,
                                    [field.name]: data
                                }));
                            }}
                            setDocumentsToLinkAfterSave={setDocumentsToLinkAfterSave}
                            setDocumentsToUpdateAfterSave={setDocumentsToUpdateAfterSave}
                            setDocumentsToUnlinkAfterSave={setDocumentsToUnlinkAfterSave} />
                        {document && document?.visible_sections?.links && currentSectionValue == "links" && <LinkedDocuments document={document} onRefresh={() => reload()} onDrawerClose={onDrawerClose} />}
                        {document && document?.visible_sections?.comments && currentSectionValue == "comments" && <>
                            <DocumentComments onAdded={() => reload()} document={document} data={history} isPending={isHistoryPending} commentsRef={commentsRef} type={type} setType={e => {
                                setType(e);
                                setHistoryPage(1);
                            }} reload={reloadHistory} />
                            {historyPagination && !(isHistorySuccess && history?.data?.length === 0) && historyPagination?.total && <PaginationWrapper>
                                <Pagination onChange={(e, p) => setHistoryPage(p)} selectedIndex={historyPage - 1}
                                    pages={Array.from(Array(Math.ceil(historyPagination?.total / historyPagination?.per_page)).keys(), (_, i) => i + 1)} />
                            </PaginationWrapper>}
                        </>}
                    </Left>
                    {(document?.visible_sections?.users || document?.visible_sections?.attachments) && <Right visible={rightPanel}>
                        <ToggleVisibility>
                            <ToggleBorder />
                            <ToggleButton visible={rightPanel} toggle={toggle} />
                        </ToggleVisibility>
                        <RightPanelVisibility visible={rightPanel}>
                            {document?.visible_sections?.users && <RightItem>
                                <DocumentUsers users={document?.users || []} documentTypeId={documentTypeId} documentId={document?.id} reload={reload} />
                            </RightItem>}
                            {document?.visible_sections?.attachments && <RightItem>
                                <Dropzone onDrop={() => { }} document={document} editMode={editMode} key={document?.id} />
                                {document?.files.map(f => (<FileItem documentId={document?.id} file={f} key={f.id} editMode={editMode} onRemoved={reload} />))}
                            </RightItem>}
                            <RightItem>
                                <h4>{t("document_metadata")}</h4>
                                <br />
                                <div>
                                    <strong>UUID: </strong> <small>{document?.uuid ?? "-"}</small>
                                </div>
                            </RightItem>
                        </RightPanelVisibility>
                    </Right>}
                </Wrapper>
                {showRemoved && <RemoveDocumentModal document={document} onRemoved={() => {
                    // setRemoved(true);
                    onDrawerClose && onDrawerClose();
                }} onClose={() => setShowRemoved(false)} />}
            </DrawerLayout>
        </>;
    }

    return <>
        <Helmet>
            <title>{`${name} - ${title}`}</title>
        </Helmet>
        <DocumentLayout document={document}>
            <Wrapper>
                <Left>
                    {status && <GlobalStatus status={status} />}
                    {documentAlert && <DocumentAlert status={documentAlert} />}
                    <PageHeader breadcrumbs={breadcrumbs} actions={actionsContent}>{name}</PageHeader>
                    <Document id={id} currentSection={currentSection} document={document}
                        fields={fields}
                        editMode={editMode}
                        reload={() => {
                            reload();
                            reloadHistory();
                        }}
                        data={additionalData}
                        propagateFields={(propagatingField, valuesMap) => {
                            const propagatingFieldOptions = parseOptions(propagatingField?.options);
                            const documentTypeId = propagatingFieldOptions?.documentTypeId;

                            fields.map(f => {
                                const fieldOptions = parseOptions(f?.options);

                                if (fieldOptions?.document_type?.id == documentTypeId && fieldOptions?.document_type?.name === propagatingField?.name) {
                                    const fieldName = fieldOptions?.document_type?.field_name;
                                    const value = valuesMap[fieldName];

                                    setAdditionalData(d => ({
                                        ...d,
                                        [f?.name]: value
                                    }));
                                }
                            });
                        }}
                        error={error} onChange={(field, data) => {
                            setAdditionalData(d => ({
                                ...d,
                                [field.name]: data
                            }));
                        }}
                        setDocumentsToLinkAfterSave={setDocumentsToLinkAfterSave}
                        setDocumentsToUpdateAfterSave={setDocumentsToUpdateAfterSave}
                        setDocumentsToUnlinkAfterSave={setDocumentsToUnlinkAfterSave} />
                    {document && document?.visible_sections?.links && currentSection == "links" && <LinkedDocuments document={document} onRefresh={() => reload()} onDrawerClose={onDrawerClose} />}
                    {document && document?.visible_sections?.comments && currentSection == "comments" && <>
                        <DocumentComments onAdded={() => reload()} document={document} data={history} isPending={isHistoryPending} commentsRef={commentsRef} type={type} setType={e => {
                            setType(e);
                            setHistoryPage(1);
                        }} reload={reloadHistory} />
                        {historyPagination && !(isHistorySuccess && history?.data?.length === 0) && historyPagination?.total && <PaginationWrapper>
                            <Pagination onChange={(e, p) => setHistoryPage(p)} selectedIndex={historyPage - 1}
                                pages={Array.from(Array(Math.ceil(historyPagination?.total / historyPagination?.per_page)).keys(), (_, i) => i + 1)} />
                        </PaginationWrapper>}
                    </>}
                </Left>
                {(document?.visible_sections?.users || document?.visible_sections?.attachments) && <Right visible={rightPanel}>
                    <ToggleVisibility>
                        <ToggleBorder />
                        <ToggleButton visible={rightPanel} toggle={toggle} />
                    </ToggleVisibility>
                    <RightPanelVisibility visible={rightPanel}>
                        {document?.visible_sections?.users && <RightItem>
                            <DocumentUsers users={document?.users || []} documentTypeId={documentTypeId} documentId={document?.id} reload={reload} />
                        </RightItem>}
                        {document?.visible_sections?.attachments && <RightItem>
                            <Dropzone onDrop={() => { }} document={document} editMode={editMode} key={document?.id} />
                            {document?.files.map(f => (<FileItem documentId={document?.id} file={f} key={f.id} editMode={editMode} onRemoved={reload} />))}
                        </RightItem>}
                        <RightItem>
                            <h4>{t("document_metadata")}</h4>
                            <br />
                            <div>
                                <strong>UUID: </strong> <small>{document?.uuid ?? "-"}</small>
                            </div>
                        </RightItem>
                    </RightPanelVisibility>
                </Right>}
            </Wrapper>
            {showRemoved && <RemoveDocumentModal document={document} onRemoved={() => {
                onDrawerClose && onDrawerClose();

                const baseDocuments = document?.links?.filter(l => l?.link_type_id === 5 || l?.link_type_id === "5")?.map(l => l?.linked_document) ?? [];

                if (baseDocuments?.length > 0) {
                    const bd = baseDocuments[0];

                    const bdSpace = bd?.document_type?.space;
                    const bdCategory = bdSpace?.category;
                    const bdModule = bdCategory?.module;
                    const dSpace = document?.document_type?.space;
                    const dCategory = dSpace?.category;
                    const dModule = dCategory?.module;

                    const uri = `/${bdModule?.slug}/${bdCategory?.slug}/${bdSpace?.slug}/${bd?.id}#${encodeURIComponent(dModule?.name)}/${encodeURIComponent(dCategory?.name)}/${encodeURIComponent(dSpace?.name)}`;

                    window.location.href = uri;
                    return;
                }

                window.location.href = spacePath(space);

            }} onClose={() => setShowRemoved(false)} />}
        </DocumentLayout>
    </>;
}

function parseOptions(opt) {
    const optString = opt?.[0] || "{}";

    try {
        return JSON.parse(optString);
    } catch (e) {
        return {};
    }
}

export default DocumentPage;

const Wrapper = styled.div`
@media (min-width: 1300px) {
    display: flex;
}
`;

const Left = styled.div`
@media (min-width: 1300px) {
    width: 100%;
}
`;

const Right = styled.div`
position: relative;
@media (min-width: 1300px) {
    width: ${props => props.visible ? "40%" : "0%"};
    padding: ${props => props.visible ? "50px" : "20px"};;
}
@media (max-width: 1299px) {
    margin-top: 50px;
}
    min-width: ${props => props.visible ? "250px" : "0px"};
    min-height: 100%;
`;

const RightItem = styled.div`
    margin-bottom: 30px;
`;

const PaginationWrapper = styled.div`
    padding: 20px;
`;

const HeaderButtonWrapper = styled.div`
    margin-left: 10px;
`;

function ToggleButton({ visible, toggle }) {
    return <ToggleButtonWrapper onClick={toggle}>
        {!visible
            ? <ChevronLeftCircleIcon size={32} primaryColor="white" secondaryColor="black" />
            : <ChevronRightCircleIcon size={32} primaryColor="white" secondaryColor="black" />}
    </ToggleButtonWrapper>;
}

const ToggleButtonWrapper = styled.div`
    width: 24px;
    height: 24px;
    position: absolute;
    left: 10px;
    top: 90px;
    z-index: 202;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 16px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ToggleBorder = styled.div`
    width: 1px;
    height: 100%;
    background: #eee;
    position: absolute;
    left: 20px;
    top: 0px;
    z-index: 190;
`;

const RightPanelVisibility = styled.div`
@media (min-width: 1300px) {
    display: ${props => props.visible ? "block" : "none"};
}
`;

const ToggleVisibility = styled.div`
@media (max-width: 1299px) {
visibility: hidden;
}
`;


function GlobalStatus({ status: { content, type } }) {
    return <GlobalStatusWrapper>
        <SectionMessage appearance={type}>
            <p>{content}</p>
        </SectionMessage>
    </GlobalStatusWrapper>;
}

const GlobalStatusWrapper = styled.div`
margin-top: 5px;
`;

function DocumentAlert({ status: { content, type } }) {
    return <GlobalStatusWrapper>
        <SectionMessage appearance={type}>
            <p>{content}</p>
        </SectionMessage>
    </GlobalStatusWrapper>;
}


function DocumentLink({ document, section = "" }) {
    const space = document?.document_type?.space;
    if (!space) return null;
    const nameField = document?.document_type?.fields?.find(i => i.name === "name");
    const name = document?.values[nameField.id];
    if (!name) return null;
    const path = `${slugify(space?.category?.module?.name)}/${space?.category?.slug ?? slugify(space?.category?.name || "")}/${space?.slug}`.toLowerCase();
    return <Flex>
        <DocumentLinkWrapper>
            <Link to={`/${path}/${document.id}#${section}`}>
                <IconWrapper>
                    <OpenInFull />
                </IconWrapper>
            </Link>
        </DocumentLinkWrapper>
        <DocumentLinkWrapper>
            <Link to={`/${path}/${document.id}#${section}`}>
                <DocumentLinkRow>
                    <DocumentLinkIcon>
                        <Page16Icon />
                    </DocumentLinkIcon>

                    <Category>
                        {space?.category?.module?.alias}/
                        {space?.category?.name}/
                        {space?.name}
                    </Category>
                    {name}
                </DocumentLinkRow>
            </Link>
        </DocumentLinkWrapper>
    </Flex>;
}

const OpenInFull = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
        <g id="surface1">
            <path d="M 14 7.332031 L 14 2 L 8.667969 2 L 10.859375 4.191406 L 4.191406 10.859375 L 2 8.667969 L 2 14 L 7.332031 14 L 5.140625 11.808594 L 11.808594 5.140625 Z M 14 7.332031 " />
        </g>
    </svg>;
};

const IconWrapper = styled.div`
    margin-top: 2px;
`;



const DocumentLinkWrapper = styled.div`
    font-size: 14px;
    margin-left: 24px;
`;

const DocumentLinkRow = styled.div`
    display: flex;
    align-items: center;
    margin-top: -3px;
`;

const DocumentLinkIcon = styled.div`
    width: 16px;
`;


const Category = styled.span`
color: rgb(137, 147, 164);
text-decoration: none;
outline: currentcolor none medium;
margin-left: 8px;
color: rgb(137, 147, 164);
font-weight: 600;
font-size: 12px;
text-decoration: none;
white-space: nowrap;
margin-right: 10px;
`;

export const DisabledBreadcrumb = styled.div`
display: flex;
-webkit-flex-direction: row;
-ms-flex-direction: row;
flex-direction: row;
height: 1.7142857142857142em;
line-height: 1.7142857142857142em; 
padding: 0;
box-sizing: border-box;
max-width: 100%;
`;

export const DisabledSlash = styled.div`
flex-shrink: 0;
padding: 0 8px;
text-align: center;
width: 8px;
`;

const Flex = styled.div`
    display: flex; 
    align-items: center;  
`;