import useAsync from "./use-async";
import apiClient from "../api/api-client";
import { useEffect } from "react";

export default function useDashboardDocuments(module = null, page = 1) {
    const { run, isPending, data } = useAsync();

    useEffect(() => {
        reload(page);
    }, [ page, module ]);   

    const reload = (p = 1) => {
        run(apiClient(`dashboard?module=${module?.toLowerCase()}&page=${p}`)).catch(() => {});
    };

    return {
        isPending,
        data: {
            ...data,
            data: data?.data?.map(d => {
                const name = d?.values?.find(i => i.name === "name")?.value;
                const description = d?.values?.find(i => i.name === "description")?.value;
    
                return {
                    ...d,
                    name,
                    description,
                };
            }),
        },
        reload,
    };
}