import React, { useState } from "react";
import useNotifications from "../../hooks/use-notifications";
import Spinner from "../ui/spinner";
import Avatar from "@atlaskit/avatar";
import Comment, {
    CommentAction,
    CommentAuthor,
    CommentEdited,
    CommentTime,
} from "@atlaskit/comment";
import { Link } from "react-router-dom";
import Pagination from "@atlaskit/pagination";
import styled from "styled-components";
import slugify from "slugify";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";

function WatchingNotifications({ module = null, highlight = true }) {
    const [ page, setPage ] = useState(1); 
    const { data, isPending, pagination } = useNotifications("watching", module, page);

    if (isPending) return <Spinner />;
    
    return <>
        {data.map(notification => { 
            return (
                <Notification notification={notification} key={notification.id} />
            );
        })}
        {pagination && <PaginationWrapper>
            <Pagination onChange={(e, p) => setPage(p)} selectedIndex={page - 1}
                pages={Array.from(Array(Math.ceil(pagination?.total/pagination?.per_page)).keys(), (_, i) => i+1)} />
        </PaginationWrapper>}
    </>;
}

function Notification({ notification }) {
    return <Comment
        css={{ paddingTop: 0 }}
        highlighted={!notification.read_at}
        avatar={<NotificationLink notification={notification}>
            <Avatar src={notification?.from_user?.avatar_url} size="medium" />
        </NotificationLink>}
        author={<NotificationLink notification={notification}>
            <CommentAuthor>{notification?.from_user?.first_name + " " + notification?.from_user?.last_name}</CommentAuthor>
        </NotificationLink>}
        time={<NotificationLink notification={notification}>
            <CommentTime>{(new Date(notification?.created_at)).toLocaleString()}</CommentTime>
        </NotificationLink>}
        content={<NotificationLink notification={notification}>
            <NotificationMessage notification={notification} />
        </NotificationLink>}
        afterContent={notification?.document && <NotificationLink notification={notification}>
            <DocumentDetails document={notification?.document} />
        </NotificationLink>}
    />;
}

function NotificationMessage({ notification }) {
    return <p dangerouslySetInnerHTML={{ __html: formatNotification(notification) }} />;
}

function NotificationLink({ notification, children }) {
    const document = notification?.document;

    if (document) {
        const { module, space, category, id } = document;
        return <Link to={`/${category?.module?.name}/${category?.slug ?? slugify(category?.name)}/${space.slug}/${id}`.toLowerCase()} key={notification.id} style={{ color: "#172B4D" }}>
            {children}
        </Link>;
    }

    return children;
}

function formatNotification(notification) {
    let message = notification?.message || "";

    if (notification.document) {
        const name = notification.document?.values?.find(i => i.name === "name")?.value;
        message = message.replaceAll("{name}", "<strong>" + name + "</strong>");
    }
    
    return message;
}

export default WatchingNotifications;

const PaginationWrapper = styled.div`
    padding: 20px;
`;

function DocumentDetails({document}) {
    const name = document?.values?.find(i => i.name === "name")?.value;

    return <DocumentWrapper>
        <DocumentDetailsIcon>
            <Page16Icon />
        </DocumentDetailsIcon>
        <DocumentDetailsName>
            {name}
        </DocumentDetailsName>
    </DocumentWrapper>;
}

const DocumentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DocumentDetailsIcon = styled.div`
    padding-top: 4px;
`;

const DocumentDetailsName = styled.div`
    flex-grow: 1;
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;