import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import DocumentLayout from "../layouts/document-layout";
import styled from "styled-components";
import DashboardDocuments from "../components/dashboard/dashboard-documents";
import DashboardNotifications from "../components/dashboard/dashboard-notifications";
import Calendar from "../components/scheduler/calendar";
import Widgets from "../components/widgets/widgets";
import useModules from "../hooks/use-modules";
import { useTranslation } from "react-i18next";
import Spinner from "../components/ui/spinner";
import title from "../title";
import GanttView from "../components/gantt/gantt-view";
import { useAuth } from "../providers/auth-provider";
import { Link, Redirect, useLocation } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard-layout";
import { LinkItem, LinkItemProps } from "@atlaskit/menu";
import PeopleIcon from "@atlaskit/icon/glyph/people";
import ListIcon from "@atlaskit/icon/glyph/list";
import OfficeBuildingIcon from "@atlaskit/icon/glyph/office-building";
import { Module } from ".";
import UserLayout from "../layouts/user-layout";
import Tabs from "@atlaskit/tabs";
import Form, { Field } from "@atlaskit/form";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import TextField from "@atlaskit/textfield";
import PageHeader from "@atlaskit/page-header";
import { toast } from "react-toastify";
import apiClient from "../api/api-client";
import useAsync from "../hooks/use-async";
import Table, {
    Cell,
    HeadCell,
    Row,
    SortableColumn,
    TBody,
    THead,
} from "@atlaskit/table";
import {
    Section,
    ButtonItem,
    SideNavigation,
} from "@atlaskit/side-navigation";
import DynamicTable from "@atlaskit/dynamic-table";
import Select from "@atlaskit/select";
import IconWidget from "../components/widgets/icon-widget";
import InviteToOrganization from "../components/organizations/invite-to-organization";
import DeleteUser from "../components/organizations/delete-user";
import DeleteInvite from "../components/organizations/delete-invite";

function OrganizationPage() {
    const { organization } = useParams();
    const { user } = useAuth();
    const { t } = useTranslation();
    const [tab, setTab] = useState("details");

    const currentOrganization = user?.organizations?.find(o => parseInt(o?.id) == parseInt(organization));
    const userOrganizations = user?.organizations;
    const currentUserType = currentOrganization?.pivot?.type;
    const adminable = currentUserType == "admin";

    if (currentOrganization?.id != user?.organization?.id) {
        return <Redirect to="/" />;
    }


    if (!currentOrganization) {
        return <Redirect to="/" />;
    }

    if (!adminable) {
        return <Redirect to="/" />;
    }

    const tabs = [
        { label: t("organization_link_basic"), content: <Details organization={currentOrganization} /> },
        { label: t("organization_link_modules"), content: <Modules organization={currentOrganization} /> },
        { label: t("organization_link_members"), content: <Members organization={currentOrganization} /> },
    ];

    return <>
        <Helmet>
            <title>{`${currentOrganization?.name?.toUpperCase()} - ${title}`}</title>
        </Helmet>
        <UserLayout>
            <Wrapper>

                <Flex>
                    <IconWrapper>
                        <IconWidget name={"apartment"} size="20px" />
                    </IconWrapper>
                    <Header>
                        {currentOrganization?.name?.toUpperCase()}
                    </Header>
                </Flex>


                <Tabs tabs={tabs} />
            </Wrapper>
        </UserLayout>
    </>;


    // return <>
    //     <Helmet>
    //         <title>{`${currentOrganization?.name?.toUpperCase()} - ${title}`}</title>
    //     </Helmet>
    //     <DashboardLayout>
    //         <Header>{`${currentOrganization?.name?.toUpperCase()}`}</Header>
    //         <Row>
    //             <Left>
    //                 <div>
    //                     <LinkItem isSelected={tab == "details"} onClick={() => setTab("details")} iconBefore={<OfficeBuildingIcon />}>
    //                         {t("organization_link_basic")}
    //                     </LinkItem>
    //                     <LinkItem isSelected={tab == "modules"} onClick={() => setTab("modules")} iconBefore={<ListIcon />}>
    //                         {t("organization_link_modules")}
    //                     </LinkItem>
    //                     <LinkItem isSelected={tab == "members"} onClick={() => setTab("members")} iconBefore={<PeopleIcon />}>
    //                         {t("organization_link_members")}
    //                     </LinkItem>
    //                 </div>
    //             </Left>
    //             <Right>
    //                 {tab == "details" && <Details organization={currentOrganization} />}
    //             </Right>
    //         </Row>
    //     </DashboardLayout>
    // </>;
}

export default OrganizationPage;

function Details({ organization }) {
    const [pending, setPending] = useState(false);
    const [name, setName] = useState(() => organization?.name);
    const { t } = useTranslation();
    const { reload } = useAuth();

    const actionsContent = <ButtonGroup>
        <Button appearance="primary" type="submit" isLoading={pending} isDisabled={!name}>
            {t("profile_basic_save")}
        </Button>
    </ButtonGroup>;

    const handleSubmit = (e) => {
        e.preventDefault();
        setPending(true);
        apiClient(`adminableOrganizations/${organization?.id}`, {
            method: "PATCH",
            data: {
                name: name,
            }
        })
            .then(() => {
                toast.success(t("organization_save_success"));
                reload();
            })
            .catch(() => toast.error(t("organization_save_error")))
            .finally(() => setPending(false));
    };

    return <DetailsWrapper>
        <Form>
            {({ formProps }) => (
                <form {...formProps} onSubmit={handleSubmit}>
                    <PageHeader actions={actionsContent}>
                        {t("organization_details")}
                    </PageHeader>
                    <Field label={t("admin_create_organization_name")} name="name">
                        {({ fieldProps }) => <TextField {...fieldProps} name="name" value={name} onChange={e => setName(e.target.value)} />}
                    </Field>
                </form>
            )}
        </Form>
    </DetailsWrapper>;
}

function Members({ organization }) {
    const { user } = useAuth();
    const { t } = useTranslation();
    const { data: membersData, isPending: membersIsPending, run: membersRun } = useAsync();
    const { data: invitesData, isPending: invitesIsPending, run: invitesRun } = useAsync();
    const { data: rolesData, isPending: rolesIsPending, run: rolesRun } = useAsync();
    const [loaded, setLoaded] = useState(false);
    const [userToBeRemoved, setUserToBeRemoved] = useState(null);
    const [inviteToBeRemoved, setInviteToBeRemoved] = useState(null);
    const [showInvite, setShowInvite] = useState(false);

    const reload = () => {
        Promise.all([
            membersRun(apiClient(`adminableOrganizations/${organization?.id}/users`)).catch(() => { }),
            invitesRun(apiClient(`adminableOrganizations/${organization?.id}/invites`)).catch(() => { }),
            rolesRun(apiClient(`adminableOrganizations/${organization?.id}/roles`)).catch(() => { }),
        ]);
    };

    useEffect(() => {
        setLoaded(true);
        reload();
    }, []);

    if (!loaded && (membersIsPending || invitesIsPending)) {
        return <Spinner />;
    }

    const deleteUser = (user) => {
        setUserToBeRemoved(user);
    };

    const deleteInvite = (invite) => {
        setInviteToBeRemoved(invite);
    };

    const membersHead = {
        cells: [
            { key: "name", content: t("dashboard_column_name"), isSortable: false, width: 100 },
            { key: "email", content: t("admin_users_column_email"), isSortable: false },
            { key: "type", content: t("organization_members_type"), isSortable: false, width: 100 },
            { key: "roles", content: t("organization_members_roles"), isSortable: false, width: 100 },
            { key: "actions", content: "", isSortable: false, width: 100 },
        ]
    };

    const invitesHead = {
        cells: [
            { key: "email", content: t("admin_users_column_email"), isSortable: false, width: 100 },
            { key: "spacer", content: "", isSortable: false, width: 2000 },
            { key: "actions", content: "", isSortable: false, width: 100 },
        ]
    };

    const userTypeOptions = [
        { label: "Użytkownik", value: "user" },
        { label: "Administrator", value: "admin" },
    ];

    const rolesOptions = rolesData?.data?.map(role => ({
        label: role?.name,
        value: role?.id,
    })) ?? [];

    const membersRows = membersData?.data?.map(member => ({
        key: member.id,
        cells: [
            { key: "name", content: <div>{member?.name}</div> },
            { key: "email", content: <div>{member?.email}</div> },
            {
                key: "type", content: <FullWidth>
                    <Select
                        isDisabled={member?.id == user?.id}
                        value={userTypeOptions.find(i => i.value == member?.type)}
                        options={userTypeOptions}
                        onChange={e => {
                            const newRole = e.value;
                            apiClient(`adminableOrganizations/${organization?.id}/users/${member?.id}/role`, {
                                method: "POST",
                                data: {
                                    role: newRole,
                                }
                            })
                                .then(() => {
                                    toast.success(t("organization_members_type_updated"));
                                    reload();
                                }).catch(() => {
                                    toast.error(t("organization_members_type_updated_error"));
                                });
                        }}
                    />
                </FullWidth>
            }, {
                key: "roles", content: <FullWidth>
                    <Select
                        value={rolesOptions.filter(i => member?.roles?.map(r => r?.id)?.includes(i?.value))}
                        options={rolesOptions}
                        isMulti
                        onChange={e => {
                            const newRoles = e?.map(i => i?.value);
                            apiClient(`adminableOrganizations/${organization?.id}/users/${member?.id}/roles`, {
                                method: "POST",
                                data: {
                                    roles: newRoles,
                                }
                            })
                                .then(() => {
                                    toast.success(t("organization_members_role_updated"));
                                    reload();
                                }).catch(() => {
                                    toast.error(t("organization_members_role_updated_error"));
                                });
                        }}
                    />
                </FullWidth>
            },
            {
                key: "actions", content:
                    <div>
                        <Button appearance="danger" onClick={() => deleteUser(member)}
                            isDisabled={member?.id == user?.id}>
                            {t("organization_member_delete")}
                        </Button>
                    </div>
            }
        ]
    })) ?? [];

    const invitesRows = invitesData?.data?.map(invite => ({
        key: invite.id,
        cells: [
            { key: "email", content: <div>{invite?.email}</div> },
            { key: "spacer", content: <div></div> },
            {
                key: "actions", content:
                    <div>
                        <Button appearance="danger" onClick={() => deleteInvite(invite)}
                            isDisabled={invite?.email == user?.email}>
                            {t("organization_invite_delete")}
                        </Button>
                    </div>
            }
        ]
    })) ?? [];

    const actionsContent = <ButtonGroup>
        <Button appearance="primary" type="submit" onClick={() => setShowInvite(true)}>
            {t("organization_members_invite")}
        </Button>
    </ButtonGroup>;

    return <FullWidth>
        <PageHeader actions={actionsContent}>
            {t("organization_link_members")}
        </PageHeader>
        <FullWidth>
            <MembersWrapper>
                <DynamicTable
                    head={membersHead}
                    rows={membersRows}
                    loadingSpinnerSize="large"
                    emptyView={<div></div>}
                />
            </MembersWrapper>
        </FullWidth>
        <PageHeader>
            {t("organization_link_invites")}
        </PageHeader>
        <FullWidth>
            <MembersWrapper>
                <DynamicTable
                    head={invitesHead}
                    rows={invitesRows}
                    loadingSpinnerSize="large"
                    emptyView={<div>{t("dashboard_invites_empty")}</div>}
                />
            </MembersWrapper>
        </FullWidth>

        {showInvite && <InviteToOrganization organizationId={organization?.id} onClose={() => {
            setShowInvite(false);
        }} onSuccess={() => {
            setShowInvite(false);
            reload();
        }} />}

        {userToBeRemoved && <DeleteUser
            user={userToBeRemoved}
            organizationId={organization?.id}
            onClose={() => setUserToBeRemoved(null)}
            onRemoved={() => {
                reload();
                setUserToBeRemoved(null);
            }}
        />}

        {inviteToBeRemoved && <DeleteInvite
            invite={inviteToBeRemoved}
            organizationId={organization?.id}
            onClose={() => setInviteToBeRemoved(null)}
            onRemoved={() => {
                reload();
                setInviteToBeRemoved(null);
            }}
        />}
    </FullWidth>;
}

function Modules({ organization }) {
    const { t } = useTranslation();
    const { data, isPending, run } = useAsync();
    const { data: modulesData, isPending: modulesIsPending, run: modulesRun } = useAsync();
    const [isEnabling, setIsEnabling] = useState(null);
    const [isDisabling, setIsDisabling] = useState(null);

    const reload = () => {
        run(
            apiClient(`adminableOrganizations/${organization?.id}/modules`)
                .finally(() => {
                    setIsEnabling(null);
                    setIsDisabling(null);
                })
        )
            .catch(() => { });
    };

    useEffect(() => {
        reload();
    }, []);

    useEffect(() => {
        modulesRun(
            apiClient(`adminableOrganizations/${organization?.id}/allModules`)
        )
            .catch(() => { });
    }, []);

    const enabledModules = data?.data ?? [];
    const enabledModulesIds = enabledModules?.map(i => i?.id) ?? [];
    const modules = modulesData?.data ?? [];

    const enable = (module) => {
        setIsEnabling(module?.id);
        apiClient(`adminableOrganizations/${organization?.id}/modules/${module?.id}/enable`, {
            method: "POST"
        }).finally(() => {
            reload();
        });
    };

    const disable = (module) => {
        setIsDisabling(module?.id);
        apiClient(`adminableOrganizations/${organization?.id}/modules/${module?.id}/disable`, {
            method: "POST"
        }).finally(() => {
            reload();
        });
    };

    if (modulesIsPending) {
        return <Spinner />;
    }

    const head = {
        cells: [
            { key: "name", content: t("dashboard_column_name"), isSortable: false, width: 1000, flex: 1, grow: true },
            { key: "enable", content: "", isSortable: false, width: 100 },
        ]
    };

    const rows = modules?.map(module => ({
        key: module.id,
        cells: [
            { key: "name", content: <div>{module?.alias ?? module?.name}</div> },
            {
                key: "enable", content:
                    <div>
                        {enabledModulesIds?.includes(module?.id) && <div>
                            <Button appearance="danger" onClick={() => disable(module)} isDisabled={isDisabling == module?.id}>
                                {t("organization_disable")}
                            </Button>
                        </div>}
                        {!enabledModulesIds?.includes(module?.id) && <div>
                            <Button appearance="subtle" onClick={() => enable(module)} isDisabled={isEnabling == module?.id}>
                                {t("organization_enable")}
                            </Button>
                        </div>}
                    </div>
            }
        ]
    }));

    return <FullWidth>
        <PageHeader>
            {t("organization_modules")}
        </PageHeader>
        <FullWidth>
            <DynamicTable
                head={head}
                rows={rows}
                styl
                loadingSpinnerSize="large"
                emptyView={<div></div>}
            />
        </FullWidth>
    </FullWidth>;
}

const Flex = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    justify-items: center;
`;


const Left = styled.div`
        @media (min-width: 1000px) {
            flex: 1;
}
        `;

const Right = styled.div`
        @media (min-width: 1000px) {
            flex: 3;
}
        `;

const Wrapper = styled.div`
        `;

const MembersWrapper = styled.div`
margin-bottom: 50px;
        `;

const FullWidth = styled.div`
        width: 100%;
        min-width: 200px;
        overflow: visible;
        `;

const Header = styled.h1`
        margin: 0;
        `;

const DetailsWrapper = styled.div`
        width: 100%;
        `;

const IconWrapper = styled.div`
        color: white;
        background-color: #2684ff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        padding: 2px;
        width: 20px;
        height: 20px;
        `;