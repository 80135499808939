import React, { useState } from "react";
import styled from "styled-components";
import Form from "@atlaskit/form";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import PageHeader from "@atlaskit/page-header";
import { useAuth } from "../../providers/auth-provider";
import Spinner from "../ui/spinner";
import { Checkbox } from "@atlaskit/checkbox";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function NotificationSettings() {
    const { user } = useAuth();
    const { t } = useTranslation();

    if (!user) return <Spinner />;

    const [ emails, setEmails ] = useState(() => Boolean(user?.config?.want_to_receive_emails));
    const [ msTeams, setMsTeams ] = useState(() => Boolean(user?.config?.want_to_receive_ms_teams));
    const [ pending, setPending ] = useState(false);

    const actionsContent = <ButtonGroup>
        <Button appearance="primary" type="submit" isLoading={pending}>
            {t("profile_notifications_save")}
        </Button>
    </ButtonGroup>;

    const  handleSubmit = (e) => {
        e.preventDefault();
        setPending(true);
        apiClient("user/profile/config", {
            method: "PATCH",
            data: {
                want_to_receive_emails: emails,
                want_to_receive_ms_teams: msTeams,
            }
        })
            .then(() => toast.success(t("profile_notifications_success")))
            .catch(() => toast.error(t("profile_notifications_error")))
            .finally(() => setPending(false));
    };

    return <Wrapper>
        <Form>
            {({ formProps }) => (
                <form {...formProps} onSubmit={handleSubmit}>
                    <PageHeader actions={actionsContent}>
                        {t("profile_notifications")} 
                    </PageHeader>
                    <Field>
                        <Checkbox label={t("profile_notifications_email")} isChecked={emails} onChange={() => setEmails(v => !v)} isDisabled={pending} />
                    </Field>
                    <Field>
                        <Checkbox label={t("profile_notifications_teams")} isChecked={msTeams} onChange={() => setMsTeams(v => !v)} isDisabled={pending} />
                    </Field>
                </form>
            )}
        </Form>
    </Wrapper>;
}

export default NotificationSettings;

const Wrapper = styled.div`
width: 100%;
`;

const Field = styled.div`
    padding: 10px 0;
    cursor: pointer;
`;