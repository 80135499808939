import { useEffect, useState } from "react";
import apiClient from "../api/api-client";
import availableDocumentTypesResponse from "../api/available-document-types-response";
import useAsync from "../hooks/use-async";

export default function useAvailableDocumentTypes(linkTypeId) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        if (!linkTypeId) return;
        setLoaded(false);

        run(apiClient(`document-type/${linkTypeId}/linkable`))
            .then(() => setLoaded(true))
            .catch(() => { });
    }, [linkTypeId]);

    return {
        data: data ? availableDocumentTypesResponse(data) : null,
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        isLoaded: loaded
    };
}