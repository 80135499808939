import React from "react";
import styled from "styled-components";
import Spinner from "@atlaskit/spinner";

function FullPageSpinner() {
    return <Wrapper data-testid="full-page-spinner">
        <Spinner size="large" />
    </Wrapper>;
}

export default FullPageSpinner;

const Wrapper = styled.div`
    width: 100%;
    min-height: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;