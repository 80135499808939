import React from "react";
import styled from "styled-components";
import Spinner from "../ui/spinner";
import useWidget from "../../hooks/use-widget";

function TextWidget({ type, children, ...props }) {
    switch (type) {
    case "h1":
        return <h1>{children}</h1>;
    case "h2":
        return <h2>{children}</h2>;
    case "h3":
        return <h3>{children}</h3>;
    case "h4":
        return <h4>{children}</h4>;
    case "h5":
        return <h5>{children}</h5>;
    case "h6":
        return <h6>{children}</h6>;
    case "strong":
        return <strong>{children}</strong>;
    case "a":
        return <p>
            <a href={props.href}>{children}</a>
        </p>;
    default:
        return <p>{children}</p>;
    }
}

export default TextWidget;

const Wrapper = styled.div`
`;