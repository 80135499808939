import slugify from "slugify";

export default function modulesResponse(response) {
    return Object.keys(response)
        ?.filter(i => i.toLowerCase() !== "core")
        ?.map(moduleSlug => {
            const module = response[moduleSlug];
            return {
                id: module?.slug,
                slug: module?.slug,
                name: module?.alias,
                icon: module?.icon,
                position: module?.position,
                config: module?.config ?? {},
                children: module?.categories?.map(mapCategory) ?? [],
            };
        })
        ?.sort((a, b) => a?.position - b?.position);
}

function mapCategory(category) {
    const { id, name, type, icon } = category;
    const slug = category?.slug ?? slugify(name);
    return {
        id,
        name,
        slug,
        type,
        icon,
        children: category.spaces?.map(mapSpace),
    };
}

function mapSpace(space) {
    const { id, name, type, slug, document_type, icon } = space;
    return {
        id,
        name,
        type,
        slug,
        icon,
        document_type,
        hiddenMenu: space?.hiddenMenu ?? false,
    };
}