/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Button from "@atlaskit/button";
import { Checkbox } from "@atlaskit/checkbox";
import TextField from "@atlaskit/textfield";
import { ErrorMessage, Field, HelperMessage } from "@atlaskit/form";
import { Radio } from "@atlaskit/radio";
import Lozenge from "@atlaskit/lozenge";
import useOrder from "../../hooks/use-order";
import Spinner from "../../components/ui/spinner";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import TextArea from "@atlaskit/textarea";
import apiClient from "../../api/business-client";
import SectionMessage, { SectionMessageAction } from "@atlaskit/section-message";

const domain = window.location.hostname;

export default function Payment({ uuid }) {
    const [isPaying, setIsPaying] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [shipping, setShipping] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [open, setOpen] = useState(false);
    const [postcode, setPostcode] = useState("");
    const [house, setHouse] = useState("");
    const [comment, setComment] = useState("");
    const { order, isPending, pay, save } = useOrder(uuid);
    const [done, setDone] = useState(false);
    const [inpost, setInpost] = useState(null);
    const [fbUser, setFbUser] = useState(null);
    const delivery = order?.delivery?.find(i => i?.id == shipping);
    let total = parseFloat(order?.package?.orders?.reduce((acc, order) => {
        if (order?.status !== "W toku" && order?.status !== "Zablokowane" && order?.status != null && order?.status !== "") return acc;

        return acc + order?.positions?.reduce((acc, pos) => {
            const price = parseFloat(pos.sale) > 0 ? parseFloat(pos.sale) : parseFloat(pos.price);
            const count = parseFloat(pos?.count ?? 1);

            return acc + count * (price ?? 0);
        }, 0) ?? 0;
    }, 0)) + parseFloat((delivery?.price ?? 0));

    let totalToBePaid = parseFloat(order?.package?.orders?.reduce((acc, order) => {
        if (order?.status !== "W toku" && order?.status !== "Zablokowane" && order?.status != null && order?.status !== "") return acc;

        return acc + order?.positions?.reduce((acc, pos) => {
            const price = parseFloat(pos.sale) > 0 ? parseFloat(pos.sale) : parseFloat(pos.price);
            const count = parseFloat(pos?.count ?? 1);

            return acc + count * (price ?? 0);
        }, 0) ?? 0;
    }, 0)) + parseFloat((delivery?.price ?? 0));

    if (delivery?.cash_on_delivery_normal) {
        total = 0;
        totalToBePaid = 0;
    }

    if (delivery?.cash_on_delivery_paid) {
        total = parseFloat((delivery?.price ?? 0));
        totalToBePaid = total;
    }

    const deliveryPaid = order?.package?.orders?.map(o => parseFloat(o?.payment?.delivery_paid ?? 0))?.reduce((acc, i) => {
        acc = acc + i;
        return acc;
    }, 0);

    React.useEffect(() => {
        if (isPending) return;
        if (!order) return;
        if (shipping) return;

        setName(order?.package?.recipient ?? "");
        setEmail(order?.package?.email ?? "");
        setPhone(order?.package?.phone ?? "");
        setStreet(order?.package?.address?.street ?? "");
        setComment(order?.package?.comment ?? "");
        setCity(order?.package?.address?.city ?? "");
        setPostcode(order?.package?.address?.postcode ?? "");
        setShipping(order?.delivery?.find(d => d?.format_original_id == order?.package?.format_id)?.id);
        setOpen(order?.package?.closed === false || order?.package?.closed === "false");
        setInpost(order?.package?.inpost ?? null);
    }, [order, isPending]);

    const onSubmit = () => {
        setErrors({});
        setIsPaying(true);
        pay({
            orderId: uuid,
            delivererId: shipping,
            formatId: delivery?.format_id,
            street,
            city,
            postcode,
            open,
            phone,
            name,
            email,
            comment,
            inpost,
        })
            .then(res => {
                if (delivery?.cash_on_delivery_normal) {
                    setDone(true);
                    return;
                }


                if (res?.redirect) {
                    window.location.href = res.redirect;
                    return;
                }

                if (res?.reason) {
                    toast.error(res.reason);
                }

                if (res?.errors) {
                    setErrors(res?.errors);
                }
            })
            .catch(err => {
                if (err?.reason) {
                    switch (err.reason) {
                        case "MULTIPLY_PAID_TRANSACTION":
                            toast.error("Zamówienie zostało już opłacone.");
                            return;
                    }
                }

                if (err?.errors) {
                    setErrors(err?.errors);
                }
            })
            .finally(() => {
                setIsPaying(false);
            });
    };


    const onSave = () => {
        setErrors({});
        setIsPaying(true);
        save({
            orderId: uuid,
            delivererId: shipping,
            formatId: delivery?.format_id,
            street,
            city,
            postcode,
            open,
            phone,
            name,
            email,
            comment,
            inpost,
        })
            .then(res => {
                setDone(true);
            })
            .catch(err => {
                toast.error(err?.message ?? "Wystąpił błąd");
            })
            .finally(() => {
                setIsPaying(false);
            });
    };

    const onInpostSelected = (params) => {
        // console.log(params);
        setInpost(params?.point?.code);
    };

    const somethingToBePaid = order?.package?.orders?.some(i => i?.status == "W toku" || i?.status == "Zablokowane" || i?.status == "Wpłacone pobranie" || i?.status == null);
    let isDisabled = !shipping || street?.length === 0 || name?.length === 0 || email?.length === 0 || city?.length === 0 || postcode?.length === 0 || phone?.length === 0 || isPending;

    if (!somethingToBePaid) {
        isDisabled = true;
    }

    if (delivery?.format_inpost && !inpost) {
        isDisabled = true;
    }


    const authenticate = () => {
        setLoading(true);
        apiClient("facebook-redirect", {
            method: "POST", data: {
                uuid: uuid,
            }
        })
            .then(response => {
                const url = response.redirect;
                window.location.href = url;
            })
            .catch(error => {
                toast.error("Wystąpił błąd");
                setLoading(false);
            });
    };

    if (done) {
        return <>
            <Helmet>
                <title>{order?.name}</title>
            </Helmet>
            <Wrapper>
                <Left>
                    <Header>Zamówienie nr {order?.name}</Header>
                    <Section>
                        <OpenPackageSection>
                            Dane przesyłki zostały zapisane.
                        </OpenPackageSection>
                    </Section>
                </Left></Wrapper></>;
    }


    return <>
        <Helmet>
            <title>{order?.name}</title>
            <script src="https://furgonetka.pl/js/dist/map/map.js" defer></script>
        </Helmet>
        <Wrapper>
            {order && order?.package && order.package.facebook_connected !== true && <Overlay>
                <OverlayBG />
                <OverlayInner>
                    {
                        isLoading ? <Spinner /> :
                            <FbInfoWrapper>
                                <TextInfo>
                                    Naciśnij <strong>Zaloguj się</strong> poniżej, aby za zamówienia z postów i live płacić tylko raz za wysyłkę.
                                </TextInfo>
                                <FacebookButtonWrapper onClick={authenticate}>
                                    <FacebookButton>
                                        <span>Zaloguj się przez Facebook&apos;a</span>
                                    </FacebookButton>
                                </FacebookButtonWrapper>

                                <SectionMessageWrapper>
                                    <SectionMessage appearance="error">
                                        <p>
                                            Uruchom tę stronę w przeglądarce Chrome za pomocą menu w prawym górnym rogu.
                                            Jeśli masz uruchomioną przeglądarkę Chrome, zignoruj ten komunikat i zaloguj się za pomocą Facebook.
                                        </p>
                                    </SectionMessage>
                                </SectionMessageWrapper>

                                <TopRightArrow>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                        <path fillRule="evenodd" d="M8.25 3.75H19.5a.75.75 0 01.75.75v11.25a.75.75 0 01-1.5 0V6.31L5.03 20.03a.75.75 0 01-1.06-1.06L17.69 5.25H8.25a.75.75 0 010-1.5z" clipRule="evenodd" />
                                    </svg>

                                </TopRightArrow>
                            </FbInfoWrapper>
                    }
                </OverlayInner>
            </Overlay>}
            <Left>
                <DFlex>
                    <DMain>
                        <Header>Zamówienie nr {order?.name}</Header>
                    </DMain>
                    {order?.organization && <Organization>
                        <OrganizationName>{order?.organization?.name}</OrganizationName>
                        <OrganizationAddress>{order?.organization?.address}</OrganizationAddress>
                    </Organization>}
                </DFlex>
                <Summary>
                    <h3 style={{ marginBottom: "25px" }}>Podsumowanie</h3>

                    {order?.package?.orders?.map((order, i) => (
                        <>
                            <Flex>
                                <Main>
                                    <h4>Zamówienie #{i + 1} {order?.created_at && <DateSmall>({format(new Date(order?.created_at ?? new Date()), "dd.MM.Y")})</DateSmall>}</h4>
                                </Main>
                                <div>
                                    {(order?.status == "W toku" || order?.status == null || order?.status === "" || order?.status === "Zablokowane") ? <Lozenge appearance="inprogress">W toku</Lozenge> : null}
                                    {order?.status == "Anulowane" ? <Lozenge appearance="removed">Anulowane</Lozenge> : null}
                                    {(order?.status != "W toku" && order?.status != "Zablokowane" && order?.status != "Wpłacone pobranie" && order?.status != null && order?.status !== "") && order?.status != "Anulowane" ? <Lozenge appearance="success">Zapłacone</Lozenge> : null}
                                    {(order?.status == "Wpłacone pobranie") && order?.status != "Anulowane" ? <Lozenge appearance="success">Za pobraniem</Lozenge> : null}
                                </div>
                            </Flex>
                            <SummaryList style={{ opacity: "0.5" }}>
                                {order?.positions?.map(position => (
                                    <FlexListItem key={position?.name}>
                                        <Main>{position?.count} x {position?.name}</Main>
                                        <RightAlign>{parseFloat(position.sale) > 0 ? parseFloat(parseFloat(position?.count) * parseFloat(position.sale)) : parseFloat(parseFloat(position?.count) * parseFloat(position.price))}zł</RightAlign>
                                    </FlexListItem>
                                ))}
                            </SummaryList>
                        </>
                    ))}
                    <SummaryList>
                        <FlexListItem>
                            <Main>Koszty wysyłki</Main>
                            <RightAlign>{delivery ? `${parseFloat(delivery?.price).toFixed(2)}zł` : "-"}</RightAlign>
                        </FlexListItem>
                    </SummaryList>
                    <SummaryPrice>
                        <Flex>
                            <Main>
                                <strong>Razem</strong>
                            </Main>
                            <div>
                                <SummaryPriceHighlight>
                                    <span>{!isNaN(total) ? parseFloat(total).toFixed(2) : "-"}</span> zł
                                </SummaryPriceHighlight>
                            </div>
                        </Flex>
                    </SummaryPrice>
                </Summary>


                <Section>
                    <SectionHeader>Otwarta paczka</SectionHeader>
                    <Checkbox size="large" isChecked={open} onChange={e => setOpen(i => !i)} label={<div>
                        Po zapłacie otwarta paczka nie zostanie wysłana.
                        Będziesz mogła/mógł dołączyć do paczki kolejne zamówienie.
                    </div>} />

                    {open && <OpenPackageSection>
                        Paczka zostanie zamknięta w ciągu 3 dni roboczych.
                    </OpenPackageSection>}
                </Section>
                <Section>
                    <SectionHeader>Dostawca</SectionHeader>
                    {
                        order?.delivery?.map(d => (
                            <div key={d.name} style={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                                <Radio
                                    ss value={d.id}
                                    label={`${d.name} - ${parseFloat(d?.price).toFixed(2)}zł`}
                                    name="shipping"
                                    isDisabled={order?.package?.selected_format_id != null}
                                    isChecked={shipping == d.id}
                                    onChange={e => {
                                        setShipping(d.id);
                                        if (d?.format_inpost) {
                                            if (domain == "localhost" || domain == "staging.vseli.com") {
                                                new window.Furgonetka.Map({
                                                    courierServices: ["inpost"],
                                                    callback: onInpostSelected,
                                                    apiUrl: "https://api-test.furgonetka.pl",
                                                }).show();
                                            } else {
                                                new window.Furgonetka.Map({
                                                    courierServices: ["inpost"],
                                                    callback: onInpostSelected,
                                                }).show();
                                            }
                                        }
                                    }}
                                />
                                {d?.format_inpost && shipping == d.id && <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    new window.Furgonetka.Map({
                                        courierServices: ["inpost"],
                                        city: "Warszawa",
                                        street: "Żoliborz",
                                        callback: onInpostSelected,
                                    }).show();
                                }} style={{
                                    fontSize: "11px",
                                    marginLeft: "5px",
                                    textDecoration: "underline",
                                }}>Wybierz z mapy</a>}
                                {d?.format_inpost && shipping == d.id && inpost && <div style={{
                                    fontWeight: "bold",
                                    marginLeft: "5px",
                                    fontSize: "11px",
                                }}>
                                    {inpost}
                                </div>}
                            </div>
                        ))
                    }

                    {deliveryPaid > 0 && <OpenPackageSection>
                        Zapłacono już za dostawę: <strong>{parseFloat(deliveryPaid).toFixed(2)}zł</strong>
                    </OpenPackageSection>}
                </Section>
                <Section>
                    <SectionHeader>Dane adresowe</SectionHeader>
                    <FlexLg>
                        <Main>
                            <Field label="Imię i nazwisko" name="name" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} value={name} onChange={e => setName(e.target.value)} />
                                )}
                            </Field>
                            {errors?.name && <ErrorMessage>{errors?.name[0]}</ErrorMessage>}
                        </Main>
                    </FlexLg>
                    <FlexLg>
                        <Main>
                            <Field label="Adres e-mail" name="email" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} value={email} onChange={e => setEmail(e.target.value)} />
                                )}
                            </Field>
                            {errors?.email && <ErrorMessage>{errors?.email[0]}</ErrorMessage>}
                        </Main>
                        <Block15Lg />
                        <div>
                            <Field label="Nr telefonu" name="phone" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} value={phone} onChange={e => setPhone(e.target.value)} />
                                )}
                            </Field>
                            {errors?.phone && <ErrorMessage>{errors?.phone[0]}</ErrorMessage>}
                        </div>
                    </FlexLg>
                    <FlexLg>
                        <Main>
                            <Field label="Ulica i nr domu" name="street" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} value={street} onChange={e => setStreet(e.target.value)} />
                                )}
                            </Field>
                            {errors?.street && <ErrorMessage>{errors?.street[0]}</ErrorMessage>}
                        </Main>
                    </FlexLg>
                    <FlexLg>
                        <div>
                            <Field label="Kod pocztowy" name="postal" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} value={postcode} onChange={e => setPostcode(e.target.value)} />
                                )}
                            </Field>
                            {errors?.postcode && <ErrorMessage>{errors?.postcode[0]}</ErrorMessage>}
                        </div>
                        <Block15Lg />
                        <Main>
                            <Field label="Miejscowość" name="city" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} value={city} onChange={e => setCity(e.target.value)} />
                                )}
                            </Field>
                            {errors?.city && <ErrorMessage>{errors?.city[0]}</ErrorMessage>}
                        </Main>
                    </FlexLg>
                    <FlexLg>
                        <Main>
                            <Field label="Komentarz" name="comment" isRequired>
                                {({ fieldProps }) => (
                                    <TextArea {...fieldProps} value={comment} onChange={e => setComment(e.target.value)} />
                                )}
                            </Field>
                        </Main>
                    </FlexLg>
                </Section>
                <div style={{ marginTop: "40px" }}>
                    {
                        isNaN(totalToBePaid) || parseFloat(totalToBePaid) == 0.0
                            ? <Button appearance="primary" shouldFitContainer height={50} isDisabled={isDisabled || isPending || isPaying} onClick={onSave}>
                                Zapisz
                            </Button>
                            : <Button appearance="primary" shouldFitContainer height={50} isDisabled={isDisabled || isPending || isPaying} onClick={onSubmit}>
                                {delivery?.cash_on_delivery_normal
                                    ? "Zapisz"
                                    : <span>
                                        Zapłać {!isNaN(totalToBePaid) ? parseFloat(totalToBePaid).toFixed(2) : "-"} zł
                                    </span>}
                            </Button>
                    }

                </div>
            </Left>
        </Wrapper>
    </>;
}

const Wrapper = styled.div`
padding: 20px;
@media (min-width: 600px) {
    padding: 40px;
    display: flex;
    max-width: 600px;
    margin: 0px auto;
}
`;

const Overlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
`;

const OverlayBG = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: white;
    opacity: 0.9;
    top: 0;
    left: 0;
    z-index: 1001;
    backdrop-filter: blur(10px);
`;

const OverlayInner = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 1002;
`;

const DFlex = styled.div`
    display: flex;
    align-items: center;
`;

const DMain = styled.div`
    flex-grow: 1;
`;

const Left = styled.div`
flex: 3;
`;

const Right = styled.div`
flex: 2;

margin-top: 40px;
@media (min-width: 900px) {
    padding: 20px;
    padding-left: 100px;
}
`;

const Header = styled.h1`
font-size: 25px;
margin: 0;
padding: 0;
`;


const Section = styled.div`
    padding: 30px 0;
    border-bottom: 1px solid #eee;
`;

const SectionHeader = styled.h3`
font-size: 16px;
margin-bottom: 10px;
`;

const Summary = styled.div`
    background: #F5F5F7;
    padding: 25px;
    border-radius: 5px;
    margin-top: 20px;
`;

const SummaryList = styled.div`
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
`;

const SummaryPrice = styled.div`

`;

const Flex = styled.div`
    display: flex;
    align-items: flex-end;
`;

const FlexListItem = styled.div`
display: flex;
align-items: flex-end;
margin: 10px 0;
`;

const FlexLg = styled.div`
@media (min-width: 900px) {
    display: flex;
    align-items: flex-end;
}
`;

const Main = styled.div`
    flex: 1;
`;

const SummaryPriceHighlight = styled.h2`
`;

const OpenPackageSection = styled.div`
    background: #F5F5F7;
    padding: 15px;
    border-radius: 5px;
    margin: 15px 0 0;
`;

const Block15Lg = styled.div`
@media (min-width: 900px) {
    width: 15px;
    height: 100%;
}
`;

const RightAlign = styled.div`
    text-align: right;
`;

const Organization = styled.div`
    opacity: 0.7;
    text-align: right;
`;
const OrganizationName = styled.div`
font-weight: 600;
font-size: 0.9rem;
`;
const OrganizationAddress = styled.div`
    font-size: 0.8rem;
    white-space: pre-wrap;
`;

const DateSmall = styled.div`
    font-size: 0.8rem;
    opacity: 0.5;
    display: inline-block;
    height: 10px;
`;



const FacebookButtonWrapper = styled.div`
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #1877F2;
    color: white;
    width: 200px;
    display: flex;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
`;

const FacebookButton = styled.div`
    margin-left: 5px;
`;

const FbInfoWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
width: 100%;
max-width: 500px;
align-items: center;
justify-content: center;
`;

function unsupportedBrowser() {
    var sBrowser, sUsrAg = navigator?.userAgent;

    if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
        return false;
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet";
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium";
        return false;
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
        return false;
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
        sBrowser = "unknown";
    }

    return true;
}

const SectionMessageWrapper = styled.div`
position: relative;
position: absolute;
top: 0px;
right: 0px;
max-width: min(400px, calc(100% - 20px));
margin: 10px;
`;

const TopRightArrow = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 100;
    width: 20px;
    height: 20px;
`;

const TextInfo = styled.div`
    text-align: center;
    font-size: 16px;
`;