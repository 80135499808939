import { useCallback, useEffect } from "react";
import apiClient from "../api/admin-api-client";
import useAsync from "../hooks/use-async";

export default function useAdminCategories(module) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        if (!module?.name) return;
        reload();
    }, [ module?.name ]);

    const reload = useCallback(() => run(apiClient("categories")).catch(() => { }));

    return {
        categories: data?.data?.filter(i => i.module?.name?.toLowerCase() === module?.name?.toLowerCase()) || [],
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        reload,
    };
}