import React, { useState } from "react";
import styled from "styled-components";
import Form, { Field } from "@atlaskit/form";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import PageHeader from "@atlaskit/page-header";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import TextField from "@atlaskit/textfield";
import { useAuth } from "../../providers/auth-provider";
import { useTranslation } from "react-i18next";

function PersonalSettings() {
    const { user } = useAuth();
    const { t } = useTranslation();

    const [ pending, setPending ] = useState(false);
    const [ firstName, setFirstName ] = useState(() => user.first_name);
    const [ lastName, setLastName ] = useState(() => user.last_name);

    const actionsContent = <ButtonGroup>
        <Button appearance="primary" type="submit" isLoading={pending} isDisabled={!firstName || !lastName || firstName.length < 3 || lastName.length < 3}>
            {t("profile_basic_save")}
        </Button>
    </ButtonGroup>;

    const  handleSubmit = (e) => {
        e.preventDefault();
        setPending(true);
        apiClient("user/profile", {
            method: "PATCH",
            data: {
                first_name: firstName,
                last_name: lastName
            }
        })
            .then(() => {
                toast.success(t("profile_basic_success"));
            })
            .catch(() => toast.error(t("profile_basic_error")))
            .finally(() => setPending(false));
    };

    return <Wrapper>
        <Form>
            {({ formProps }) => (
                <form {...formProps} onSubmit={handleSubmit}>
                    <PageHeader actions={actionsContent}>
                        {t("profile_basic")}
                    </PageHeader>
                    <Field label={t("profile_basic_first_name")} name="firstName">
                        {({ fieldProps }) => <TextField {...fieldProps} name="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} />}
                    </Field>
                    <Field label={t("profile_basic_last_name")} name="lastName">
                        {({ fieldProps }) => <TextField {...fieldProps} name="lastName" value={lastName} onChange={e => setLastName(e.target.value)} />}
                    </Field>
                </form>
            )}
        </Form>
    </Wrapper>;
}

export default PersonalSettings;

const Wrapper = styled.div`
width: 100%;
`;
