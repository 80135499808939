import React, { useState } from "react";
import PageHeader from "@atlaskit/page-header";
import AdminLayout from "../../layouts/admin-layout";
import useModules from "../../hooks/use-modules";
import DynamicTable from "@atlaskit/dynamic-table";
import Spinner from "../../components/ui/spinner";
import useAdminRoles from "../../hooks/use-admin-roles";
import apiClient from "../../api/admin-api-client";
import { toast } from "react-toastify";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import NewRole from "../../components/roles/new-role";
import EditRole from "../../components/roles/edit-role";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import RemoveRole from "../../components/roles/remove-role";
import { useTranslation } from "react-i18next";

function AdminRoles() {
    const { roles, isPending, reload } = useAdminRoles();
    const { run, isPending: isPendingAsync, error, isError: isErrorAsync } = useAsync();
    const [addFormOpen, setAddFormOpen] = useState(false);
    const [roleToEdit, setRoleToEdit] = useState(null);
    const [roleToRemove, setRoleToRemove] = useState(null);
    const { t } = useTranslation();

    if (isPending) {
        return <AdminLayout>
            <Spinner />
        </AdminLayout>;
    }

    const head = {
        cells: [
            { key: "ID", content: "", },
            { key: "name", content: t("admin_roles_column_name"), isSortable: false },
            { key: "modules", content: "", isSortable: false, },
            { key: "actions", content: "", }
        ]
    };

    const rows = roles.map(r => ({
        key: r?.id,
        cells: [
            { key: "ID", content: r?.id },
            { key: "name", content: r?.organization ? r?.name : <strong>{r?.name}</strong> },
            {
                key: "modules", content: <div>
                    {r?.modules?.map(m => m?.name)?.join(", ")}
                </div>
            },
            {
                key: "actions", content: !r?.organization &&
                    <DropdownMenu position="bottom right" triggerType="button">
                        <DropdownItemGroup>
                            <DropdownItem onClick={() => {
                                setRoleToEdit(r);
                            }}>
                                {t("admin_roles_edit")}
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                setRoleToRemove(r);
                            }}>
                                {t("admin_roles_remove")}
                            </DropdownItem>
                        </DropdownItemGroup>
                    </DropdownMenu>
            }
        ]
    }));

    const actionsContent = (
        <ButtonGroup>
            <Button appearance="primary" onClick={() => setAddFormOpen(true)} isLoading={isPendingAsync}>
                {t("admin_roles_create")}
            </Button>
        </ButtonGroup >
    );

    return <AdminLayout>
        <PageHeader actions={actionsContent}>
            {t("admin_roles_heading")}
        </PageHeader>
        <DynamicTable
            head={head}
            rows={rows}
            isLoading={isPending}
            emptyView={<div>{t("admin_roles_empty")}</div>}
        />
        {addFormOpen && <NewRole isPending={isPendingAsync} onClose={() => setAddFormOpen(false)} onSubmit={data => {
            run(apiClient("roles", { data }))
                .then(() => {
                    toast.success(t("admin_roles_success"));
                    reload();
                    setAddFormOpen(false);
                })
                .catch(error => {
                    toast.error(t("admin_roles_error"));
                });
        }} error={error} isError={isErrorAsync} />}
        {roleToEdit && <EditRole role={roleToEdit} isPending={isPendingAsync} onClose={() => setRoleToEdit(null)} onSubmit={data => {
            run(apiClient(`roles/${roleToEdit?.id}`, { data, method: "PATCH" }))
                .then(() => {
                    toast.success(t("admin_roles_updated"));
                    reload();
                    setRoleToEdit(null);
                })
                .catch(error => {
                    toast.error(t("admin_roles_updated_error"));
                });
        }} error={error} isError={isErrorAsync} />}
        {roleToRemove && <RemoveRole onClose={() => setRoleToRemove(null)} isPending={isPendingAsync} onSubmit={() => {
            run(apiClient(`roles/${roleToRemove?.id}`, { method: "DELETE" }))
                .then(() => {
                    toast.success(t("admin_roles_removed"));
                    reload();
                    setRoleToRemove(null);
                })
                .catch(error => {
                    toast.error(t("admin_roles_removed_error"));
                });
        }} />}
    </AdminLayout>;
}

export default AdminRoles;
