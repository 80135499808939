/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Checkbox } from "@atlaskit/checkbox";
import Select from "@atlaskit/select";
import useAvailableDocumentTypes from "../../hooks/use-available-document-types";
import useAdminDocuments from "../../hooks/use-admin-document";
import { AsyncSelect } from "@atlaskit/select";
import { useTranslation } from "react-i18next";

export default function CreateRelation({ onClose, onAdded, documentTypeId }) {
    const { run, isPending , error, isError: isCreatingError } = useAsync();
    const [ type, setType ] = useState(null);
    const { t } = useTranslation();

    const search = query => {
        return apiClient(`document-types?per_page=100&search=${query}`)
            .then(response => {
                return response?.data?.map(i => ({
                    value: i?.id,
                    label: `${i?.space?.category?.module?.alias} / ${i?.category?.name} / ${i?.name}`
                }));
            });
    };

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit}>
                    {t("admin_create_relation_create")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_create_relation_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        run(apiClient(`document-type/${documentTypeId}/link/${type?.value}`, {
            method: "POST"
        }))
            .then(response => {
                onAdded(response);
                toast.success(t("admin_create_relation_success"));
                onClose();
            })
            .catch(() => {
                toast.error(t("admin_create_relation_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            isDisabled={!type?.value}
            heading={t("admin_create_relation_heading")}
            scrollBehavior="outside"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_create_relation_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="type" label={t("admin_create_relation_type")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => (
                    <AsyncSelect loadOptions={search} defaultOptions value={type} onChange={setType} isDisabled={isPending} placeholder="Typ dokumentu" />
                )}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;