/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Spinner from "../ui/spinner";
import Textarea from "@atlaskit/textarea";

export default function GenerateUserToken({ user, onClose }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [ token, setToken ] = useState(null);
    const { t } = useTranslation();

    const submit = () => {
        run(apiClient(`users/${user.id}/tokens`, { method: "POST" }))
            .then(response => {
                setToken(response.token);
            })
            .catch(() => {
                toast.error(t("admin_generate_user_token_error"));
            });
    };

    useEffect(() => submit(), []);

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_generate_user_token_heading")}
            isLoading={isPending}
            scrollBehavior="inside-wide"
        >
            {isCreatingError ? <SectionMessageWrapper>
                <SectionMessage title={t("admin_generate_user_token_error")} appearance="error">
                    <p>{error?.message}</p>
                </SectionMessage>
            </SectionMessageWrapper> : <SectionMessageWrapper>
                <SectionMessage appearance="info">
                    <p>{t("admin_generate_user_token_info")}</p>
                </SectionMessage>
            </SectionMessageWrapper>}
            <br />
            {isPending && <Spinner />}
            {token && <Textarea value={token} isReadOnly={true} minimumRows={10} />}
            <br />
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;