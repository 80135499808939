import React from "react";
import styled from "styled-components";
import Navbar from "../components/nav/navbar";
import Sidebar from "../components/nav/admin-sidebar";
import { useAuth } from "../providers/auth-provider";

export default function AdminLayout({ children }) {
    const { user, logout } = useAuth();

    return <>
        <Navbar user={user} logout={logout} />
        <ContentWrapper>
            <Sidebar />
            <Content padding="30px">
                {children}
            </Content>
        </ContentWrapper>
    </ >;
}

const ContentWrapper = styled.div`
display: flex;
min-height: 100vh;
`;

const Content = styled.div`
    padding-left: calc(0px + ${props => props.padding});
    padding-top: calc(56px + ${props => props.padding} - 20px);
    padding-right: ${props => props.padding};
    padding-bottom: ${props => props.padding};
    background: white;
    z-index: 201;
    width: 100%;
    min-height: 100%;
`;