import React, { useEffect } from "react";
import Tabs from "@atlaskit/tabs";
import styled from "styled-components";
import DirectNotifications from "./direct-notifications";
import apiClient from "../../api/api-client";
import WatchingNotifications from "./watching-notifications";
import { useTranslation } from "react-i18next";

function Notifications() {
    const { t } = useTranslation();
    // useEffect(() => {
    //     apiClient("notifications/read-all", { method: "POST" }).catch(() => {});
    // }, []);
    const tabs = [
        { label: t("notifications_direct"), content: <Content>
            <DirectNotifications  />
        </Content> },
    ];

    return <TabsWrapper>
        <Tabs tabs={tabs} />
    </TabsWrapper>;
}

export default Notifications;

const Content = styled.div`
padding-top: 20px;
width: 400px;
`;

const TabsWrapper = styled.div`
padding: 20px;
`;