/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import TextArea from "@atlaskit/textarea";
import TextField from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import SelectRole from "../roles/select-role";
import SelectRoles from "../roles/select-roles";
import SelectWorkflow from "../workflows/select-workflow";
import { useTranslation } from "react-i18next";

export default function AttachWorkflowToDocumentType({ onClose, onSuccess, documentTypeId }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [ workflow, setWorkflow ] = useState(null);
    const { t } = useTranslation();

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit} isDisabled={!workflow}>
                    {t("admin_attach_workflow_assign")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_attach_workflow_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        run(apiClient(`document-type/${documentTypeId}/workflow`, { data: {
            workflow: workflow,
        }, method: "POST" }))
            .then(response => {
                toast.success(t("admin_attach_workflow_success"));
                onSuccess();
            })
            .catch(() => {
                toast.error(t("admin_attach_workflow_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_attach_workflow_heading")}
            scrollBehavior="outside"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_attach_workflow_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="workflow" label={t("admin_attach_workflow_workflow")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <SelectWorkflow value={workflow} onChange={e => setWorkflow(e?.value)} />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;

