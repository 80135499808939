import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";
import { useLocation } from "react-router-dom";
import ModulesProvider from "../../providers/modules-provider";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import TagManager from "react-gtm-module";


function PrivateRoute({ children, ...props }) {
    const { user } = useAuth();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!user) return;

        apiClient("configs/global")
            .then(data => {
                const gtm = data?.config?.googleTagManager;
                if (!gtm) return;

                TagManager.initialize({ gtmId: gtm });
            })
            .catch(() => {

            });
    }, []);

    if (!user) {
        return <Redirect to={"/login?redirect=" + pathname} />;
    }

    return <Route {...props}>
        <ModulesProvider>
            {children}
        </ModulesProvider>
    </Route>;
}

export default PrivateRoute;