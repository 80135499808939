import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import apiClient from "../api/api-client";
import modulesResponse from "../api/modules-response";
import useAsync from "../hooks/use-async";

export default function useModules() {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();
    const query = useQuery();
    const queryFilterId = query.get("filter");

    useEffect(() => {
        reload();
    }, [ queryFilterId ]);

    const reload = useCallback(() => {
        return run(apiClient("modules"))
            .catch(() => { });
    });

    return {
        modules: data ? modulesResponse(data) : null,
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        reload,
    };
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}