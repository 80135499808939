import React, { useState } from "react";
import useDocumentWorkflows from "../../hooks/use-document-workflows";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ButtonGroup } from "@atlaskit/button";
import Button from "@atlaskit/button";
import Spinner from "../ui/spinner";
import RunUserWorkflow from "../workflows/run-user-workflow";

function DocumentWorkflowsDropdown({ document, onRemove, onSuccess, canRemove = true }) {
    const { t } = useTranslation();
    const { data: workflows, callback } = useDocumentWorkflows(document);
    const documentTypeWorkflows = document?.document_type?.workflows?.filter(i => parseInt(i?.workflow_type_id) === 2) ?? [];
    const userDocumentTypeWorkflows = document?.document_type?.workflows?.filter(i => parseInt(i?.workflow_type_id) === 1) ?? [];
    const [loadingId, setLoadingId] = useState(null);
    const [userWorkflowId, setUserWorkflowId] = useState(null);

    // `document-type/${documentTypeId}/workflows`

    const runDocumentTypeWorkflow = workflowId => {
        setLoadingId(workflowId);
        apiClient(`workflow/${workflowId}/document/${document?.id}`, { method: "POST" })
            .then(onSuccess)
            .catch(e => toast.error(e.message))
            .finally(() => setLoadingId(null));
    };

    const count = workflows?.length
        + documentTypeWorkflows?.length
        + (canRemove ? 1 : 0);

    if (!count) return null;

    return <div style={{ display: "flex" }}>
        <ButtonGroup>
            {workflows.map(workflow => {
                if (workflow?.config?.button) {
                    return <Button appearance={workflow?.config?.button} key={workflow.id} onClick={() => callback(workflow).then(onSuccess)}>
                        {workflow.label}
                    </Button>;
                }
                return null;
            })}

            {documentTypeWorkflows.map(workflow => {
                try {
                    const config = workflow?.pivot?.config;
                    const options = JSON.parse(config);

                    if (options?.button) {
                        return <Button appearance={options?.button} key={workflow.id} onClick={() => runDocumentTypeWorkflow(workflow?.id)} isDisabled={loadingId == workflow.id}>
                            {loadingId == workflow.id ? <Spinner /> : <span>{workflow.name}</span>}
                        </Button>;
                    }
                } catch (e) {
                    return null;
                }


                return null;
            })}

            {userDocumentTypeWorkflows.map(workflow => {
                try {
                    const config = workflow?.pivot?.config;
                    const options = JSON.parse(config);

                    if (options?.button) {
                        return <Button appearance={options?.button} key={workflow.id} onClick={() => setUserWorkflowId(workflow?.id)}>
                            {workflow.name}
                        </Button>;
                    }
                } catch (e) {
                    return null;
                }


                return null;
            })}
        </ButtonGroup>
        <DropdownMenu position="bottom right" triggerType="button">
            <DropdownItemGroup>
                {canRemove && <DropdownItem onClick={() => onRemove()}>
                    {t("document_workflow_remove")}
                </DropdownItem>}
            </DropdownItemGroup>
            <DropdownItemGroup>
                {document?.document_type?.print_patterns?.map(pdf => (
                    <DropdownItem key={pdf.id} onClick={() => {

                    }}>
                        {pdf?.name}
                    </DropdownItem>
                ))}
            </DropdownItemGroup>
            <DropdownItemGroup>
                {workflows.map(workflow => (
                    <DropdownItem key={workflow.id} onClick={() => callback(workflow).then(onSuccess)}>
                        {workflow.label}
                    </DropdownItem>
                ))}
            </DropdownItemGroup>
            {documentTypeWorkflows.length > 0 && <DropdownItemGroup>
                {documentTypeWorkflows.map(workflow => (
                    <DropdownItem key={workflow.id} onClick={() => runDocumentTypeWorkflow(workflow?.id)}>
                        {workflow.name}
                    </DropdownItem>
                ))}
            </DropdownItemGroup>}
        </DropdownMenu>
        {userWorkflowId && <RunUserWorkflow documentId={document?.id} documentTypeId={document?.document_type_id} onSuccess={() => {
            setUserWorkflowId(null);
        }}
            onClosed={() => setUserWorkflowId(null)}
            initialWorkflow={{
                value: userWorkflowId,
                label: userDocumentTypeWorkflows?.find(i => i?.id == userWorkflowId)?.name,
            }} />}
    </div>;
}

export default DocumentWorkflowsDropdown;