/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import styled from "styled-components";
import Form, { Field, FormFooter } from "@atlaskit/form";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import TextField from "@atlaskit/textfield";
import { useTranslation } from "react-i18next";
import useAdminModules from "../../hooks/use-admin-modules";
import Select from "@atlaskit/select";

export default function EditRole({ role, onClose, onSubmit, isError, error, isPending = false }) {
    const { t } = useTranslation();
    const { modules, isPending: isPendingModules } = useAdminModules();
    const [selectedModules, setSelectedModules] = useState([]);
    const [name, setName] = useState(role?.name);

    useEffect(() => {
        if (isPendingModules) return;

        const roleModules = (role?.modules ?? [])?.map(m => m?.id) ?? [];

        setSelectedModules(
            modules
                ?.filter(i => roleModules?.includes(i?.id))
                ?.map(i => ({
                    label: i?.name,
                    value: i?.id,
                }))
        );
    }, [isPendingModules]);

    return <>
        <ModalTransition>
            <Modal
                onClose={() => {
                    onClose();
                }}
                heading={t("admin_edit_role_heading")}
                scrollBehavior="outside"
                shouldCloseOnOverlayClick
            >
                <Wrapper>
                    <Form onSubmit={() => onSubmit({
                        name,
                        modules: selectedModules?.map(i => i?.value) ?? [],
                    })}>
                        {({ formProps }) => (
                            <form {...formProps}>
                                <Field name="name" value={name} onChange={e => setName(e.target.value)} defaultValue={role?.name} label={t("admin_edit_role_name")} isRequired>
                                    {({ fieldProps }) => <TextField {...fieldProps} isInvalid={isError && error?.errors?.name} isDisabled={isPending} defaultValue={role?.name} />}
                                </Field>
                                <Field name="modules" label={t("admin_edit_role_modules")}>
                                    {({ fieldProps }) => <Select
                                        value={selectedModules}
                                        options={modules?.map(m => ({
                                            label: m?.name,
                                            value: m?.id,
                                        }))}
                                        onChange={e => setSelectedModules(e)}
                                        isDisabled={isPending}
                                        isLoading={isPendingModules}
                                        isClearable={true}
                                        isMulti={true} />}
                                </Field>
                                <FormFooter>
                                    <ButtonGroup>
                                        <Button type="submit" appearance="primary" isLoading={isPending}>
                                            {t("admin_edit_role_save")}
                                        </Button>
                                    </ButtonGroup>
                                </FormFooter>
                            </form>
                        )}
                    </Form>
                </Wrapper>
            </Modal>
        </ModalTransition>
    </>;
}

const Wrapper = styled.div`
    margin-bottom: 20px;
`;

const FieldWrapper = styled.div`
    margin-top: 20px;
`;