
import { AbstractMentionResource, DefaultMentionNameResolver, MentionNameStatus, SLI_EVENT_TYPE } from "@atlaskit/mention/resource";
import apiClient from "../api/api-client";

export class MentionResource extends AbstractMentionResource {
    constructor(config) {
        super();
        this.config = config;
        this.lastReturnedSearch = 0;
        this.productName = config.productName;
        this.shouldEnableInvite = !!config.shouldEnableInvite;
        this.onInviteItemClick = config.onInviteItemClick;
        this.userRole = config.userRole || "basic";
    }
  
    filter(query) {
        const searchTime = Date.now();
  
        const notify = mentions => {
            if (searchTime >= this.lastReturnedSearch) {
                this.lastReturnedSearch = searchTime;
                let stats = {};
  
                if (query === "team") {
                    stats.teamMentionDuration = 200;
                } else {
                    stats.duration = 100;
                }
  
                this._notifyListeners(mentions, stats);
            } else {
                const date = new Date(searchTime).toISOString().substr(17, 6);
            }
  
            this._notifyAllResultsListeners(mentions);
        };
  
        const notifyErrors = error => {
            this._notifyErrorListeners(error);
        };
  
        const notifyAnalytics = (eventType, sliName, action) => {
            this._notifyAnalyticsListeners(eventType, sliName, action);
        };
  
        const minWait = this.config.minWait || 0;
        const randomTime = (this.config.maxWait || 0) - minWait;
        const waitTime = Math.random() * randomTime + minWait;

        setTimeout(() => {
            fetchUsers(query)
                .then(users => {
                    notify({
                        mentions: users,
                        query
                    });
                    return Promise.resolve(users);
                });
        }, waitTime + 1);

        return Promise.resolve();
    } // eslint-disable-next-line class-methods-use-this
  
  
    recordMentionSelection(mention) {
    }
  
    resolveMentionName(id) {
        if (!this.config.mentionNameResolver) {
            return {
                id,
                name: "",
                status: MentionNameStatus.UNKNOWN
            };
        }
  
        return this.config.mentionNameResolver.lookupName(id);
    }
  
    cacheMentionName(id, name) {
        if (this.config.mentionNameResolver) {
            this.config.mentionNameResolver.cacheName(id, name);
        }
    }
  
    supportsMentionNameResolving() {
        const supported = !!this.config.mentionNameResolver;
        return supported;
    }
  
    shouldHighlightMention(mention) {
        return false;
    }
  
}

const fetchUsers = (query) => {
    return apiClient(`users?email=${query}`)
        .then(response => response.data.map(i => ({
            display: `${i.first_name} ${i.last_name}`,
            name: `${i.first_name} ${i.last_name}`,
            mentionName: `${i.first_name} ${i.last_name}`,
            nickname: `${i.first_name} ${i.last_name}`,
            avatar_url: i.avatar_url,
            avatarUrl: i.avatar_url,
            id: i.id,
        }))
        );
};

export const resourceProvider = new MentionResource({
    minWait: 10,
    maxWait: 25
});