import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Navbar from "../components/nav/navbar";
import Sidebar from "../components/nav/sidebar";
import { useAuth } from "../providers/auth-provider";
import SectionMessage, { SectionMessageAction } from "@atlaskit/section-message";

function DocumentLayout({ children, location, document }) {
    const { user, logout, reload, globalStatus, userStatus, setGlobalStatus, setUserStatus, searchEnabled } = useAuth();

    useEffect(() => {
        if (!user) return;
        const channelName = `user-status.${user?.id}`;
        Echo.channel(channelName)
            .listen(".Updated", (e) => {
                setUserStatus(e);
            })
            .listen(".Removed", (e) => {
                setUserStatus(undefined);
            });


        return () => Echo.leaveChannel(channelName);
    }, [user]);

    useEffect(() => {
        if (!user) return;
        const channelName = "status";
        Echo.channel(channelName)
            .listen(".Updated", (e) => {
                setGlobalStatus(e);
            })
            .listen(".Removed", (e) => {
                setGlobalStatus(undefined);
            });


        return () => Echo.leaveChannel(channelName);
    }, [user]);

    return <>
        <Navbar user={user} logout={logout} searchEnabled={searchEnabled} />
        <ContentWrapper addPadding={!!globalStatus || !!userStatus}>
            {globalStatus && <GlobalStatus status={globalStatus} />}
            {userStatus && !globalStatus && <GlobalStatus status={userStatus} />}
            <ContentFlex>
                <Sidebar pathname={location.pathname} document={document} addPadding={!globalStatus && !userStatus} />
                <Content padding="30px" addPadding={!globalStatus && !userStatus}>
                    {children}
                </Content>
            </ContentFlex>
        </ContentWrapper>
    </>;
}

export default withRouter(DocumentLayout);


const ContentWrapper = styled.div`
padding-top: ${props => props.addPadding ? "56px" : "0px"};
min-height: 100vh;
`;
const ContentFlex = styled.div`
min-height: 100vh;
display: flex;
`;

const Content = styled.div`
    padding-left: calc(0px + ${props => props.padding});
    padding-top: calc(${props => props.addPadding ? "56px" : "0px"} + ${props => props.padding} - 20px);
    padding-right: ${props => props.padding};
    padding-bottom: ${props => props.padding};
    background: white;
    width: 100%;
    min-height: 100%;
`;

function GlobalStatus({ status: { content, type } }) {
    return <GlobalStatusWrapper>
        <SectionMessage appearance={type}>
            <p>{content}</p>
        </SectionMessage>
    </GlobalStatusWrapper>;
}

const GlobalStatusWrapper = styled.div`
width: 100%;
`;