import { useCallback, useEffect } from "react";
import apiClient from "../api/admin-api-client";
import useAsync from "../hooks/use-async";

export default function useAdminSpace(module, category, slug) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        if (!module || ! category || !slug) return;
        reload();
    }, [ module, category, slug ]);

    const reload = useCallback(() => run(apiClient(`spaces/${slug}`)).catch(() => { }));

    return {
        space: data?.data || [],
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        reload,
    };
}