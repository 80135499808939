import { useCallback, useEffect } from "react";
import apiClient from "../api/admin-api-client";
import useAsync from "../hooks/use-async";

export default function useAdminModules() {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        reload();
    }, []);

    const reload = useCallback(() => run(apiClient("modules")).catch(() => { }));

    return {
        modules: data?.data ? data?.data : [],
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        reload,
    };
}