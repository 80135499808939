export default function documentFieldsResponse(response) {
    const fields = [];
    let currentSection = null;

    response
        ?.sort((a, b) => a.position - b.position)
        ?.forEach(field => {
            if (field?.field?.type === "section") {
                currentSection = field?.id;
                field.sectionId = field?.id;
            } else {
                field.sectionId = currentSection;
            }

            fields.push(field);
        });

    return fields;
}