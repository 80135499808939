import React, { useState } from "react";
import { AsyncSelect } from "@atlaskit/select";
import apiClient from "../../api/api-client";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CreateCompany from "../companies/create-company";

const companies = [
    { label: "AIR Labs Krzysztof Bączkiewicz", value: "7842446875" },
    { label: "Łukasz Ławański It", value: "7822435006" },
    { label: "Lean Grow Sp. z o.o.", value: "7792449066" },
    { label: "FrameCoders Sp. z o.o.", value: "7822821382" },
];

function SearchCompany({ typeId, onChange, value, isDisabled }) {
    const [ open, setOpen ] = useState(false);
    // const search = query => apiClient(`link/document-type/${typeId}/documents?query=${query}`)
    //     .then(response => {
    //         return Promise.resolve(response.map(i => ({
    //             label: i.name,
    //             value: i.id,
    //         })));
    //     })
    //     .catch(() => Promise.resolve([]));

    const search = query => Promise.resolve(companies.filter(i => i.value == query));

    return <Wrapper>
        <AsyncSelect loadOptions={search} value={value} onChange={onChange} isDisabled={isDisabled} placeholder="Wyszukaj po numerze NIP" />
        <Row>
            <AddNewWrapper>
                <Link to="" onClick={e => {
                    e.preventDefault();
                    setOpen(true);
                }}>Dodaj nową</Link>
            </AddNewWrapper>
            <Spacer />
        </Row>
        {open && <CreateCompany open={open} onClose={() => setOpen(false)} onSubmit={e => {
            setOpen(false);
            onChange({
                label: e.name,
                value: e.nip
            });
        }} />}
    </Wrapper>;
}

export default SearchCompany;

const Wrapper = styled.div`

`;

const Row = styled.div`
    display: flex;
    margin-bottom: -30px;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const AddNewWrapper = styled.div`
    padding: 10px 5px;
    font-weight: bold;
`;