import React, { useCallback, useState } from "react";
import Button from "@atlaskit/button/custom-theme-button";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import ShareIcon from "@atlaskit/icon/glyph/share";
import TextArea from "@atlaskit/textarea";
import SearchUsers from "./users/search-users";
import { Checkbox } from "@atlaskit/checkbox";
import SelectDocumentRole from "./documents/select-document-role";
import useAsync from "../hooks/use-async";
import apiClient from "../api/api-client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Share({ documentId, onSuccess }) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [role, setRole] = useState(null);
    const [user, setUser] = useState(null);
    const [message, setMessage] = useState("");
    const [isPrivate, setIsPrivate] = useState(true);
    const { isPending, run } = useAsync();

    const close = () => {
        setIsOpen(false);
        setMessage("");
        setIsPrivate(true);
        setUser(null);
        setRole(null);
    };
    const open = () => setIsOpen(true);

    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        run(apiClient(`document/${documentId}/share`, {
            data: {
                user_id: user?.id,
                role_id: role?.value,
                message: message,
                is_private_message: isPrivate,
            }
        }))
            .then(() => {
                toast.success(t("share_success"));
                onSuccess && onSuccess();
                close();
            })
            .catch(error => {
                toast.error(t("share_error") + error?.message);
            });
    };

    return (
        <>
            <Button iconBefore={<ShareIcon />} onClick={open}></Button>

            <ModalTransition>
                {isOpen && (
                    <Modal
                        actions={[
                            { text: t("share_button"), type: "submit", onClick: handleSubmit, isDisabled: !user || !role },
                            { text: t("share_cancel"), onClick: close },
                        ]}
                        onClose={close}
                        heading={t("share_heading")}
                        scrollBehavior="outside"
                    >
                        <Field id="user" name="user" label={t("share_recipient")}>
                            {() => (
                                <SearchUsers onChange={setUser} isMulti={false} isDisabled={isPending} selectedUser={user} />
                            )}
                        </Field>
                        <Field id="role" name="role" label={t("share_role")}>
                            {() => (
                                <SelectDocumentRole onChange={setRole} isDisabled={isPending} />
                            )}
                        </Field>
                        <Field id="message" name="message" label={t("share_message")}>
                            {() => (
                                <TextArea
                                    onChange={e => setMessage(e.target.value)}
                                    isDisabled={isPending}
                                    value={message}
                                    placeholder={t("share_message_placeholder")}
                                />
                            )}
                        </Field>
                        <Field id="private" name="private" label="">
                            {() => (
                                <Checkbox label={t("share_private")} isChecked={isPrivate} onChange={() => setIsPrivate(v => !v)} isDisabled={isPending} />
                            )}
                        </Field>
                    </Modal>
                )}
            </ModalTransition>
        </>
    );
}
