import { useState, useCallback } from "react";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";

export default function useOauthLogin() {
    const [error, setError] = useState(null);
    const [status, setStatus] = useState("idle");

    const login = useCallback(() => {
        setStatus("pending");
        setError(null);

        return apiClient("oauthLogin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        })
            .then(async response => {
                setStatus("resolved");

                try {
                    const body = response;
                    const url = body["url"];
                    const nonce = body["nonce"];
                    const timestamp = body["timestamp"];
                    const secret = body["secret"];

                    if (url != null) {
                        sessionStorage.setItem("nonce", nonce);
                        sessionStorage.setItem("timestamp", timestamp);
                        sessionStorage.setItem("secret", secret);
                        window.location.href = url;
                    } else {
                        toast.error("Wystąpił błąd");
                    }
                } catch (e) {
                    toast.error("Wystąpił błąd");
                }

                // window.localStorage.setItem("auth.token", JSON.stringify(response.token));
                return Promise.resolve(response);
            })
            .catch(e => {
                setStatus("rejected");
                setError(e || { message: "Unexpected error" });
                toast.error("Wystąpił błąd");
                return Promise.reject(e);
            });
    }, []);

    return {
        isIdle: status === "idle",
        isOauthPending: status === "pending",
        isError: status === "rejected",
        isSuccess: status === "resolved",
        error,
        oauthLogin: login,
    };
}