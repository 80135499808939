import React from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import apiClient from "../../api/admin-api-client";
import { toast } from "react-toastify";
import useAsync from "../../hooks/use-async";
import { useTranslation } from "react-i18next";

export default function RemoveDocumentType({ documentType, onRemoved, onClose }) {
    const { t } = useTranslation();
    const { run, isPending } = useAsync();
    const remove = () => {
        run(apiClient(`document-types/${documentType?.id}`, { method: "DELETE"}))
            .then(() => {
                toast.success(t("admin_remove_document_type_success"));
                onRemoved && onRemoved();
            })
            .catch(error => toast.error(t("admin_remove_document_type_error") +  error.message));
    };

    return (
        <>
            <ModalTransition>
                <Modal
                    isLoading={isPending}
                    actions={[
                        { text: t("admin_remove_document_type_remove"), onClick: remove, isLoading: isPending },
                        { text: t("admin_remove_document_type_cancel"), onClick: onClose, isDisabled: isPending },
                    ]}
                    onClose={close}
                    heading={t("admin_remove_document_type_confirm")}
                    appearance="danger"
                >
                    <p>
                        {t("admin_remove_document_type_confirm_p")}
                    </p>
                </Modal>
            </ModalTransition>
        </>
    );
}