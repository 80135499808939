import React, { useState } from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Spinner from "../ui/spinner";

export default function DeleteInvite({ organizationId, invite, onRemoved, onClose }) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const remove = () => {
        if (isLoading) return;
        setIsLoading(true);
        apiClient(`adminableOrganizations/${organizationId}/invites/${invite?.id}`, { method: "DELETE" })
            .then(() => {
                toast.success(t("organization_delete_invite_delete_success"));
                onRemoved && onRemoved();
                close();
            })
            .catch(() => {
                toast.error(t("organization_delete_invite_delete_error"));
                setIsLoading(false);
            });
    };

    return (
        <ModalTransition>
            <Modal
                isLoading={isLoading}
                actions={[
                    { text: t("organization_delete_invite_confirm"), onClick: remove },
                    { text: t("organization_delete_invite_success"), onClick: onClose },
                ]}
                onClose={onClose}
                heading={t("organization_delete_invite_delete")}
                appearance="danger"
            >
                {isLoading && <Spinner />}
            </Modal>
        </ModalTransition>
    );
}