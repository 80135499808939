/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import styled from "styled-components";
import Form, { Field, FormFooter } from "@atlaskit/form";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import TextField from "@atlaskit/textfield";

export default function NewRole({ onClose, onSubmit, isError, error, isPending = false }) {
    return <>
        <ModalTransition>
            {open && (
                <Modal
                    onClose={() => {
                        onClose();
                    }}
                    heading="Dodaj nową rolę"
                    scrollBehavior="outside"
                >
                    <Wrapper>
                        <Form onSubmit={onSubmit}>
                            {({ formProps }) => (
                                <form {...formProps}>
                                    <Field name="name" defaultValue="" label="Nazwa" isRequired>
                                        {({ fieldProps }) => <TextField {...fieldProps} isInvalid={isError && error?.errors?.name} isDisabled={isPending} />}
                                    </Field>
                                    <FormFooter>
                                        <ButtonGroup>
                                            <Button type="submit" appearance="primary" isLoading={isPending}>
                                                Dodaj
                                            </Button>
                                        </ButtonGroup>
                                    </FormFooter>
                                </form>
                            )}
                        </Form>
                    </Wrapper>
                </Modal>
            )}
        </ModalTransition>
    </>;
}

const Wrapper = styled.div`
    margin-bottom: 20px;
`;

const FieldWrapper = styled.div`
    margin-top: 20px;
`;