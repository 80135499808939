/* eslint-disable react/display-name */
import React from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import styled from "styled-components";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useDocument from "../../hooks/use-document";
import { useTranslation } from "react-i18next";
import Document from "../documents/document";

export default function CommentSecondaryModal({ open, onClose, documentTypeId, resourceId }) {
    const { document, isPending, fields, reload, error } = useDocument(resourceId, documentTypeId);
    const { t } = useTranslation();

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button type="button" isLoading={isPending} onClick={onClose}>
                    {t("create_document_modal_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    return <>
        <ModalTransition>
            {open && (
                <Modal
                    isLoading={isPending}
                    onClose={() => {
                        onClose();
                    }}
                    components={{
                        Footer: footer,
                    }}
                    scrollBehavior="outside"
                >
                    <Wrapper>
                        {document && <Document document={document} fields={fields} editMode={true} reload={reload} error={error} />}
                    </Wrapper>
                </Modal>
            )}
        </ModalTransition>
    </>;
}

const Wrapper = styled.div`
    margin-bottom: 20px;
`;