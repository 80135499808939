import Button from "@atlaskit/button";
import React, { useCallback, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";

export default function Signature({ onChange, field, value, editable = false }) {
    const [ dirty, setDirty ] = useState(false);
    const { t } = useTranslation();
    let sigPad = {};

    const onSigCreated = useCallback((ref) => {
        sigPad = ref;
        if (sigPad) {
            sigPad.fromDataURL(value);
        }
    },[]);

    const saveImageFromSigPad = useCallback(() => {
        setDirty(false);
        onChange(sigPad.getTrimmedCanvas().toDataURL("image/png"));
    },[]);

    const clearSigPad = useCallback(() => {
        onChange(null);
        setDirty(false);
        sigPad.clear();
    },[]);
    
    if (!editable) return <SigPad><img src={value} width={500} height={200}/></SigPad>;

    return <div>
        <SigPad>
            <SignatureCanvas 
                ref={onSigCreated} 
                onEnd={() => setDirty(true)} 
                penColor="blue" 
                canvasProps={{width: 500, height: 200}}
            />
        </SigPad>
        <Button appearance="primary" onClick={saveImageFromSigPad} isDisabled={!dirty}>
            {"Zapisz"}
        </Button>
        <Button appearance="subtle" onClick={clearSigPad} isDisabled={!value}>
            {"Wyczyść"}
        </Button>
    </div>;
}

const SigPad = styled.div`
    border: 1px solid #eee;
    height: 200px;
    width: 500px;
`;