import React, { useState } from "react";
import Button from "@atlaskit/button/custom-theme-button";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import TextArea from "@atlaskit/textarea";
import TextField from "@atlaskit/textfield";
import styled from "styled-components";

export default function CreateCompany({ open, onClose, onSubmit }) {
    const [ nip, setNip ] = useState("");
    const [ loaded, setLoaded ] = useState(false);
    const [ name, setName ] = useState("");
    const [ street, setStreet ] = useState("");
    const [ postcode, setPostcode ] = useState("");
    const [ city, setCity ] = useState("");

    const loadGusData = () => {
        setLoaded(true);
        setName("FrameCoders Sp. z o.o.");
        setStreet("Dąbrowskiego 77a");
        setPostcode("60-529");
        setCity("Poznań");
    };

    const submit = e => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit({
            name,
            nip,
            street,
            postcode,
            city
        });
    };

    return <>
        <ModalTransition>
            {open && (
                <Modal
                    actions={[
                        { text: "Zapisz", type: "submit", isDisabled: !nip, onClick: submit },
                        { text: "Anuluj", onClick: onClose },
                    ]}
                    onClose={onClose}
                    heading="Dodaj nową firmę"
                    scrollBehavior="outside"
                >
                    <Field id="nip" name="nip" label="Numer NIP">
                        {() => <TextField value={nip} onChange={e => setNip(e.target.value)} />}
                    </Field>
                    <ButtonWrapper>
                        <Button appearance="primary" isDisabled={!nip} onClick={() => loadGusData()}>Pobierz dane firmy z GUS</Button>
                    </ButtonWrapper>
                    {loaded && <>
                        <Field id="name" name="name" label="Nazwa firmy">
                            {() => <TextField value={name} onChange={e => setName(e.target.value)} />}
                        </Field>
                        <Field id="street" name="street" label="Ulica i nr">
                            {() => <TextField value={street} onChange={e => setStreet(e.target.value)} />}
                        </Field>
                        <Row>
                            <div style={{ width: "60%", paddingRight: "10px" }}>
                                <Field id="postcode" name="postcode" label="Kod pocztowy">
                                    {() => <TextField value={postcode} onChange={e => setPostcode(e.target.value)} />}
                                </Field>
                            </div>
                            <div style={{ width: "60%" }}>
                                <Field id="city" name="city" label="Miejscowość">
                                    {() => <TextField value={city} onChange={e => setCity(e.target.value)} />}
                                </Field>
                            </div>
                        </Row>
                    </>}
                </Modal>
            )}
        </ModalTransition>
    </>;
}

const ButtonWrapper = styled.div`
    margin: 10px 0;
`;

const Row = styled.div`
    display: flex;
`;