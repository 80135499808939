/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import TextArea from "@atlaskit/textarea";
import SelectWorker from "./select-worker";

export default function EditWorkflow({ workflow, onClose, onUpdated }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [ name, setName ] = useState(workflow.name);
    const [ worker, setWorker ] = useState({
        id: workflow.worker,
        label: workflow.worker,
        value: workflow.worker
    });
    const [ config, setConfig ] = useState(JSON.stringify(workflow.configuration ?? {}));
    const { t } = useTranslation();

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit} isDisabled={!name || !worker || !config}>
                    {t("admin_edit_workflow_save")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_edit_workflow_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        let configData = null;
        try {
            configData = JSON.parse(config);
        } catch (e) {
            toast.error(t("invalid_json"));
            return;
        }

        run(apiClient(`workflows/${workflow.id}`, { method: "PATCH", data: {
            name,
            worker: worker?.value,
            config: configData,
        }}))
            .then(response => {
                onUpdated(response);
                toast.success(t("admin_edit_workflow_success"));
                onClose();
            })
            .catch(() => {
                toast.error(t("admin_edit_workflow_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_edit_workflow_heading")}
            scrollBehavior="outside-wide"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_edit_workflow_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="name" label={t("admin_edit_workflow_name")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={name} onChange={e => setName(e.target.value)} isInvalid={error?.errors?.name} autoComplete={false} />}
            </Field>
            <Field name="worker" label={t("admin_edit_workflow_worker")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <SelectWorker {...fieldProps} value={worker} onChange={e => setWorker(e)} />}
            </Field>
            <Field name="config" label={t("admin_edit_workflow_config")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextArea
                    {...fieldProps}
                    isMonospaced
                    value={config}
                    onChange={e => setConfig(e?.target?.value)}
                    minimumRows={5}
                />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;