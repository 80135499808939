import React, { useCallback, useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import UserPicker from "@atlaskit/user-picker";
import TextField from "@atlaskit/textfield";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import { Checkbox } from "@atlaskit/checkbox";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function AddGroupUser({ groupId, onAdded, onHide }) {
    const [name, setName] = useState("");
    const [user, setUser] = useState(null);
    const [supervisor, setSupervisor] = useState(false);
    const { run, isPending } = useAsync();
    const { t } = useTranslation();

    const createUser = () => {
        run(apiClient("group/user", { data: {
            user_id: user?.id,
            group_id: groupId,
            position: name,
            supervisor: supervisor,
        }}))
            .then(() => {
                toast.success(t("add_group_user_success"));
                onAdded(user);
                onHide();
            })
            .catch(() => {
                toast.error(t("add_group_user_error"));
            });
    };

    const search = query => apiClient(`users?email=${query || ""}`)
        .then(response => {
            return Promise.resolve(response.data.map(i => ({
                id: i.id,
                name: i.first_name + " " + i.last_name,
                type: "user",
                avatarUrl: i.avatar_url,
                lozenge: i.email,
            })));
        })
        .catch(() => Promise.resolve([]));

    return (
        <>
            <ModalTransition>
                <Modal
                    isLoading={isPending}
                    scrollBehavior="outside"
                    actions={[
                        { text: t("add_group_user_assign_user"), type: "submit", isDisabled: !user || !name, isLoading: isPending, onClick: e => {
                            e.preventDefault();
                            createUser();
                        }},
                        { text: t("add_group_user_close"), onClick: onHide },
                    ]}
                    onClose={onHide}
                    heading={t("add_group_user_heading")}
                >
                    <Field id="name" name="name" label={t("add_group_user_position")}>
                        {() => (
                            <TextField onChange={e => setName(e.target.value)} />
                        )}
                    </Field>
                    <Field id="user" name="user" label={t("add_group_user_user")}>
                        {() => (
                            <UserPicker
                                fieldId="user"
                                placeholder={t("add_group_user_search_placeholder")}
                                onChange={setUser}
                                loadOptions={search}
                            />
                        )}
                    </Field>
                    <FormField>
                        <Checkbox label={t("add_group_user_manages_department")}
                            isChecked={supervisor} 
                            onChange={() => setSupervisor(v => !v)} 
                            isDisabled={isPending} />
                    </FormField>
                </Modal>
            </ModalTransition>
        </>
    );
}

const FormField = styled.div`
    padding: 20px 0 0;
`;