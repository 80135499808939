import { useState, useCallback } from "react";
import apiClient from "../../api/api-client";

export default function useLogin() {
    const [error, setError] = useState(null);
    const [status, setStatus] = useState("idle");

    const login = useCallback(data => {
        setStatus("pending");
        setError(null);

        return apiClient("login", { data })
            .then(response => {
                setStatus("resolved");
                window.localStorage.setItem("auth.token", JSON.stringify(response.token));
                return Promise.resolve(response);
            })
            .catch(e => {
                setStatus("rejected");
                setError(e || { message: "Unexpected error" });
                return Promise.reject(e);
            });
    }, []);

    const reset = useCallback(data => {
        setStatus("pending");
        setError(null);

        return apiClient("password/email", { data })
            .then(response => {
                setStatus("resolved");
                return Promise.resolve(response);
            })
            .catch(e => {
                setStatus("rejected");
                setError(e || { message: "Unexpected error" });
                return Promise.reject(e);
            });
    }, []);

    const setNewPassword = useCallback((token, data) => {
        setStatus("pending");
        setError(null);

        return apiClient("password/reset", { data: {
            token,
            ...data,
        }})
            .then(response => {
                setStatus("resolved");
                return Promise.resolve(response);
            })
            .catch(e => {
                setStatus("rejected");
                setError(e || { message: "Unexpected error" });
                return Promise.reject(e);
            });
    }, []);

    return {
        isIdle: status === "idle",
        isPending: status === "pending",
        isError: status === "rejected",
        isSuccess: status === "resolved",
        error,
        login,
        reset,
        setNewPassword,
    };
}