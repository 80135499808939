/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function CreateCategory({ onClose, onAdded, module, categories }) {
    const maxPosition = Math.max(...categories?.map(i => i?.position ?? 0));
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [ name, setName ] = useState("");
    const [ icon, setIcon ] = useState("");
    const [ position, setPosition ] = useState(maxPosition >= 0 ? maxPosition + 1 : 0);
    const { t } = useTranslation();

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit}>
                    {t("admin_create_category_create")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_create_category_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        run(apiClient("categories", { data: {
            name,
            module_id: module?.id,
            config: {
                version: "0.0.1"
            },
            position,
            icon,
        }}))
            .then(response => {
                onAdded(response);
                toast.success(t("admin_create_category_success"));
                onClose();
            })
            .catch(() => {
                toast.error(t("admin_create_category_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_create_category_heading")}
            scrollBehavior="inside-wide"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_create_category_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="name" label={t("admin_create_category_name")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={name} onChange={e => setName(e.target.value)} isInvalid={error?.errors?.name} autoComplete={false} />}
            </Field>
            <Field name="position" label={t("create_module_position")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={position} onChange={e => setPosition(e.target.value)} isInvalid={error?.errors?.position} autoComplete={false} />}
            </Field>
            <Field name="icon" label={t("create_module_icon")} isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={icon} onChange={e => setIcon(e.target.value)} isInvalid={error?.errors?.icon} autoComplete={false} />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;