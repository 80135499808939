import React from "react";
import styled from "styled-components";
import { default as AtlassianSpinner } from "@atlaskit/spinner";

function AbsoluteSpinner() {
    return <Wrapper data-testid="spinner">
        <AtlassianSpinner size="medium" />
    </Wrapper>;
}

export default AbsoluteSpinner;

const Wrapper = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 50px;
    bottom: 50px;
`;