/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import TextArea from "@atlaskit/textarea";
import TextField from "@atlaskit/textfield";
import SelectRoles from "../roles/select-roles";
import Select from "@atlaskit/select";
import { useTranslation } from "react-i18next";

export default function EditPdfConfig({ onClose, onSuccess, documentTypeId, pdf }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [ config, setConfig ] = useState(pdf?.pattern);
    const [ name, setName ] = useState(pdf?.name);
    const [ roles, setRoles ] = useState(pdf?.attached_roles?.map(i => ({
        content: i?.name,
        value: i?.id,
    })));
    const [ status, setStatus ] = useState(pdf?.status);
    const { t } = useTranslation();

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit} isDisabled={!name || !config}>
                    {t("admin_edit_pdf_config_save")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_edit_pdf_config_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        run(apiClient(`print-patterns/${pdf?.id}`, { data: {
            document_type_id: documentTypeId,
            name,
            pattern: config,
            status,
        }, method: "PATCH" }))
            .then(response => {
                const previousRoles = pdf?.attached_roles?.map(i => i?.id) ?? [];
                const currentRoles = roles?.map(i => i?.value);
                const rolesToAttach = currentRoles?.filter(r => !previousRoles?.includes(r));
                const rolesToDetach = previousRoles?.filter(r => !currentRoles?.includes(r));

                Promise.all([
                    ...rolesToAttach?.map(r => {
                        return apiClient(`print-patterns/${pdf?.id}/roles/${r}`, { method: "POST"});
                    }),
                    ...rolesToDetach?.map(r => {
                        return apiClient(`print-patterns/${pdf?.id}/roles/${r}`, { method: "DELETE"});
                    })
                ]).then(() => {
                    toast.success(t("admin_edit_pdf_config_success"));
                    onSuccess();
                }).catch(() => {
                    toast.error(t("admin_edit_pdf_config_error"));
                    onSuccess();
                });

                // todo

            //     Promise.all(roles?.map(r => {
            //         apiClient(`print-patterns/${response?.id}/roles/${r?.value}`, { method: "POST"});
            //     }))
            //         .then(() => {
            //             toast.success("Konfiguracja została zapisana");
            //             onSuccess();
            //         })
            //         .catch(() => {
            //             toast.error("Nie udało się przypisać roli");
            //             onSuccess();
            //         });
            // })
            // .catch(() => {
            //     toast.error("Nie udało się zapisać konfiguracji");
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_edit_pdf_config_heading")}
            scrollBehavior="outside"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_edit_pdf_config_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="name" label={t("admin_edit_pdf_config_name")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField
                    {...fieldProps}
                    isMonospaced
                    isDisabled={isPending}
                    value={name}
                    onChange={e => setName(e?.target?.value)}
                />}
            </Field>
            <Field name="config" label={t("admin_edit_pdf_config_config")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextArea
                    {...fieldProps}
                    isMonospaced
                    isDisabled={isPending}
                    value={config}
                    onChange={e => setConfig(e?.target?.value)}
                    minimumRows={5}
                />}
            </Field>
            <Field name="roles" label={t("admin_edit_pdf_config_roles")} autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <SelectRoles onChange={setRoles} ids={pdf?.attached_roles?.map(i => i?.id)} />}
            </Field>
            <Field name="status" label={t("admin_edit_pdf_config_status")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <Select 
                    options={[
                        { label: t("admin_edit_pdf_config_draft"), value: 0 },
                        { label: t("admin_edit_pdf_config_production"), value: 1 },
                    ]} 
                    isDisabled={isPending}
                    defaultValue={pdf?.status ? { label: t("admin_edit_pdf_config_production"), value: 1 }: { label: t("admin_edit_pdf_config_draft"), value: 0 }}
                    placeholder={t("admin_edit_pdf_config_status")} onChange={e => {
                        setStatus(e?.value);
                    }} />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;

