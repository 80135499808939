import Button from "@atlaskit/button";
import React, { useState } from "react";
import styled from "styled-components";
import AddItemIcon from "@atlaskit/icon/glyph/add-item";
import TextField from "@atlaskit/textfield";
import useAsync from "../../hooks/use-async";
import { Field } from "@atlaskit/form";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function AddGroupForm({ groupId, onAdded, onHide }) {
    const [ name, setName ] = useState("");
    const { isPending, run } = useAsync();
    const { t } = useTranslation();

    function handleSubmit() {
        run(apiClient("groups", { data: {
            name,
            parent_id: groupId ?? 0,
        }}))
            .then(() => {
                toast.success(t("admin_structure_group_success"));
                setName("");
                onAdded();
                onHide();
            }).catch(() => toast.error(t("admin_structure_group_error")));
    }

    return <AddFormWrapper>
        <AddForm onSubmit={handleSubmit}>
            <AddFormRow>
                <Field label={t("admin_structure_group_name")} name="name">
                    {({ fieldProps }) => (
                        <TextField {...fieldProps} value={name} onChange={e => setName(e.target.value)} />
                    )}
                </Field>
            </AddFormRow>
            <ButtonsWrapper>
                <Spacer />
                <ButtonWrapper>
                    <Button appearance="default" onClick={onHide} isDisabled={isPending}>
                        {t("admin_structure_group_cancel")}
                    </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                    <Button appearance="primary" isDisabled={!name} isLoading={isPending} onClick={handleSubmit}>
                        {t("admin_structure_group_add")}
                    </Button>
                </ButtonWrapper>
            </ButtonsWrapper>
        </AddForm>
    </AddFormWrapper>;
}

export default AddGroupForm;


const AddFormWrapper = styled.div`
    padding: 20px;
    border-radius: 3px;
    background: white;
    margin: 20px 0;
`;

const AddForm = styled.form`
display: block;
width: 100%;
`;

const AddFormRow = styled.div`
width: 100%;
margin-bottom: 10px;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
    display: flex;
`;

const ButtonWrapper = styled.div`
    margin-left: 10px;
`;