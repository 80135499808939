/* eslint-disable react/display-name */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import Form from "@atlaskit/form";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import prepareFormData from "../../utils/prepare-form-data";
import { toast } from "react-toastify";
import styled from "styled-components";
import SectionMessage from "@atlaskit/section-message";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useDocument from "../../hooks/use-document";
import LinkedDocumentsOnEvent from "./linked-documents-on-event";
import useDocumentFields from "../../hooks/use-document-fields";
import FormFieldUncontrolled from "../forms/form-field-uncontrolled";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import useDocumentLinkTypes from "../../hooks/use-document-link-types";
import toBoolean from "../../utils/to-boolean";
import { useTranslation } from "react-i18next";

export default function CreateEventModal({ open, onClose, onSubmit, onEdit, documentTypeId, resourceId = null, startDate = null, endDate = null, documentLinkTypeId = null, eventId = null }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [links, setLinks] = useState([]);
    const { isPending: isPendingFields, fields } = useDocumentFields(documentTypeId);
    const { document: resourceDocument, isPending: isPendingDocument } = useDocument(resourceId);
    const { types } = useDocumentLinkTypes();
    const { t } = useTranslation();

    useEffect(() => {
        if (!resourceDocument?.id) return;
        const uuid = uuidv4();

        setLinks(l => ([
            {
                linked_document_id: resourceDocument?.id,
                link_type_id: 3,
                linked_document: resourceDocument,
                id: uuid,
                link_id: uuid,
            }
        ]));
    }, [resourceDocument?.id]);

    const initialValues = useMemo(() => ({
        start_date: startDate,
        end_date: endDate,
    }), [startDate, endDate]);

    const handleSubmit = data => {
        const formData = prepareFormData(data);
        formData.start_date = format(Date.parse(formData.start_date), "yyyy-MM-dd");
        formData.end_date = format(Date.parse(formData.end_date), "yyyy-MM-dd");


        if (eventId) {
            return editEvent(formData);
        }

        run(apiClient(`workflow/document-type/${documentTypeId}/document`, { data: formData }))
            .then(response => {
                links.map(link => {
                    run(apiClient(`document/${response.id}/link/${link?.linked_document?.id}/type/${link?.link_type_id}`, {
                        method: "POST"
                    }))
                        .then(() => {

                        })
                        .catch(error => { });
                });
                onSubmit(response);
            })
            .catch(() => {
                toast.error(t("create_event_modal_document_create_error"));
            });
    };

    const editEvent = (formData) => {
        run(apiClient(`workflow/document/${eventId}`, { data: formData }))
            .then(() => {
                onEdit && onEdit();
            })
            .catch(error => {
                toast.error(error?.message || t("create_event_modal_document_save_error"));
            });
    };

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="submit" isLoading={isPending}>
                    {eventId ? t("create_event_modal_save") : t("create_event_modal_create")}
                </Button>
                <Button type="button" isLoading={isPending} onClick={onClose}>
                    {t("create_event_modal_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const onRemoved = useCallback(id => {
        setLinks(l => l.filter(i => i.id !== id));
    }, []);

    const onAdded = useCallback(data => {
        setLinks(l => ([
            ...l,
            {
                ...data,
                link_id: data.id,
            }
        ]));
    }, []);

    return <>
        <ModalTransition>
            {open && (
                <Modal
                    isLoading={isPendingFields || isPending || isPendingDocument}
                    onClose={() => {
                        if (isPending) return;
                        onClose();
                    }}
                    heading={eventId ? t("create_event_modal_edit_heading") : t("create_event_modal_create_heading")}
                    components={{
                        Container: ({ children, className }) => (
                            <Form onSubmit={handleSubmit}>
                                {({ formProps }) => (
                                    <form {...formProps}>
                                        {children}
                                    </form>
                                )}
                            </Form>
                        ),
                        Footer: footer,
                    }}
                    scrollBehavior="outside"
                >
                    <Wrapper>
                        {isCreatingError && <SectionMessageWrapper>
                            <SectionMessage title={t("create_event_modal_error_heading")} appearance="error">
                                <p>{error?.message}</p>
                                {error?.errors && <ul>
                                    {Object.keys(error.errors).map(key => (
                                        <li key={key}>{error.errors[key]}</li>
                                    ))}
                                </ul>}
                            </SectionMessage>
                        </SectionMessageWrapper>}
                        {fields?.filter(f => !toBoolean(f.hidden) && !toBoolean(f.read_only)).filter(f => toBoolean(f.quick_form)).map(field => {
                            const inputProps = {
                                key: field.id,
                                name: field.name,
                                label: field?.field?.type === "checkbox" ? null : field.label,
                                isRequired: toBoolean(field.required),
                                defaultValue: field.field.type === "date" ? format(new Date(), "yyyy-MM-dd") : undefined,
                                isInvalid: error && error?.errors ? error?.errors[field.name] : false,
                                options: field?.options || [],
                                table_column: field?.table_column || [],
                                isDisabled: toBoolean(field?.read_only),
                            };

                            if (initialValues[field.name]) {
                                inputProps.defaultValue = initialValues[field.name];
                            }

                            return <FormFieldUncontrolled key={field.id} inputProps={inputProps} field={field} />;
                        })}

                        {types && <LinkedDocumentsOnEvent types={types} documentTypeId={documentTypeId} links={links} onRemoved={onRemoved} onAdded={onAdded} />}
                    </Wrapper>
                </Modal>
            )}
        </ModalTransition>
    </>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 50px;
    font-size: 13px;
`;

const Wrapper = styled.div`
    margin-bottom: 20px;
`;
