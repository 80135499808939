/* eslint-disable react/display-name */
import React, { useState, useEffect, useMemo } from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import NewDocumentForm from "./new-document-form";
import Form from "@atlaskit/form";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import prepareFormData from "../../utils/prepare-form-data";
import { toast } from "react-toastify";
import styled from "styled-components";
import SectionMessage from "@atlaskit/section-message";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useDocument from "../../hooks/use-document";
import LinkedDocuments from "./linked-documents";
import { Link } from "react-router-dom";
import slugify from "slugify";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import useDocumentFields from "../../hooks/use-document-fields";
import FormFieldUncontrolled from "../forms/form-field-uncontrolled";
import toBoolean from "../../utils/to-boolean";
import { format, parse, subDays } from "date-fns";
import { useTranslation } from "react-i18next";
import useSpace from "../../hooks/use-space";
import { NewPageInner } from "../../pages/new";
import { Drawer } from "../../pages/space";

function transformDate(originalDate) {
    if (!originalDate) return originalDate;

    const year = originalDate.getFullYear();
    const month = originalDate.getMonth();
    const day = originalDate.getDate();

    const newDate = new Date(Date.UTC(year, month, day));
    return newDate;
    // return format(newDate, "yyyy-MM-dd");
}

export default function NewCreateEventModal({ slug, open, onClose, onSubmit, onEdit, documentTypeId, resourceId = null, startDate = null, endDate = null, documentLinkTypeId = null, eventId = null, resourceField }) {
    const { document, isPending, reload: reloadDocument } = useDocument(resourceId);
    const { space, isPending: isPendingSpace, isError, forbidden } = useSpace(slug);

    const initialValues = {
        start_date: format(transformDate(startDate), "yyyy-MM-dd"),
        end_date: format(subDays(transformDate(endDate), 1), "yyyy-MM-dd"),
        [resourceField]: resourceId,
    };

    return <Drawer
        space={space}
        initialValues={initialValues}
        onClose={() => onClose()}
        onSaved={() => {
            onClose();
            onSubmit();
        }}
        onReload={() => {
            onClose();
        }}
        renderer={resourceId && document && <div>
            <DocumentLink document={document} />
        </div>}
    />;
}

const SectionMessageWrapper = styled.div`
    margin-top: 50px;
    font-size: 13px;
`;

const Wrapper = styled.div`
    margin-bottom: 20px;
`;

function DocumentLink({ document }) {
    const space = document?.document_type?.space;
    if (!space) return null;
    const nameField = document?.document_type?.fields?.find(i => i.name === "name");
    const name = document?.values[nameField.id];
    if (!name) return null;
    const path = `${slugify(space?.category?.module?.name)}/${space?.category?.slug ?? slugify(space?.category?.name || "")}/${space?.slug}`.toLowerCase();
    return <DocumentLinkWrapper>
        <Link to={`/${path}/${document.id}`}>
            <DocumentLinkRow>
                <Flex>
                    <FlexGrow>
                        <Category>
                            {space?.category?.module?.alias}/
                            {space?.category?.name}/
                            {space?.name}
                        </Category>
                        <div>
                            {name}
                        </div>
                    </FlexGrow>
                </Flex>
            </DocumentLinkRow>
        </Link>
    </DocumentLinkWrapper>;
}

const Flex = styled.div`
    display: flex;
    gap: 5px;
`;

const FlexGrow = styled.div`
    flex: 1;
`;

const DocumentLinkWrapper = styled.div`
    font-size: 14px;
    margin-bottom: 15px;
`;

const DocumentLinkRow = styled.div`
    display: flex;
    align-items: center;
`;

const DocumentLinkIcon = styled.div`
    width: 32px;
`;

const Category = styled.div`
color: rgb(137, 147, 164);
text-decoration: none;
outline: currentcolor none medium;
color: rgb(137, 147, 164);
font-weight: 600;
font-size: 12px;
text-decoration: none;
white-space: nowrap;
`;