/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import TextArea from "@atlaskit/textarea";
import TextField from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import SelectRole from "../roles/select-role";
import SelectRoles from "../roles/select-roles";
import { useTranslation } from "react-i18next";

export default function CreatePdfConfig({ onClose, onSuccess, documentTypeId }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [ config, setConfig ] = useState("{\n}");
    const [ name, setName ] = useState("");
    const [ roles, setRoles ] = useState([]);
    const [ status, setStatus ] = useState(0);
    const { t } = useTranslation();

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit} isDisabled={!name || !config}>
                    {t("admin_create_pdf_config_create")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_create_pdf_config_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        run(apiClient("print-patterns", { data: {
            document_type_id: documentTypeId,
            name,
            pattern: config,
            status,
        }, method: "POST" }))
            .then(response => {
                if (roles?.length === 0) {
                    toast.success(t("admin_create_pdf_config_success"));
                    onSuccess();
                    return;
                }

                Promise.all(roles?.map(r => {
                    apiClient(`print-patterns/${response?.id}/roles/${r?.value}`, { method: "POST"});
                }))
                    .then(() => {
                        toast.success(t("admin_create_pdf_config_success"));
                        onSuccess();
                    })
                    .catch(() => {
                        toast.error(t("admin_create_pdf_config_role_error"));
                        onSuccess();
                    });
            })
            .catch(() => {
                toast.error(t("admin_create_pdf_config_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_create_pdf_config_heading")}
            scrollBehavior="outside"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_create_pdf_config_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="name" label={t("admin_create_pdf_config_name")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField
                    {...fieldProps}
                    isMonospaced
                    isDisabled={isPending}
                    value={name}
                    onChange={e => setName(e?.target?.value)}
                />}
            </Field>
            <Field name="config" label={t("admin_create_pdf_config_config")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextArea
                    {...fieldProps}
                    isMonospaced
                    isDisabled={isPending}
                    value={config}
                    onChange={e => setConfig(e?.target?.value)}
                    minimumRows={5}
                />}
            </Field>
            <Field name="roles" label={t("admin_create_pdf_config_roles")} autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <SelectRoles onChange={setRoles} />}
            </Field>
            <Field name="status" label={t("admin_create_pdf_config_status")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <Select 
                    options={[
                        { label: t("admin_create_pdf_config_draft"), value: 0 },
                        { label: t("admin_create_pdf_config_production"), value: 1 },
                    ]} 
                    isDisabled={isPending}
                    defaultValue={{ label: t("admin_create_pdf_config_draft"), value: 0 }}
                    placeholder={t("admin_create_pdf_config_status")} onChange={e => {
                        setStatus(e?.value);
                    }} />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;

