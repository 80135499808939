import React, { useEffect, useState } from "react";
import SearchUsers from "./search-users";
import apiClient from "../../api/api-client";


export default function SearchUsersField({ onChange, value, isMulti = false }) {
    const [selected, setSelected] = useState([]);

    console.log("SEARCH USERS BY FIELD", isMulti, value);

    useEffect(() => {
        if (!value) {
            setSelected([]);
            return;
        }
        apiClient("usersByIDs", {
            method: "POST",
            data: {
                ids: isMulti ? value : [value],
            },
        })
            .then(data => {
                setSelected(data?.map(i => ({
                    id: i.id,
                    name: i.first_name + " " + i.last_name,
                    type: "user",
                    avatarUrl: i.avatar_url,
                })));
            });
    }, [value]);

    return <SearchUsers
        isMulti={isMulti}
        selectedUser={isMulti ? selected : selected?.[0]}
        onChange={data => {
            if (isMulti) {
                onChange((data ?? [])?.map(u => u?.id));
            } else {
                onChange(data?.id);
            }
        }}
    />;
}