import React, { useEffect, useState } from "react";
import Spinner from "../../components/ui/spinner";
import AdminLayout from "../../layouts/admin-layout";
import PageHeader from "@atlaskit/page-header";
import styled from "styled-components";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import DynamicTable from "@atlaskit/dynamic-table";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { Link, useParams } from "react-router-dom";
import useAdminCategories from "../../hooks/use-admin-categories";
import useAdminSpaces from "../../hooks/use-admin-spaces";
import CreateSpace from "../../components/spaces/create-space";
import RemoveSpace from "../../components/spaces/remove-space";
import useAdminModules from "../../hooks/use-admin-modules";
import EditSpace from "../../components/spaces/edit-space";
import { useTranslation } from "react-i18next";
import ImportModal from "../../components/import/space-import-modal";
import IconWidget from "../../components/widgets/icon-widget";

function AdminCategory() {
    const { module, category } = useParams();
    const { isPending, spaces, reload } = useAdminSpaces(module, category);
    const [ open, setOpen ] = useState(false);
    const [ importOpen, setImportOpen ] = useState(false);
    const { modules } = useAdminModules();
    const currentModule = modules?.find(i => i?.slug?.toLowerCase() === module?.toLowerCase());
    const { categories } = useAdminCategories(currentModule);
    const [ itemToRemove, setItemToRemove ] = useState(null);
    const [ itemToEdit, setItemToEdit ] = useState(null);
    const { t } = useTranslation();

    const currentCategory = categories?.find(c => c.slug === category);

    if (isPending) {
        return <AdminLayout><Spinner /></AdminLayout>;
    }

    const head = {
        cells: [
            { key: "name", content: t("admin_category_column_name"), isSortable: false },
            { key: "actions", content: null, isSortable: false },
        ]
    };

    const rows = spaces?.map(s => ({
        key: s.id,
        cells: [
            { key: "name", content: <Flex>
                {s?.icon ? <IconWrapper>
                    <IconWidget name={s?.icon} size="16px" />
                </IconWrapper> : null}
                <Link to={`/admin/modules/${module}/${category}/${s.slug}`}>{s.name}</Link>
            </Flex>
            },
            { key: "actions", content: 
            <DropdownMenu position="bottom right" triggerType="button">
                <DropdownItemGroup>
                    <DropdownItem onClick={() => setItemToEdit(s)}>
                        {t("admin_category_edit")}
                    </DropdownItem>
                    <DropdownItem onClick={() => setItemToRemove(s)}>
                        {t("admin_category_remove")}
                    </DropdownItem>
                </DropdownItemGroup>
            </DropdownMenu>
            }
        ],
    })) || [];

    const actionsContent = <ButtonGroup>
        {<Button onClick={() => setImportOpen(true)}>
            {t("admin_category_import")}
        </Button>}
        {currentCategory && <Button appearance="primary" onClick={() => setOpen(true)}>
            {t("admin_category_create")}
        </Button>}
    </ButtonGroup>;

    return <AdminLayout>
        <PageHeader actions={actionsContent}>
            {t("admin_category_heading")}
        </PageHeader>
        <Wrapper>
            <Left>
                <DynamicTable
                    head={head} 
                    rows={rows}
                    loadingSpinnerSize="large"
                    isLoading={isPending}
                />
            </Left>
        </Wrapper>
        {importOpen && <ImportModal categoryId={currentCategory?.id} onSuccess={() => { 
            setImportOpen(false);
            reload();
        }} onClose={() => setImportOpen(false)} />}
        {currentCategory && open && <CreateSpace categoryId={currentCategory?.id} open={open} onClose={() => setOpen(false)} onAdded={() => reload()} spaces={spaces} />}
        {itemToRemove && <RemoveSpace space={itemToRemove} onClose={() => setItemToRemove(null)} onRemoved={() => {
            setItemToRemove(null);
            reload();
        }} />}
        {itemToEdit && <EditSpace category={currentCategory} space={itemToEdit} onClose={() => setItemToEdit(null)} onSaved={() => {
            setItemToEdit(null);
            reload();
        }} />}
    </AdminLayout>;
}

export default AdminCategory;

const Wrapper = styled.div`
    display: flex;
`;

const Left = styled.div`
    width: 100%;
`;


const Flex = styled.div`
    display: flex;
`;

const IconWrapper = styled.div`
    margin-right: 5px;
    width: 20px;
`;