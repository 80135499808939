import React, { useState } from "react";
import styled from "styled-components";
import useDocumentLinkTypes from "../../hooks/use-document-link-types";
import Spinner from "../ui/spinner";
import EmptyState from "@atlaskit/empty-state";
import Button from "@atlaskit/button";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import LinkDocumentFormOnEvent from "./link-document-form-on-event";
import { Link } from "react-router-dom";
import spacePath from "../../utils/space-path";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { toast } from "react-toastify";
import apiClient from "../../api/api-client";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import Branch16Icon from "@atlaskit/icon-object/glyph/branch/16";
import Subtask16Icon from "@atlaskit/icon-object/glyph/subtask/16";
import slugify from "slugify";
import useGlobalConfig from "../../hooks/use-global-config";

function LinkedDocumentsOnEvent({ onAdded, types, links, onRemoved, documentTypeId }) {
    const [ addFormOpen, setAddFormOpen ] = useState(false);
    const { config: typesMapConfig } = useGlobalConfig("types_map");

    let allGroups = {};

    links.reduce((acc, link) => {
        let name;

        if (link?.linked_document?.document_type?.fields) {
            const nameField = link?.linked_document?.document_type?.fields?.find(i => i.name === "name");
            name = link?.linked_document?.values[nameField.id];
        } else {
            name = link?.linked_document?.values?.find(i => i?.name === "name")?.value;
        }

        const linkData = {
            name,
            space: link?.linked_document?.document_type?.space,
            ...link?.linked_document,
            link_id: link.id,
            id: link.id,
            document_id: link?.linked_document?.id,
        };

        allGroups[link.link_type_id] = allGroups[link.link_type_id] ? [ 
            ...allGroups[link.link_type_id],
            linkData,
        ] : [ linkData ];
        return acc;
    }, {});

    return <Wrapper>
        <Heading>
            <h4>Powiązania</h4>
            <Button iconBefore={<EditorAddIcon />} onClick={() => setAddFormOpen(true)}></Button>
        </Heading>
        {addFormOpen && <LinkDocumentFormOnEvent documentTypeId={documentTypeId} onCancel={() => setAddFormOpen(false)} onSuccess={data => {
            setAddFormOpen(false);
            onAdded(data);
        }} />}
        {(links?.length) === 0 && !addFormOpen && <EmptyState header="" description="" primaryAction={<Button appearance="primary" onClick={() => setAddFormOpen(true)}>Dodaj powiązany dokument</Button>} />}
        {Object.keys(allGroups).length > 0 && <GroupsWrapper>
            {Object.keys(allGroups).map(groupId => (
                <Group key={groupId}>
                    <GroupHeading>{types && types[groupId]}</GroupHeading>
                    <GroupWrapper>
                        <GroupContent>
                            {allGroups[groupId].map(link => (
                                <DocumentLink key={link.id}>
                                    {groupId == typesMapConfig?.inferiorId && <Page16Icon />}
                                    {groupId == typesMapConfig?.superiorId && <Branch16Icon />}
                                    {groupId == typesMapConfig?.relatedId && <Subtask16Icon />}
                                    <Category>
                                        {link?.space?.category?.module?.alias}/
                                        {link?.space?.category?.name}/
                                        {link?.space?.name} 
                                    </Category>
                                    <Link to={`/${slugify(link?.space?.category?.module?.name || "")}/${link?.space?.category?.slug ?? slugify(link?.space?.category?.name || "")}/${link?.space?.slug}/${link.document_id}`.toLowerCase()}>
                                        <LinkStyle>{link.name}</LinkStyle>
                                    </Link>
                                    <Spacer />
                                    <DropdownMenu position="bottom right" triggerType="button">
                                        <DropdownItemGroup>
                                            <DropdownItem onClick={() => {
                                                onRemoved(link?.id);
                                            }}>
                                            Usuń
                                            </DropdownItem>
                                        </DropdownItemGroup>
                                    </DropdownMenu>
                                </DocumentLink>
                            ))}
                        </GroupContent>
                    </GroupWrapper>
                </Group>
            ))}
        </GroupsWrapper>}
    </Wrapper>;
}

export default LinkedDocumentsOnEvent;

const Wrapper = styled.div`
    margin-top: 50px;
    padding-top: 30px;
    border-top: 1px solid #eee;
`;

const Heading = styled.div`
    display: flex;

    &>h4 {
        flex-grow: 1;
    }
`;


const Group = styled.div`
margin: 20px 0;
`;

const GroupHeading = styled.h6`
    color: #6B778C;
`;

const GroupContent = styled.ul`
box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px 0px;
background: rgb(250, 251, 252) none repeat scroll 0% 0%;
border-radius: 3px;
padding: 0px;
`;

const DocumentLink = styled.div`
background: rgb(255, 255, 255) none repeat scroll 0% 0%;
border-radius: 3px;
cursor: pointer;
display: flex;
position: relative;
height: 40px;
padding: 0px 8px;
border-bottom: 1px solid rgb(235, 236, 240);
display: flex;
-moz-box-align: center;
align-items: center;
`;

const Category = styled.span`
color: rgb(137, 147, 164);
text-decoration: none;
outline: currentcolor none medium;
margin-left: 8px;
color: rgb(137, 147, 164);
font-weight: 600;
font-size: 12px;
text-decoration: none;
white-space: nowrap;
margin-right: 10px;
`;

const Spacer = styled.div`
flex-grow: 1;
`;

const GroupWrapper = styled.div`
    margin-top: 10px;
`;

const GroupsWrapper = styled.div`
    margin-top: 20px;
    padding: 5px 25px 10px;
    background: rgba(0,0,0,0.02);
    border-radius: 5px;
`;

const LinkStyle = styled.span`
    font-weight: 600;
`;