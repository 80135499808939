import useAdminRoles from "../../hooks/use-admin-roles";
import MultiSelect from "@atlaskit/multi-select";
import React, { useState } from "react";
import Spinner from "../ui/spinner";
import { useTranslation } from "react-i18next";

export default function SelectRoles({ onChange, ids = [] }) {
    const { roles, isPending } = useAdminRoles();
    const { t } = useTranslation();

    if (isPending) {
        return <Spinner />;
    }

    return <MultiSelect 
        isDisabled={isPending}
        placeholder={t("select_roles_placeholder")}
        shouldFitContainer
        onSelectedChange={e => {
            onChange && onChange(e?.items);
        }}
        defaultSelected={roles?.filter(r => ids?.includes(r?.id))?.map(r => ({
            content: r?.name,
            value: r?.id,
        })) || []}
        items={roles?.map(r => ({
            content: r?.name, 
            value: r?.id,
        }))} />;
}