import { useCallback, useEffect } from "react";
import apiClient from "../api/admin-api-client";
import useAsync from "../hooks/use-async";

export default function useAdminDocumentTypes(limit = 10) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        reload();
    }, []);

    const reload = useCallback(() => run(apiClient("document-types?search=&per_page=" + limit)).catch(() => { }));

    return {
        data: data?.data || [],
        dataMap: data?.data?.reduce((acc, i) => {
            acc[i.id] = i;
            return acc;
        }, {}),
        meta: data?.meta || {},
        isPending: isIdle || isPending,
        isError,
        isSuccess,
        reload,
    };
}