import { useEffect } from "react";
import apiClient from "../api/api-client";
import useAsync from "../hooks/use-async";

export default function useDocumentStatuses(documentTypeId) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        if (!documentTypeId) return;
        run(apiClient(`document-type/${documentTypeId}/status/values`)).catch(() => { });
    }, [ documentTypeId ]);

    return {
        statuses: data,
        isPending: isIdle || isPending,
        isError,
        isSuccess,
    };
}
