import React from "react";
import DirectNotifications from "../notifications/direct-notifications";
import WatchingNotifications from "../notifications/watching-notifications";

function DashboardNotifications({ module = null }) {
    return <>
        <DirectNotifications module={module} />
    </>;
}

export default DashboardNotifications;