import React from "react";
import Helmet from "react-helmet";
const domain = window.location.hostname;

const uwrDomains = ["uwr.app.erpspace.pl", "simulation.akta.american-systems.pl"];


function Favicon() {
    if (domain == "app.vseli.com" || domain == "staging.vseli.com") {
        return <Helmet>
            <link rel="apple-touch-icon" sizes="180x180" href="/vseli/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/vseli/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/vseli/favicon-16x16.png" />
        </Helmet>;
    }

    if (uwrDomains.includes(domain)) {
        return <Helmet>
            <link rel="apple-touch-icon" sizes="180x180" href="/uwr/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/uwr/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/uwr/favicon-16x16.png" />
        </Helmet>;
    }

    return <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    </Helmet>;
}

export default Favicon;