import React from "react";
import { AsyncSelect } from "@atlaskit/select";
import apiClient from "../../api/api-client";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

function SelectDocumentRole({ onChange, value, isDisabled }) {
    const { t } = useTranslation();
    const search = () => apiClient("document/roles")
        .then(response => {
            return Promise.resolve(response.map(i => ({
                label: i.name,
                value: i.id,
            })));
        })
        .catch(() => Promise.resolve([]));

    return <Wrapper>
        <AsyncSelect loadOptions={search} defaultOptions value={value} onChange={onChange} isDisabled={isDisabled} placeholder={t("select_document_role_placeholder")} />
    </Wrapper>;
}

export default SelectDocumentRole;

const Wrapper = styled.div`

`;