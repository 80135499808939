import React from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import useAsync from "../../hooks/use-async";
import { useTranslation } from "react-i18next";

export default function DocumentTableRemoveComponentRecord({ document, linkId, onRemoved, onClose }) {
    const { t } = useTranslation();
    const { run, isPending } = useAsync();
    const remove = () => {
        run(apiClient(`document/${document?.id}/unlink/${linkId}`, { method: "DELETE" }))
            .then(() => {
                onRemoved && onRemoved();
            })
            .catch(error => toast.error(t("unknown_error") +  error.message));
    };

    return (
        <>
            <ModalTransition>
                <Modal
                    isLoading={isPending}
                    actions={[
                        { text: t("document_table_remove_record_remove"), onClick: remove, isLoading: isPending },
                        { text: t("document_table_remove_record_cancel"), onClick: onClose, isDisabled: isPending },
                    ]}
                    onClose={close}
                    heading={t("document_table_remove_record_confirm")}
                    appearance="danger"
                >
                    <p>
                        {t("document_table_remove_record_confirm_p")}
                    </p>
                </Modal>
            </ModalTransition>
        </>
    );
}