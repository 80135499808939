import { useEffect } from "react";
import apiClient from "../api/api-client";
import documentFieldsResponse from "../api/document-fields-response";
import useAsync from "../hooks/use-async";

export default function useDocumentFields(documentTypeId) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        if (!documentTypeId) return;

        run(apiClient(`document-types/${documentTypeId}`))
            .catch(() => { });
    }, [documentTypeId]);

    return {
        fields: data ? documentFieldsResponse(data) : null,
        isPending: isIdle || isPending,
        isError,
        isSuccess,
    };
}