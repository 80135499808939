import React from "react";
import styled from "styled-components";

export default function CmsLayout({ children }) {
    return <Wrapper>
        <Content>
            {children}
        </Content>
    </Wrapper >;
}

const Wrapper = styled.div`
`;

const Content = styled.div`
    background: white;
    padding: 40px;
`;